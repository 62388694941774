/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasChevronDown } from "@resource/atlas/icons";

import { EXTENSION_DIMS } from "./dimensions";

export function FloatingCloseButton({ onClick }: { onClick: () => void }) {
  return (
    <div
      id="guide-extension-popover-close-button"
      role="button"
      onClick={onClick}
      style={{
        width: EXTENSION_DIMS.floatingButton.width,
        height: EXTENSION_DIMS.floatingButton.height,
        bottom: EXTENSION_DIMS.floatingButton.padding.bottom,
        right: EXTENSION_DIMS.floatingButton.padding.right,
        zIndex: 2,
      }}
      className="fixed rounded-full bg-[#1F1440] cursor-pointer flex items-center justify-center"
    >
      <Icon content={atlasChevronDown} className="text-white w-8 h-8" />
    </div>
  );
}
