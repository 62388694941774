import React, { useContext } from "react";

interface FlagsLoadingContextValue {
  loading: boolean;
}

export const FlagsLoadingContext = React.createContext<
  FlagsLoadingContextValue | undefined
>(undefined);

export const useFlagsLoading = () => {
  const context = useContext(FlagsLoadingContext);
  if (!context) {
    throw new Error(
      "FlagsLoadingContext cannot be used without FlagsLoadingProvider"
    );
  }

  return context;
};
