/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  // eslint-disable-next-line no-restricted-imports
  useQuery as useApolloQuery,
} from "@apollo/client";
import { useExtensionHasBeenOpened } from "client/app/extension/__components/ExtensionControllerProvider";
import { getErrorWithGraphQLErrors } from "client/utils/apollo";
import { useConnectionErrorContext } from "components/Generic/ConnectionErrorBanner";
import { useEffect } from "react";

export type QueryOptions<
  TData = any,
  TVariables extends OperationVariables = any
> = QueryHookOptions<TData, TVariables> & {
  throwOnError?: boolean;
};

export default function useQuery<
  TData = any,
  TVariables extends OperationVariables = any
>(
  query: TypedDocumentNode<TData, TVariables>,
  queryOptions?: QueryOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  const hasExtensionBeenOpen = useExtensionHasBeenOpened();
  const skip = !hasExtensionBeenOpen || queryOptions?.skip;
  const queryResult = useApolloQuery<TData, TVariables>(query, {
    ...queryOptions,
    skip,
  });
  const { erroredQuery, setErroredQuery } = useConnectionErrorContext();
  const throwOnError = queryOptions?.throwOnError ?? true;

  useEffect(() => {
    if (!erroredQuery && queryResult.error && queryResult.previousData) {
      setErroredQuery(query);
    }
    // TODO: ideally we should remove this banner when this request is next successful, however this seems to get called again
    // with queryResult.error undefined immediately after resulting in the banner constantly appearing and disappearing
  }, [
    queryResult.error,
    queryResult.previousData,
    erroredQuery,
    setErroredQuery,
    query,
    queryResult,
  ]);

  if (queryResult.error) {
    if (erroredQuery) {
      return {
        ...queryResult,
        data: queryResult.previousData,
      };
    }

    // Whilst we don't strictly know if this is an internet connection issue, if it happens after a successful request was
    // already made then we assume it is. Worst case the user refreshes per error message + they will get to the "real" error
    if (queryResult.previousData) {
      return queryResult;
    }

    if (throwOnError) {
      throw getErrorWithGraphQLErrors(queryResult.error);
    }
  }

  return queryResult;
}
