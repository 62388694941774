import type { NextPage } from "next";
import type { ReactElement } from "react";

export type GetLayout = (page: ReactElement) => ReactElement;

export function withLayout(getLayout: GetLayout) {
  return <T extends object>(page: T) => {
    if ("getLayout" in page) {
      throw new Error(
        "Cannot add getLayout to page as it already exists and would override it."
      );
    }
    // eslint-disable-next-line no-param-reassign
    (page as unknown as { getLayout: GetLayout }).getLayout = getLayout;
    return page;
  };
}

interface Predicate {
  (): boolean;
}

function DecoratedPage({
  decorator,
  predicate,
  page,
}: {
  decorator: GetLayout;
  predicate: Predicate;
  page: ReactElement;
}) {
  return predicate() ? decorator(page) : null;
}

export function withConditionalLayout(
  getLayout: GetLayout,
  predicate: Predicate
) {
  return <T extends object>(page: T) => {
    if ("getLayout" in page) {
      throw new Error(
        "Cannot add getLayout to page as it already exists and would override it."
      );
    }
    // eslint-disable-next-line no-param-reassign
    (page as unknown as { getLayout: GetLayout }).getLayout = (innerPage) => (
      <DecoratedPage
        decorator={getLayout}
        predicate={predicate}
        page={innerPage}
      />
    );
    return page;
  };
}

export function withAppLayout<P extends JSX.IntrinsicAttributes>(
  Component: NextPage,
  pageProps: P
): ReactElement {
  const getLayout: (page: ReactElement) => ReactElement =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (Component as any).getLayout ?? ((page: ReactElement) => page);

  return getLayout(<Component {...pageProps} />);
}
