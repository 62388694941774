/* eslint-disable import/prefer-default-export */
import { Button as AriakitButton } from "ariakit/button";
import clsx from "clsx";
import { useRef } from "react";

import { atlasChevronDown } from "../../icons/atlas";
import { setTooltipConfig } from "../__utils/__deprecated";
import {
  createComponentUtils,
  getNegativeMarginClassName,
} from "../__utils/atlas";
import { createDefaultProps, mergeProps, useForkRef } from "../__utils/react";
import { Icon } from "../icon/Icon";
import { LoadingIndicator } from "../loading-indicator/LoadingIndicator";
import { AtlasLoadingIndicatorProps } from "../loading-indicator/types";
import { SelectLabelProvider } from "../select/utils/SelectLabel";
import { useKeyboardActive } from "../utils/interaction";
import type { AtlasButtonComponent, AtlasButtonProps } from "./types";
import { extractButtonOptions, useButtonOptions } from "./utils/button-options";

// config
// ------

const COMPONENT_NAME = "Button";
const DEFAULT_PROPS = createDefaultProps<AtlasButtonProps>()({
  variant: "default",
  size: "medium",
  iconSize: "medium",
  isHeavy: true,
} as const);
const DEFAULT_ELEMENT = "button";
const DROPDOWN_ICON = atlasChevronDown;
const { ROOT, el, createComponent } = createComponentUtils(COMPONENT_NAME);

// button
// ------

function getLoadingIndicatorVariant({
  variant,
  isGhost,
  isActive,
}: {
  variant: NonNullable<AtlasButtonProps["variant"]>;
  isGhost?: AtlasButtonProps["isGhost"];
  isActive?: AtlasButtonProps["isActive"];
}): NonNullable<AtlasLoadingIndicatorProps["variant"]> {
  switch (variant) {
    case "default":
    case "dark":
      return "dark";
    case "light":
      return "light";
    default:
      return isGhost && !isActive ? "dark" : "light";
  }
}

/**
 * A button component. If `a` is passed to the `as` prop, it will be rendered
 * as a link while remaining accessible.
 *
 * @example
 * ```jsx
 * <Button size="small" variant="primary" isGhost>
 *   Click me!
 * </Button>
 * ```
 */
export const Button = createComponent<AtlasButtonComponent>(
  ({ as = DEFAULT_ELEMENT, ref, children, ...p0 }) => {
    const [buttonOptions, props] = extractButtonOptions(p0);
    const {
      variant = DEFAULT_PROPS.variant,
      size = DEFAULT_PROPS.size,
      iconSize = DEFAULT_PROPS.iconSize,
      isActive,
      isGhost,
      isDropdown,
      isLoading,
      isHeavy,
      icon,
      disabled,
      negativeMargin,
    } = useButtonOptions(buttonOptions);

    const parentRef = useRef<HTMLElement>(null);
    const { keyboardActiveProps } = useKeyboardActive(props);

    const overflowRef = useRef<HTMLSpanElement>(null);

    return (
      <AriakitButton
        as={as}
        ref={useForkRef(parentRef, ref)}
        {...mergeProps(keyboardActiveProps, props)}
        className={clsx(
          `${ROOT} variant-${variant} size-${size}`,
          getNegativeMarginClassName(negativeMargin),
          { isActive, isGhost, isDropdown, isLoading },
          { isIconButton: !children, hasIcon: icon },
          props.className
        )}
        disabled={disabled || isLoading}
        // TODO: this should be automatically passed by Tooltip instead of being the default here
        accessibleWhenDisabled={props.accessibleWhenDisabled ?? true}
      >
        {isLoading && (
          <div className={el`loading`}>
            <LoadingIndicator
              size="small"
              variant={getLoadingIndicatorVariant({
                variant,
                isGhost,
                isActive,
              })}
            />
          </div>
        )}
        {icon && <Icon className={el`icon`} content={icon} size={iconSize} />}
        {children && (
          <span
            ref={overflowRef}
            className={clsx(el`label`, {
              normal: !isHeavy,
              heavy: isHeavy,
            })}
          >
            <SelectLabelProvider value={{ parentRef, overflowRef }}>
              {children}
            </SelectLabelProvider>
          </span>
        )}
        {isDropdown && (
          <Icon
            className={el`dropdown-icon`}
            content={DROPDOWN_ICON}
            size="custom"
          />
        )}
      </AriakitButton>
    );
  }
);

// TODO: remove when "slot" becomes the default mode
setTooltipConfig(Button, { mode: "slot" });
