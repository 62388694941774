import { OrganizationFeaturesEnum } from "generated/graphql-codegen/graphql";

export enum OrganizationFeatures {
  MESSAGING = "MESSAGING",
  SCHEDULING = "SCHEDULING",
  SURVEYS = "SURVEYS",
}

type OrganizationToGetFeatures = {
  id: string;
  messagingEnabled: boolean;
  schedulingEnabled: boolean;
  surveysEnabled: boolean;
};

type UserMembershipToGetFeatures = {
  messagingEnabledOverride: boolean;
  schedulingEnabledOverride: boolean;
  surveysEnabledOverride: boolean;
};

type GuideToGetFeatures = {
  messagingEnabledOverride: boolean;
  schedulingEnabledOverride: boolean;
  surveysEnabledOverride: boolean;
};

export const getOrganizationFeatures = (
  organization: OrganizationToGetFeatures
) => {
  return [
    ...(organization.messagingEnabled ? [OrganizationFeatures.MESSAGING] : []),
    ...(organization.schedulingEnabled
      ? [OrganizationFeatures.SCHEDULING]
      : []),
    ...(organization.surveysEnabled ? [OrganizationFeatures.SURVEYS] : []),
  ];
};

export const getUserMembershipOverrideFeatures = (
  userMembership: UserMembershipToGetFeatures
) => {
  return [
    ...(userMembership.messagingEnabledOverride
      ? [OrganizationFeatures.MESSAGING]
      : []),
    ...(userMembership.schedulingEnabledOverride
      ? [OrganizationFeatures.SCHEDULING]
      : []),
    ...(userMembership.surveysEnabledOverride
      ? [OrganizationFeatures.SURVEYS]
      : []),
  ];
};

export const getGuideOverrideFeatures = (guide: GuideToGetFeatures) => {
  return [
    ...(guide.messagingEnabledOverride ? [OrganizationFeatures.MESSAGING] : []),
    ...(guide.schedulingEnabledOverride
      ? [OrganizationFeatures.SCHEDULING]
      : []),
    ...(guide.surveysEnabledOverride ? [OrganizationFeatures.SURVEYS] : []),
  ];
};

export const featureIsEnabledForOrg = (
  features: OrganizationFeaturesEnum | OrganizationFeaturesEnum[],
  organization: {
    features: OrganizationFeaturesEnum[];
  }
) => {
  const orgFeatures = organization.features;

  return [features].flat().every((f) => orgFeatures.includes(f));
};

export const featureIsEnabledOverrideForUserMembership = (
  features: OrganizationFeaturesEnum | OrganizationFeaturesEnum[],
  userMembership: {
    featuresOverride: OrganizationFeaturesEnum[];
  }
) => {
  const membershipFeatureOverrides = userMembership.featuresOverride;

  return [features].flat().every((f) => membershipFeatureOverrides.includes(f));
};

export const featureIsEnabledOverrideForGuide = (
  features: OrganizationFeaturesEnum | OrganizationFeaturesEnum[],
  guide: {
    featuresOverride: OrganizationFeaturesEnum[];
  }
) => {
  const guideFeatureOverrides = guide.featuresOverride;

  return [features].flat().every((f) => guideFeatureOverrides.includes(f));
};
