import { Prisma } from "@prisma/client";
import { CalendarEvent } from "apis/google";
import { EventInput } from "generated/graphql-codegen/graphql";
import { DateTime } from "luxon";
import { InterviewerPoolLoadLimits } from "shared/interviewer-pools/types";
import { WorkingHoursConfig } from "shared/utils/working-hours";
import { z } from "zod";

const interviewWithInterviewers =
  Prisma.validator<Prisma.InterviewRequirementArgs>()({
    include: {
      interviewerRequirements: true,
    },
  });

export type InterviewRequirementWithInterviewers =
  Prisma.InterviewRequirementGetPayload<typeof interviewWithInterviewers>;

export type InterviewerEvent = CalendarEvent;

export type CandidateAvailability = {
  startTime: DateTime;
  endTime: DateTime;
};

export type InterviewerForScheduling = {
  id: string;
  userId: string;
  googleCalendarId: string;
  maxInterviewLoadPerDay?: number | null;
  maxInterviewLoadPerWeek?: number | null;
  workingHours?: WorkingHoursConfig | null;
  timezone?: string | null;
  interviewCountsPerDayForLoad: Record<string, number>;
  poolInterviewCountsPerDayForLoad: Record<string, number> | null;
  poolLimits: InterviewerPoolLoadLimits | null;
  ignoreAccountLoadLimits: boolean;
  whitelist: string[];
};
export type InterviewerWithEvents = InterviewerForScheduling & {
  events: InterviewerEvent[];
};

export type InterviewerForSchedulingWithScore = InterviewerForScheduling & {
  score: number;
};

export type InterviewRequirementForScheduling = {
  id: string;
  order?: number;
  orderLocked: boolean;
  duration: number;
  interviewerRequirements: {
    id: string;
    userMembershipIds: string[];
    shadowingUserMembershipIds: string[];
    poolId: string | null;
    poolLimits: InterviewerPoolLoadLimits | null;
    ignoreAccountLoadLimits: boolean;
  }[];
};

export type InterviewRequirementWithScore =
  InterviewRequirementForScheduling & {
    time: DateTime;
    score: number;
    interviewers: Record<string, string[]>;
    shadowers: Record<string, string[]>;
  };

export type InterviewerWithScore = {
  interviewerId: string;
  time: DateTime;
  score: number;
};

export type TimeWithProperties = {
  time: DateTime;
  properties: SlotProperties;
};

export type TimeWithScoreAndProperties = TimeWithProperties & {
  score: number;
};

export enum TimeAvailability {
  FREE = 1,
  WHITELISTED,
  BUSY,
}

// TODO (P3): Some of these properties are mutually exclusive or subsets of each other; we should probably have a better way of
//            representing this. e.g. DuringWorkingHours must be true for OutsideLunchHour to be true. TimeAvailable must be false
//            for meeting with less than 3 to be true.
// TODO: Unify with SlotProperties in guides/src/client/components/scheduling-tasks/ai-scenarios/__types.ts
export type SlotProperties = {
  timeAvailability: TimeAvailability;
  duringWorkingHours: boolean;
  outsideLunchHour: boolean;
  meetingWithLessThanThree: boolean;
  dailyInterviewerLoad: number;
  weeklyInterviewerLoad: number;
  interviewerLoadMet: boolean;
  dailyInterviewerPoolLoad: number;
  weeklyInterviewerPoolLoad: number;
  monthlyInterviewerPoolLoad: number;
  overallInterviewerPoolLoad: number;
  interviewerPoolLoadMet: boolean;
  ignoreAccountLoadLimits: boolean;
  conflictingEvents: CalendarEvent[];
};

export const SlotWeightsSchema = z.object({
  timeIsFree: z.number(),
  timeIsWhitelisted: z.number(),
  duringWorkingHours: z.number(),
  outsideLunchHour: z.number(),
  meetingWithLessThanThree: z.number(),
  dailyInterviewerLoad: z.number(),
  weeklyInterviewerLoad: z.number(),
  dailyInterviewerPoolLoad: z.number(),
  interviewerLoadMet: z.number(),
  weeklyInterviewerPoolLoad: z.number(),
  monthlyInterviewerPoolLoad: z.number(),
  overallInterviewerPoolLoad: z.number(),
  interviewerPoolLoadMet: z.number(),
});

export type SlotWeights = z.infer<typeof SlotWeightsSchema>;

export type PanelProperties = {
  singleDay: number;
  backToBack: number;
};

export const PanelWeightsSchema = z.object({
  singleDay: z.number(),
  backToBack: z.number(),
});

export type PanelWeights = z.infer<typeof PanelWeightsSchema>;

export type InterviewSlotOptions = {
  time: DateTime;
  // Keyed by interviewer requirement id
  requirements: Record<
    string,
    {
      requirementId: string;
      // Keyed by score for easy grouping; we should treat interviewers with the same score interchangeably
      options: Record<
        number,
        { score: number; interviewers: string[]; shadowers: string[] }
      >;
    }
  >;
};

export type PotentialInterviewersForRequirement = {
  score: number;
  interviewers: string[];
  shadowers: string[];
  interviewRequirementId: string;
};

export type InterviewTimeScoreMap = Record<
  string,
  Record<string, TimeWithScoreAndProperties>
>;

export type FindTimesForSchedulingAlgorithmRunProps = {
  schedulingAlgorithmRunId: string;
  fakeAvailability?: EventInput[] | null;
  actorId: string;
  runAsId: string;
  version?: number;
  verbose: boolean;
};

export enum DayOfWeek {
  SUNDAY = 7,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export type TimeOfDay = `${number}:${number}`;

export type RepeatTimeBlock = {
  dayOfWeek: DayOfWeek;
  startTime: TimeOfDay;
  endTime: TimeOfDay;
  timezone: string;
};
