import { useExtensionHasBeenOpened } from "client/app/extension/__components/ExtensionControllerProvider";
import { useEffect } from "react";

export const LOGIN_COMMUNICATION_CHANNEL = "__guide_login_channel";

export interface UseAcrossTabsListenerProps {
  channel: string;
  listener?: (event: StorageEvent) => void;
}

export const useAcrossTabsListener = ({
  channel,
  listener,
}: UseAcrossTabsListenerProps) => {
  const hasExtensionBeenOpen = useExtensionHasBeenOpened();
  useEffect(() => {
    function fn(event: StorageEvent) {
      if (listener && event.key === channel && hasExtensionBeenOpen) {
        listener(event);
      }
    }

    window.addEventListener("storage", fn);

    return () => {
      window.removeEventListener("storage", fn);
    };
  }, [channel, hasExtensionBeenOpen, listener]);
};

export const postMessageAcrossTabs = (channel: string, message: string) => {
  window.localStorage.setItem(channel, message);
};
