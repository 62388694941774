import { useToast } from "@resource/atlas/toast/use-toast";
import { httpBatchLink, TRPCClientError } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import { ssrPrepass } from "@trpc/next/ssrPrepass";
import { inferReactQueryProcedureOptions } from "@trpc/react-query/dist/utils/inferReactQueryProcedure";
import { UseTRPCMutationResult } from "@trpc/react-query/shared";
import { inferRouterInputs, inferRouterOutputs } from "@trpc/server";
import { useCallback } from "react";
import type { AppRouter } from "shared/trpc/types";

function getBaseUrl() {
  if (typeof window !== "undefined") return "";

  if (process.env.NEXT_PUBLIC_NEXTJS_APP_ROOT) {
    return process.env.NEXT_PUBLIC_NEXTJS_APP_ROOT;
  }

  return `https://localhost:3000`;
}

export type ReactQueryOptions = inferReactQueryProcedureOptions<AppRouter>;
export type RouterInputs = inferRouterInputs<AppRouter>;
export type RouterOutputs = inferRouterOutputs<AppRouter>;

export const trpc = createTRPCNext<AppRouter>({
  config() {
    return {
      links: [
        httpBatchLink({
          url: `${getBaseUrl()}/api/trpc`,
        }),
      ],
      queryClientConfig: {
        defaultOptions: {
          queries: {
            retry: false,
          },
        },
      },
    };
  },
  ssrPrepass,
  ssr: true,
});

/**
 * Wrapper for TRPC useMutation hook that sends a toast on error. Due to complex typing, we have to pass the mutation result
 * as a function return.
 * */
export const useTRPCMutation = <
  TData = unknown,
  TError = TRPCClientError<AppRouter>,
  TVariables = void,
  TContext = unknown
>(
  trpcMutationHook: () => UseTRPCMutationResult<
    TData,
    TError,
    TVariables,
    TContext
  >
): UseTRPCMutationResult<TData, TError, TVariables, TContext> => {
  const mutation = trpcMutationHook();
  const trpcMutation = mutation.mutateAsync;

  const { sendToast } = useToast();

  mutation.mutateAsync = useCallback(
    async (input, options) => {
      try {
        return await trpcMutation(input, options);
      } catch (error) {
        sendToast(error.message, {
          variant: "error",
        });
        throw error;
      }
    },
    [trpcMutation, sendToast]
  );

  return mutation;
};
