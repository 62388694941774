import { useAuthContext } from "auth/context";
import { ReactNode, useCallback, useEffect } from "react";
import { OutboundMessage } from "utils/useWindowMessageListener";

// Needs to be below user auth context
export function ExtensionAuthenticationProvider({
  children,
}: {
  children?: ReactNode;
}) {
  const { user, loading: userLoading } = useAuthContext();

  const sendMessageToParent = useCallback((message: OutboundMessage) => {
    window.parent.parent.postMessage(message, "*");
  }, []);

  useEffect(() => {
    if (user) {
      sendMessageToParent({
        command: "authentication-context-updated",
        value: {
          isLoggedIn: true,
          firstName: user.firstName,
          lastName: user.lastName,
          fullName: user.fullName,
          imageUrl: user.imageUrl,
          createdAt: new Date(user.createdAt),
        },
      });
    } else if (!user && !userLoading) {
      sendMessageToParent({
        command: "authentication-context-updated",
        value: {
          isLoggedIn: false,
        },
      });
    }
  }, [user, sendMessageToParent, userLoading]);

  return <>{children}</>;
}
