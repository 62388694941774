import { DateTime } from "luxon";

import {
  momentTimezonesForDst,
  momentTimezonesToAbbreviationsForDst,
} from "./moment-timezones-dst";
import {
  momentTimezonesForNonDst,
  momentTimezonesToAbbreviationsForNonDst,
} from "./moment-timezones-non-dst";
import { Timezone } from "./types";

const isDaylightSavingsTime = DateTime.now().isInDST;

export const momentTimezones = isDaylightSavingsTime
  ? momentTimezonesForDst
  : momentTimezonesForNonDst;

export const momentTimezonesToAbbreviations = isDaylightSavingsTime
  ? momentTimezonesToAbbreviationsForDst
  : momentTimezonesToAbbreviationsForNonDst;

export type { Timezone };
