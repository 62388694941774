/* eslint-disable no-restricted-syntax */
import * as Sentry from "@sentry/nextjs";
import PrismaError from "errors/PrismaError";

const handleError = (
  error: Error,
  scope: Sentry.Scope,
  opts?: {
    title?: string;
    metadata?: Record<string, unknown>;
  }
) => {
  if (error instanceof PrismaError) {
    scope.setFingerprint([error.message]);
    scope.setExtras({
      model: error.model,
      operation: error.operation,
      errorId: error.errorId,
    });
  } else if (error.name === "ApolloError" && "graphQLErrors" in error) {
    scope.setFingerprint([error.message]);
    scope.setExtras({
      graphQLErrors: error.graphQLErrors,
      networkError: "networkError" in error ? error.networkError : undefined,
      extraInfo:
        "extraInfo" in error ? JSON.stringify(error.extraInfo) : undefined,
    });
  } else if (opts?.title) {
    scope.setFingerprint([opts.title]);
  } else if (error.name === "PrismaClientKnownRequestError") {
    // Sentry auto-groups based on stack trace
    // So set fingerprint to the error message to group by that
    scope.setFingerprint([error.message]);
  }

  if (opts?.metadata) {
    scope.setExtras(opts.metadata);
  }

  Sentry.captureException(error);
};

export function captureSentryError(
  error: Error,
  opts?: {
    title?: string;
    metadata?: Record<string, unknown>;
  },
  scope?: Sentry.Scope
) {
  if (scope) {
    handleError(error, scope, opts);
  } else {
    Sentry.withScope((innerScope) => {
      handleError(error, innerScope, opts);
    });
  }
}
