import { ReactNode, useEffect } from "react";

export const SHOULD_PERSIST_CACHE_STORAGE_KEY = "GUIDE_should-persist-cache";

/**
 * HOC that initializes the `shouldPersistCache` flag in local storage for the next
 * app launch.
 */
export function ShouldPeristCacheInitializer({
  children,
}: {
  children?: ReactNode;
}) {
  useEffect(() => {
    window.localStorage.setItem(SHOULD_PERSIST_CACHE_STORAGE_KEY, "true");
  }, []);

  return <>{children}</>;
}
