/* eslint-disable react/destructuring-assignment */
import { IdProvider as RadixProvider } from "@radix-ui/react-id";
import { SSRProvider as ReactAriaProvider } from "@react-aria/ssr";
import { SnackbarProvider, SnackbarProviderProps } from "notistack";
import { ReactElement, ReactNode, useRef } from "react";

// toast provider
// --------------
import { DialogOpenProvider } from "../dialog/DialogOpenContext";
import { AtlasProviderProps } from "./types";

export const NotistackDomRootId = "Guide-NotistackDomRoot";

// config
// ------

const NOTISTACK_OPTIONS: Omit<SnackbarProviderProps, "children"> = {
  maxSnack: 2,
  anchorOrigin: {
    horizontal: "right",
    vertical: "bottom",
  },
};

function ToastProvider(p: { children?: ReactNode }) {
  const domRootRef = useRef(null);
  return (
    <div id={NotistackDomRootId} ref={domRootRef}>
      <SnackbarProvider
        {...NOTISTACK_OPTIONS}
        domRoot={domRootRef.current ?? undefined}
      >
        {p.children}
      </SnackbarProvider>
    </div>
  );
}

// design system provider
// ----------------------

function Provider(p: AtlasProviderProps): ReactElement {
  return (
    // @ts-expect-error - Radix is not typed correctly in React 18
    <RadixProvider>
      <ReactAriaProvider>
        <ToastProvider>
          <DialogOpenProvider>{p.children}</DialogOpenProvider>
        </ToastProvider>
      </ReactAriaProvider>
    </RadixProvider>
  );
}

export default Provider;
