import { ApolloError } from "@apollo/client";
import { GraphQLErrors } from "@apollo/client/errors";

export const formatGraphQLErrorMessage = (error: {
  graphQLErrors?: GraphQLErrors | null;
  networkError?: Error | null;
}): string => {
  let errorMessage = "Unknown Apollo Client error";

  if (error.graphQLErrors?.length) {
    const firstGraphQLError = error.graphQLErrors[0];

    if (firstGraphQLError.extensions?.code === "BAD_USER_INPUT") {
      errorMessage = firstGraphQLError.message.split(";").slice(-1)[0].trim();
    } else {
      errorMessage = firstGraphQLError.message;
    }
  } else if (error.networkError) {
    errorMessage = error.networkError.message;
  }

  return errorMessage;
};

/**
 * There is an issue with Apollo Client when a 400 is returned and we lose the GraphQL Errors
 * They get put on the networkError.result instead
 * Mapping here so we properly get GraphQL errors
 * https://github.com/apollographql/apollo-client/issues/9870
 */
export const getErrorWithGraphQLErrors = (error: ApolloError): ApolloError => {
  const newError = error;

  if (
    !newError.graphQLErrors?.length &&
    newError.networkError &&
    "result" in newError.networkError &&
    typeof newError.networkError.result === "object" &&
    "errors" in newError.networkError.result &&
    newError.networkError.result.errors.length
  ) {
    newError.graphQLErrors = newError.networkError.result.errors;
    newError.message = formatGraphQLErrorMessage(newError);
  }

  return newError;
};
