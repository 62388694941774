/* eslint-disable no-underscore-dangle */
import {
  createContext,
  isValidElement,
  ReactNode,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from "react";
import flattenChildren from "react-keyed-flatten-children";

import { getComponentMetadata } from "../../__utils/atlas";
import { createOptionsExtractor } from "../../__utils/misc";
import type { AtlasButtonExtendedOptions } from "../types";

type ButtonPropsById = Record<string, AtlasButtonExtendedOptions>;
type ContextValue = AtlasButtonExtendedOptions & {
  _buttonPropsById?: ButtonPropsById;
  _registerId?: (id: string) => void;
  _unregisterId?: (id: string) => void;
  _wasLowestDescendant?: boolean;
};

const ButtonOptionsContext = createContext<ContextValue | undefined>(undefined);

function useButtonOptionsContext() {
  return useContext(ButtonOptionsContext);
}

function hasChildGroups(children?: ReactNode) {
  const flatChildren = flattenChildren(children);

  if (process.env.NODE_ENV === "development") {
    const hasCollections = flatChildren.some(
      (child) =>
        isValidElement(child) &&
        typeof child.type !== "string" &&
        getComponentMetadata(child.type).id === "ButtonCollection"
    );

    if (hasCollections)
      throw new Error("ButtonCollection components can't be nested");
  }

  const hasGroups = flatChildren.some(
    (child) =>
      isValidElement(child) &&
      typeof child.type !== "string" &&
      getComponentMetadata(child.type).id === "ButtonGroup"
  );

  return hasGroups;
}

export function ButtonOptionsProvider({
  value,
  children,
}: {
  value: ContextValue;
  children?: ReactNode;
}) {
  const currentValue = useButtonOptionsContext();

  const [buttonIds, setButtonIds] = useState<string[]>([]);

  const wasLowestDescendant = currentValue?._wasLowestDescendant ?? false;
  const isLowestDescendant = useMemo(
    () => !hasChildGroups(children),
    [children]
  );

  const isTopContext = useMemo(
    () =>
      wasLowestDescendant ||
      (!currentValue?._registerId && !currentValue?._unregisterId),
    [
      currentValue?._registerId,
      currentValue?._unregisterId,
      wasLowestDescendant,
    ]
  );

  const registerFns = useMemo(
    () =>
      isTopContext
        ? {
            _registerId: (id: string) => setButtonIds((ids) => [...ids, id]),
            _unregisterId: (id: string) =>
              setButtonIds((ids) => ids.filter((x) => x !== id)),
          }
        : undefined,
    [isTopContext]
  );

  if (!isTopContext && value.negativeMargin)
    throw new Error(
      '"negativeMargin" can only be passed to the root ButtonGroup or ButtonCollection'
    );

  const _buttonPropsById = useMemo(() => {
    if (!isTopContext) return currentValue?._buttonPropsById || {};

    const propsById: ButtonPropsById = {};
    const { negativeMargin } = value;

    if (!negativeMargin) return propsById;

    if (buttonIds.length === 1) propsById[buttonIds[0]] = { negativeMargin };
    else if (buttonIds.length > 1) {
      const firstId = buttonIds.at(0);
      const lastId = buttonIds.at(-1);

      if (!firstId || !lastId) throw new Error("Invalid state");

      if ([true, "left"].includes(negativeMargin)) {
        propsById[firstId] = { negativeMargin: "left" };
      }

      if ([true, "right"].includes(negativeMargin)) {
        propsById[lastId] = { negativeMargin: "right" };
      }
    }

    return propsById;
  }, [buttonIds, currentValue?._buttonPropsById, isTopContext, value]);

  const { negativeMargin, ...mergedValue } = useMemo(
    () => ({
      ...(!isTopContext ? currentValue : undefined),
      ...value,
      ...registerFns,
      _buttonPropsById,
      _wasLowestDescendant: isLowestDescendant,
    }),
    [
      _buttonPropsById,
      currentValue,
      isLowestDescendant,
      isTopContext,
      registerFns,
      value,
    ]
  );

  return (
    <ButtonOptionsContext.Provider value={mergedValue}>
      {children}
    </ButtonOptionsContext.Provider>
  );
}

/** Returns the passed button options, falling back to values from the context. */
export function useButtonOptions<P extends AtlasButtonExtendedOptions>(
  props: P
): AtlasButtonExtendedOptions {
  const {
    _buttonPropsById,
    _registerId,
    _unregisterId,
    _wasLowestDescendant, // ignored
    ...contextOptions
  } = useButtonOptionsContext() ?? {};

  const buttonId = useId();
  useEffect(() => {
    _registerId?.(buttonId);
    return () => _unregisterId?.(buttonId);
  }, [_registerId, _unregisterId, buttonId]);

  const buttonProps = _buttonPropsById?.[buttonId];

  return { ...contextOptions, ...buttonProps, ...props };
}

/** Separates button options from other props. */
export const extractButtonOptions =
  createOptionsExtractor<AtlasButtonExtendedOptions>([
    "icon",
    "isActive",
    "isDropdown",
    "isLoading",
    "isGhost",
    "size",
    "variant",
    "disabled",
    "iconSize",
    "negativeMargin",
  ] as const);
