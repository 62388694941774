import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

interface DialogOpenContextType {
  isAnyDialogOpen: boolean;
  incrementDialogCount: () => void;
  decrementDialogCount: () => void;
}

const DialogOpenContext = createContext<DialogOpenContextType | undefined>(
  undefined
);

export const useDialogOpen = (): DialogOpenContextType => {
  const context = useContext(DialogOpenContext);
  if (!context) {
    throw new Error("useDialogOpen must be used within a DialogOpenProvider");
  }
  return context;
};

export function DialogOpenProvider({ children }: { children: ReactNode }) {
  const [dialogCount, setDialogCount] = useState(0);

  const incrementDialogCount = useCallback(
    () => setDialogCount((c) => c + 1),
    []
  );
  const decrementDialogCount = useCallback(
    () => setDialogCount((c) => Math.max(0, c - 1)),
    []
  );

  const isAnyDialogOpen = useMemo(() => dialogCount > 0, [dialogCount]);

  return (
    <DialogOpenContext.Provider
      value={{ isAnyDialogOpen, incrementDialogCount, decrementDialogCount }}
    >
      {children}
    </DialogOpenContext.Provider>
  );
}
