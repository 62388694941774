import { ReactNode } from "react";

/** Stops event propagation for `onClick` and `onKeyDown` handlers. */
export default function StopPropagation({
  children,
  className,
}: {
  children?: ReactNode;
  className?: string;
}) {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span
      onClick={(e) => e.stopPropagation()}
      onPointerDown={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      className={className}
    >
      {children}
    </span>
  );
}
