// AUTOGENERATED FILE. DO NOT EDIT.
// Run 'yarn gen:role-enum' to update.
export enum RoleEnum {
  Admin = "admin",
  Candidate = "candidate",
  External = "external",
  Interviewer = "interviewer",
  Manager = "manager",
  Member = "member",
}
