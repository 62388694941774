import { DateTime } from "luxon";
import { momentTimezonesToAbbreviations } from "shared/utils/moment-timezones";

export const getCurrentIanaTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

// This const is only used for useTimezoneSelectionItemsOriginal; do not use!
// Should be removed when that flag is fully retired.
// Instead, use moment-timezones.ts which is comprehensive.
export const timezonesForSearch = [
  "Africa/Johannesburg",
  "America/Argentina/Buenos_Aires",
  "America/Chicago",
  "America/Denver",
  "America/Detroit",
  "America/Edmonton",
  "America/Halifax",
  "America/Los_Angeles",
  "America/Mexico_City",
  "America/Montreal",
  "America/New_York",
  "America/Phoenix",
  "America/Regina",
  "America/Sao_Paulo",
  "America/Toronto",
  "America/Vancouver",
  "America/Winnipeg",
  "Asia/Bangkok",
  "Asia/Dhaka",
  "Asia/Dubai",
  "Asia/Hong_Kong",
  "Asia/Jakarta",
  "Asia/Kolkata",
  "Asia/Kuala_Lumpur",
  "Asia/Kuwait",
  "Asia/Manila",
  "Asia/Riyadh",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Taipei",
  "Asia/Tehran",
  "Asia/Tokyo",
  "Australia/Brisbane",
  "Australia/Sydney",
  "Europe/Amsterdam",
  "Europe/Athens",
  "Europe/Berlin",
  "Europe/Brussels",
  "Europe/Copenhagen",
  "Europe/Helsinki",
  "Europe/London",
  "Europe/Madrid",
  "Europe/Paris",
  "Europe/Rome",
  "Europe/Stockholm",
  "Europe/Warsaw",
  "Europe/Zurich",
];

type DisplayTypes = "default" | "abbreviation" | "abbreviation-with-offset";

export const displayTimezone = (
  timezone: string,
  displayType: DisplayTypes = "default",
  useMomentCurrentAbbreviation = false
) => {
  const currDateInTimezone = DateTime.now().setZone(timezone);

  if (!currDateInTimezone.isValid) return null;

  const formattedName = currDateInTimezone.zoneName.replace(/_/g, " ");

  // The moment abbreviations are much friendlier for users;
  // when possible, use this instead of the Luxon abbreviation:
  // https://github.com/moment/luxon/discussions/1041
  const abbreviation = useMomentCurrentAbbreviation
    ? momentTimezonesToAbbreviations[timezone]
    : currDateInTimezone.offsetNameShort;

  if (displayType === "abbreviation") {
    return abbreviation;
  }

  if (displayType === "abbreviation-with-offset") {
    return `${abbreviation} (${getTimezoneOffset(timezone)})`;
  }

  return `${formattedName} (${abbreviation})`;
};

export const getTimezoneOffset = (timezone: string) => {
  const currDateInTimezone = DateTime.now().setZone(timezone);

  if (!currDateInTimezone.isValid) return null;

  const timezoneOffset = currDateInTimezone.offset;
  const hours = Math.floor(Math.abs(timezoneOffset) / 60);
  const minutes = Math.abs(timezoneOffset) % 60;
  const sign = timezoneOffset >= 0 ? "+" : "-";

  return `${sign}${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
};

export type ReadableTimezoneLabelType =
  | "abbreviation"
  | "abbreviation-with-offset"
  | "default";

export function getReadableTimezone({
  timezone,
  labelType = "default",
  useMomentCurrentAbbreviation = false,
}: {
  timezone: string;
  labelType?: ReadableTimezoneLabelType;
  useMomentCurrentAbbreviation?: boolean;
}) {
  const display =
    displayTimezone(timezone, "default", useMomentCurrentAbbreviation) ??
    "Unknown";
  const abbreviation = displayTimezone(
    timezone,
    "abbreviation",
    useMomentCurrentAbbreviation
  );
  const formattedOffset = getTimezoneOffset(timezone);

  if (labelType === "abbreviation-with-offset") {
    return `${abbreviation} (${formattedOffset})`;
  }

  if (labelType === "abbreviation") {
    return abbreviation ?? display;
  }

  return display;
}
