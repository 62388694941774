/* eslint-disable import/prefer-default-export */
import { Button as AriakitButton } from "ariakit/button";
import clsx from "clsx";

import { atlasLinkExternal } from "../../icons/atlas";
import { createComponentUtils } from "../__utils/atlas";
import { createDefaultProps } from "../__utils/react";
import { Icon } from "../icon/Icon";
import type { AtlasLinkComponent, AtlasLinkProps } from "./types";

// config
// ------

const COMPONENT_NAME = "Link";
const DEFAULT_PROPS = createDefaultProps<AtlasLinkProps>()({
  variant: "default",
} as const);

const DEFAULT_ELEMENT = "a";

const EXTERNAL_ICON = atlasLinkExternal;

const { ROOT, el, createComponent } = createComponentUtils(COMPONENT_NAME);

// link
// ----

/**
 * A link component. If "button" is passed to the "as" prop, it will be rendered
 * as a button while remaining accessible.
 *
 * @example
 * ```jsx
 * <Link variant="subtle" href="https//example.com/">
 *   Take me somewhere!
 * </Link>
 * ```
 */
export const Link = createComponent<AtlasLinkComponent>(
  ({
    as = DEFAULT_ELEMENT,
    variant = DEFAULT_PROPS.variant,
    isExternal,
    children,
    accessibleWhenDisabled,
    // @ts-expect-error - Ignore polymorphism type issues.
    target,
    // @ts-expect-error - Ignore polymorphism type issues.
    rel,
    ...props
  }) => (
    // @ts-expect-error - Ignore polymorphism type issues.
    <AriakitButton
      as={as}
      {...props}
      // by default, external links are opened on a new tab
      target={target ?? (as === "a" && isExternal ? "_blank" : undefined)}
      // by default, no referrer nor opener is made available, for security purposes
      rel={rel ?? (as === "a" ? "noreferrer" : undefined)}
      className={clsx(`${ROOT} variant-${variant}`, props.className)}
      // TODO: this should be automatically passed by Tooltip instead of being the default here
      accessibleWhenDisabled={accessibleWhenDisabled ?? true}
    >
      <span>{children}</span>
      {isExternal && (
        <Icon className={el`icon`} size="custom" content={EXTERNAL_ICON} />
      )}
    </AriakitButton>
  )
);
