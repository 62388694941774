import { DataProxy, useApolloClient } from "@apollo/client";
import { useTrackPage } from "analytics";
import { Pages } from "analytics/types";
import { EditGuideInterviewPlanModalProps } from "client/app/extension/guide/[guideId]/__tmp/__components/EditGuideInterviewPlanModal";
import { EditGuideTemplateInterviewPlanModalProps } from "client/app/extension/guide/[guideId]/__tmp/__components/EditGuideTemplateInterviewPlanModal";
import { StageChangeConfirmationViewProps } from "client/components/interview-plan/InterviewPlanSelect";
import { CollectCandidateAvailabilityDialogProps } from "client/components/scheduling-tasks/CollectCandidateAvailabilityModal";
import { GuideIdProvider } from "client/utils/guide-id-provider";
import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMessenger } from "react-hooks/useMessenger";
import { PersistableCalendarEvent } from "shared/guide-content/rich-blocks/calendar-utils/types";
import inIframe from "utils/in-iframe";
import { useQueryStringValue } from "utils/next";
import { v4 as uuidv4 } from "uuid";

import type { ExtensionInstallProps } from "./ExtensionInstallModal";

// TODO: Move ModalName and ModalProps to a separate config file inside config folder
export const enum ModalName {
  TEST = "test",
  EXTENSION_INSTALL = "extension_install",
  EDIT_GUIDE_INTERVIEW_PLAN = "EditGuideInterviewPlan",
  EDIT_GUIDE_TEMPLATE_INTERVIEW_PLAN = "EditGuideTemplateInterviewPlan",
  STAGE_CHANGE_CONFIRMATION = "stage_change_confirmation",
  COLLECT_CANDIDATE_AVAILABILITY = "collect_candidate_availability",
}

export type ModalProps = {
  [ModalName.TEST]: {
    input: Record<string, never>;
    result: undefined;
  };
  [ModalName.EXTENSION_INSTALL]: {
    input: ExtensionInstallProps;
    result: undefined;
  };
  [ModalName.EDIT_GUIDE_INTERVIEW_PLAN]: {
    input: EditGuideInterviewPlanModalProps;
    result: undefined;
  };
  [ModalName.EDIT_GUIDE_TEMPLATE_INTERVIEW_PLAN]: {
    input: EditGuideTemplateInterviewPlanModalProps;
    result: undefined;
  };
  [ModalName.STAGE_CHANGE_CONFIRMATION]: {
    input: StageChangeConfirmationViewProps;
    result: undefined;
  };
  [ModalName.COLLECT_CANDIDATE_AVAILABILITY]: {
    input: CollectCandidateAvailabilityDialogProps;
    result: {
      timezone: string;
      availabilities: PersistableCalendarEvent[];
    };
  };
};

export const ModalPageNames: Record<ModalName, Pages> = {
  [ModalName.TEST]: "Test Modal",
  [ModalName.EXTENSION_INSTALL]: "Extension Install Modal",
  [ModalName.EDIT_GUIDE_INTERVIEW_PLAN]: "Edit Guide Interview Plan Modal",
  [ModalName.EDIT_GUIDE_TEMPLATE_INTERVIEW_PLAN]:
    "Edit Guide Template Interview Plan Modal",
  [ModalName.STAGE_CHANGE_CONFIRMATION]: "Stage Change Confirmation Modal",
  [ModalName.COLLECT_CANDIDATE_AVAILABILITY]:
    "Collect Candidate Availability Modal",
};

export type Payloads = {
  [K in keyof ModalProps]: {
    key: string;
    name: K;
    result: ModalProps[K]["result"];
  };
};
export type ResultPayload = Payloads[ModalName];
export type OnResultCallback = (payload: ResultPayload) => unknown;

export interface ModalContextValue {
  active?: {
    key: string;
    name: ModalName;
    // TODO: Improve this typing by linking props to name. Not required.
    props: ModalProps[ModalName]["input"];
  };
  setActive: <N extends ModalName>(
    name: N,
    props: ModalProps[N]["input"],
    key?: string
  ) => string;
  isActive: (name: ModalName) => boolean;
  close: (payload?: ResultPayload) => void;
  addOnResultCallback: (callback: OnResultCallback) => void;
  removeOnResultCallback: (callback: OnResultCallback) => void;
}

const ModalContext = React.createContext<ModalContextValue | undefined>(
  undefined
);

interface ModalProviderProps {
  children?: ReactNode;
}

export function ModalProvider({ children }: ModalProviderProps) {
  const resultCallbacksRef = useRef<OnResultCallback[]>([]);
  const trackPage = useTrackPage();
  const [active, setActive] = useState<ModalContextValue["active"]>();

  const value = useMemo(
    (): ModalContextValue => ({
      active,
      setActive: (name, props, defaultKey) => {
        const key = defaultKey ?? uuidv4();
        trackPage(ModalPageNames[name]);
        setActive({ key, name, props });
        return key;
      },
      isActive: (name) => active?.name === name,
      close: (payload) => {
        if (payload) {
          resultCallbacksRef.current.forEach((callback) => callback(payload));
        }

        if (active && (!payload || payload.name === active.name)) {
          setActive(undefined);
        }
      },
      addOnResultCallback: (callback) =>
        resultCallbacksRef.current.push(callback),
      removeOnResultCallback: (callback) => {
        resultCallbacksRef.current = resultCallbacksRef.current.filter(
          (cb) => cb !== callback
        );
      },
    }),
    [active, trackPage]
  );

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
}

export const useModalManager = <
  TModalName extends ModalName,
  TUpdateFragmentData,
  TUpdateFragmentVariables
>({
  modalName,
  cacheUpdate,
}: {
  modalName: TModalName;
  cacheUpdate?: {
    getFragmentInfo: (
      data: ModalProps[TModalName]["result"] extends {
        mutationResult?: unknown;
      }
        ? ModalProps[TModalName]["result"]["mutationResult"]
        : never
    ) => DataProxy.UpdateFragmentOptions<
      TUpdateFragmentData,
      TUpdateFragmentVariables
    > | null;
    update: (
      data: ModalProps[TModalName]["result"] extends {
        mutationResult?: unknown;
      }
        ? ModalProps[TModalName]["result"]["mutationResult"]
        : never,
      existing: TUpdateFragmentData
    ) => TUpdateFragmentData | null;
  };
}) => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("ModalContext cannot be used without ModalProvider");
  }

  const client = useApolloClient();

  useEffect(() => {
    const writeToCacheFromExtension: OnResultCallback = (payload) => {
      if (payload.name === modalName) {
        const updatedData =
          payload.result && "mutationResult" in payload.result
            ? payload.result?.mutationResult
            : undefined;

        if (updatedData && !cacheUpdate) {
          console.warn("Must configure cache update to listen to modal result");

          return;
        }

        if (cacheUpdate && !updatedData) {
          console.warn("Cache update configured but modal not sending result");

          return;
        }

        if (cacheUpdate && updatedData) {
          // @ts-expect-error TypeScript can't figure this out
          const fragmentInfo = cacheUpdate.getFragmentInfo(updatedData);

          if (!fragmentInfo) {
            return;
          }

          client.cache.updateFragment(fragmentInfo, (existing) => {
            if (!existing) {
              return null;
            }

            // @ts-expect-error TypeScript can't figure this out
            return cacheUpdate.update(updatedData, existing);
          });
        }
      }
    };

    context.addOnResultCallback(writeToCacheFromExtension);

    return () => context.removeOnResultCallback(writeToCacheFromExtension);
  });

  const contextValue = useMemo(() => {
    return {
      ...context,
      show: (props: ModalProps[TModalName]["input"]) => {
        context.setActive(modalName, props);
      },
    };
  }, [context, modalName]);

  return contextValue;
};

const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("ModalContext cannot be used without ModalProvider");
  }

  return context;
};

export const useOptionalModalContext = () => {
  const context = useContext(ModalContext);
  return context;
};

export function ExtensionModalProviderInner({ children }: ModalProviderProps) {
  const modalManager = useModalContext();
  const messenger = useMessenger();

  useEffect(() => {
    const listener = (ev: MessageEvent) => {
      if (typeof ev.data === "object") {
        if (ev.data.command === "modal") {
          modalManager.setActive(ev.data.name, ev.data.props, ev.data.key);
        } else if (ev.data.command === "modal-close") {
          modalManager.close(ev.data.payload);
        }
      }
    };
    window.addEventListener("message", listener);
    return () => window.removeEventListener("message", listener);
  }, [modalManager]);

  const value = useMemo(
    (): ModalContextValue => ({
      ...modalManager,
      setActive: (name, props, defaultKey) => {
        const key = defaultKey ?? uuidv4();
        messenger.send({ command: "modal", key, name, props });
        return key;
      },
      close: (payload) => {
        messenger.send({ command: "modal-close", payload });
      },
    }),
    [messenger, modalManager]
  );

  return (
    <GuideIdProvider guideId={useQueryStringValue("guideId")}>
      <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
    </GuideIdProvider>
  );
}

export function ExtensionModalProvider({ children }: ModalProviderProps) {
  if (inIframe()) {
    return (
      <ExtensionModalProviderInner>{children}</ExtensionModalProviderInner>
    );
  }
  return <ModalProvider>{children}</ModalProvider>;
}

export default useModalContext;
