import { useSession } from "next-auth/react";
import { useMemo } from "react";

export const useUser = () => {
  const { data: session } = useSession();

  return useMemo(() => {
    if (!session?.user?.id) {
      return null;
    }
    return {
      id: session?.user?.id,
      email: session?.user?.email,
    };
  }, [session?.user?.email, session?.user?.id]);
};
