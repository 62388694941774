import { Organization, User } from "./User";

export type CustomLDProperties = Record<
  string,
  string | number | boolean | (string | number | boolean)[]
>;

export const buildOrganizationProperties = (organization: Organization) => {
  return {
    organizationId: organization.id,
    organizationName: organization.name,
  };
};

export const userAsLDUser = ({
  user,
  custom,
}: {
  user: User;
  custom?: CustomLDProperties;
}) => {
  return {
    kind: "user",
    key: user.id,
    email: user.primaryEmail || undefined,
    firstName: user.firstName || undefined,
    lastName: user.lastName || undefined,
    ...(user.highestRole ? { highestRole: user.highestRole } : {}),
    ...(user.currentOrganization &&
      buildOrganizationProperties(user.currentOrganization)),
    ...custom,
  };
};
