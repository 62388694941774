import * as FullStory from "@fullstory/browser";
import { gql } from "generated/graphql-codegen";
import { UserForFullstoryFragment } from "generated/graphql-codegen/graphql";
import React, { useEffect } from "react";

gql(`
fragment UserForFullstory on User {
  id
  primaryEmail
  fullName
  createdAt
}
`);

interface FullstoryWrapperProps {
  children?: React.ReactNode;
  user?: UserForFullstoryFragment | null;
}

export function FullstoryWrapper({ children, user }: FullstoryWrapperProps) {
  useEffect(() => {
    if (user) {
      FullStory.identify(user.id, {
        email: user.primaryEmail ?? undefined,
        displayName: user.fullName,
      });
    }
  }, [user]);

  return <>{children}</>;
}
