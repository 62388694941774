export default class PrismaError extends Error {
  errorId: string;

  model: string;

  operation: string;

  constructor({
    model,
    operation,
    message,
    originalError,
    errorId,
  }: {
    model: string;
    message: string;
    operation: string;
    originalError: Error;
    errorId: string;
  }) {
    super(`${model}.${operation} error - ${message}`, {
      cause: originalError,
    });

    this.model = model;
    this.errorId = errorId;
    this.operation = operation;
    this.name = "PrismaError";
  }
}
