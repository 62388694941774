import { calendarTimezoneInternalAtom } from "client/components/calendar/settings";
import { Provider } from "jotai";
import { useHydrateAtoms } from "jotai/utils";
import { ReactNode } from "react";

import { TimezoneContext } from "./context";

type TimezoneProviderProps = {
  children: ReactNode;
  timezone?: string | null;
};

/**
 * The calendar uses a jotai atom to manage its timezone internally
 * Whenever we set the timezone in the provider, hydate with this default
 */
function CalendarTimezoneHydration({ timezone }: { timezone: string }) {
  useHydrateAtoms([[calendarTimezoneInternalAtom, timezone]]);

  return null;
}

export function TimezoneProvider({
  children,
  timezone,
}: TimezoneProviderProps) {
  if (!timezone) {
    // If no timezone is provided, we want to just use the whatever the higher level provider has set timezone to
    // Most common case is a lower level candidate timezone wrapper but the candidate doesn't have a timezone set
    return <>{children}</>;
  }

  return (
    <TimezoneContext.Provider value={timezone}>
      <Provider>
        <CalendarTimezoneHydration timezone={timezone} />
        {children}
      </Provider>
    </TimezoneContext.Provider>
  );
}
