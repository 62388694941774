import { User, UserMembership } from "@prisma/client";
import { strings } from "@resource/common";
import { RoleEnum } from "enums/role-enum";
import {
  AccountStatusEnum,
  CompanyEmployeeSizeEnum,
  CompanyTalentTeamSizeEnum,
  PersonaEnum,
  UserForAnalyticsFragment,
} from "generated/graphql-codegen/graphql";
import { first } from "lodash";
// import UserMembershipModel from "models/UserMembership";
import { OrganizationWithDomains } from "server/types/organization";

type ResolvedUser = NonNullable<UserForAnalyticsFragment>;
type ResolvedOrganization = NonNullable<ResolvedUser["currentOrganization"]>;

export const buildResolvedOrganizationFromOrganization = (
  organization: OrganizationWithDomains
) => {
  const resolvedOrganization: ResolvedOrganization = {
    __typename: "Organization",
    companyLogoUrl: organization.companyLogoUrl,
    id: organization.id,
    name: organization.name,
    createdAt: organization.createdAt.toISOString(),
    accountStatus:
      organization.accountStatus &&
      AccountStatusEnum[organization.accountStatus],
    employeeCountEnum:
      organization.employeeCountEnum &&
      CompanyEmployeeSizeEnum[organization.employeeCountEnum],
    talentTeamCountEnum:
      organization.talentTeamCountEnum &&
      CompanyTalentTeamSizeEnum[organization.talentTeamCountEnum],
    contractStartDate: organization.contractStartDate
      ? organization.contractStartDate.toISOString()
      : null,
    domains: organization.domains.map(({ domain }) => domain),
  };
  return resolvedOrganization;
};

export const buildResolvedUserFromUser = ({
  user,
  userMembership,
  organization,
}: {
  user: User;
  userMembership: UserMembership;
  organization: OrganizationWithDomains;
}) => {
  const resolvedUser: ResolvedUser = {
    __typename: "User",
    id: user.id,
    intercomHash: "",
    currentUserMembership: {
      __typename: "UserMembership",
      id: userMembership.id,
      createdAt: userMembership.createdAt.toISOString(),
      imageUrl:
        userMembership.imageUrlOverride ?? userMembership.imageUrlStamped,
      name: strings.joinNames(
        userMembership.firstNameOverride ?? userMembership.firstNameStamped,
        userMembership.lastNameOverride ?? userMembership.lastNameStamped
      ),
      email: userMembership.email ?? "",
      persona: userMembership.persona && PersonaEnum[userMembership.persona],
    },
    currentOrganization: {
      ...buildResolvedOrganizationFromOrganization(organization),
    },
  };
  return resolvedUser;
};

export const buildCommonOrgAttributes = (
  organization: ResolvedOrganization
) => ({
  id: organization.id,
  name: organization.name,
  avatar: organization.companyLogoUrl,
  status: organization.accountStatus,
  employees: organization.employeeCountEnum,
  talentTeam: organization.talentTeamCountEnum,
  contractStartDate: organization.contractStartDate,
  createdAt: organization.createdAt,
  website: first(organization.domains),
  version: "2.0",
  environment: process.env.NEXT_PUBLIC_APP_ENV,
});

export const buildGroupOrgAttributes = (
  organization: ResolvedOrganization
) => ({
  ...buildCommonOrgAttributes(organization),
  companyId: organization.id,
  companyName: organization.name,
});

export const buildOrganizationProperties = (
  organization: ResolvedOrganization
) => ({
  organization: buildCommonOrgAttributes(organization),
});

export const buildCommonIdentifyTraits = ({
  user,
  highestRole,
  organization,
}: {
  user: ResolvedUser;
  highestRole: RoleEnum | undefined;
  organization: ResolvedOrganization | null;
}) => ({
  version: "2.0",
  environment: process.env.NEXT_PUBLIC_APP_ENV,
  name: user.currentUserMembership?.name,
  email: user.currentUserMembership?.email,
  createdAt: user.currentUserMembership?.createdAt,
  imageUrl: user.currentUserMembership?.imageUrl,
  persona: user.currentUserMembership?.persona, // will be null for candidates
  role: highestRole,
  ...(organization ? buildOrganizationProperties(organization) : {}),
});

export const trackProperties = {
  sourceEmail: { source: "email" },
  isCustomerSuccess: { customerSuccess: true },
};
