import { ApolloError } from "@apollo/client";
import { RoleEnum } from "enums/role-enum";
import { gql } from "generated/graphql-codegen";
import {
  OrganizationFeaturesEnum,
  UserForAuthContextFragment,
} from "generated/graphql-codegen/graphql";
import jsCookie from "js-cookie";
import { clearPersistedCache } from "lib/apolloClient";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useEventCallback } from "react-hooks/useEventCallback";
import {
  isRoleAllowed,
  PermissionType,
  StaffRoleEnum,
} from "shared/auth/permissions";
import {
  IMPERSONATION_MEMBERSHIP_ID_COOKIE_NAME,
  ORGANIZATION_ID_COOKIE_NAME,
} from "shared/constants/auth";
import {
  featureIsEnabledForOrg,
  featureIsEnabledOverrideForUserMembership,
} from "shared/utils/features";
import {
  LOGIN_COMMUNICATION_CHANNEL,
  postMessageAcrossTabs,
  useAcrossTabsListener,
} from "utils/acrossTabsMessaging";

import { useUser } from "./useUser";

// cookie util
// -----------

// these utils try to use CookieStore, and it not present they fall back to "js-cookie" and polling

// extremely barebones and incomplete types for the experimental CookieStore
// https://developer.mozilla.org/en-US/docs/Web/API/CookieStore
declare global {
  interface Window {
    cookieStore: {
      get: (name: string) => Promise<{ value: string }>;
      set: (options: {
        name: string;
        value: string;
        domain?: string;
        sameSite?: "strict" | "lax" | "none";
      }) => Promise<undefined>;
      addEventListener: (event: "change", cb: () => void) => void;
      removeEventListener: (event: "change", cb: () => void) => void;
    };
  }
}

async function getCookieValue(cookieName: string) {
  return window?.cookieStore
    ? (await window.cookieStore.get(cookieName))?.value
    : jsCookie.get(cookieName);
}

async function setCookieValue({
  secure,
  ...opt
}: Parameters<Window["cookieStore"]["set"]>[0] & {
  secure?: boolean;
}) {
  if (window?.cookieStore) window.cookieStore.set(opt);
  else {
    const { name, value, ...restOptions } = opt;
    jsCookie.set(name, value, { ...restOptions, secure, expires: 365 });
  }
}

const COOKIE_POLLING_INTERVAL_MS = 1000;

function useWatchCookieValue(
  cookieName: string
): [value: string | undefined, loading: boolean] {
  const [value, setValue] = useState<string>();
  const [loading, setLoading] = useState(true);

  // set initial value
  useEffect(() => {
    async function setInitialValue() {
      const initialValue = await getCookieValue(cookieName);
      if (initialValue) setValue(initialValue);
      setLoading(false);
    }
    setInitialValue();
  }, [cookieName]);

  // update value when it changes
  const callback = useEventCallback(async () => {
    if (loading) return;
    const currentValue = await getCookieValue(cookieName);
    if (currentValue !== value) setValue(currentValue);
  });
  const intervalRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (window?.cookieStore)
      window.cookieStore.addEventListener("change", callback);
    else
      intervalRef.current = setInterval(callback, COOKIE_POLLING_INTERVAL_MS);

    return () => {
      if (window?.cookieStore)
        window.cookieStore.removeEventListener("change", callback);
      else if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [callback]);

  return [value, loading];
}

// auth context
// ------------

type SSOProvider = "microsoft" | "google";

type AuthContextValue = {
  user?: UserForAuthContextFragment;
  ssoProvider?: SSOProvider;
  highestRole?: RoleEnum;
  error?: ApolloError;
  loading: boolean;
  onboardingComplete?: boolean;
  prospectOnboardingComplete?: boolean;
  organizationIdCookie?: string;
  organizationIdCookieLoading: boolean;
  impersonationMembershipIdCookie?: string;
  impersonationMembershipIdCookieLoading: boolean;
  switchToOrg: (organizationId: string, reload?: boolean) => void;
  impersonateUser: (userMembershipId: string, reload?: boolean) => void;
  checkRolePermissions: (
    permissions: PermissionType | PermissionType[]
  ) => boolean;
  checkStaffRole: (role: StaffRoleEnum) => boolean;
  hasFeatureEnabled: (
    features: OrganizationFeaturesEnum | OrganizationFeaturesEnum[]
  ) => boolean;
  onLogout(): Promise<void>;
  isCandidate: boolean;
};

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

gql(`
fragment UserForAuthContext on User {
  id
  isStaff
  isSuperuser
  fullName
  firstName
  lastName
  primaryEmail
  imageUrl
  createdAt
  availableOrganizationsCount
  canAccessCalendar
  canAccessCalendarRooms
  canAccessGoogleUsers
  timezone
  currentOrganization {
    id
    name
    prospectOnboardingComplete
    isZoomEnabled
    isGoogleCalendarEnabled
    isCodeSignalEnabled
    isCoderpadEnabled
    isHackerRankEnabled
    features
    defaultAvatarImageUrl
    atssyncAccounts {
      id
    }
    defaultGoogleUserMembership {
      id
      user {
        id
      }
    }
  }
  currentUserMembership {
    id
    onboardingComplete
    isCoderpadEnabled
    isHackerRankEnabled
    imageUrl
    name
    firstName
    lastName
    email
    defaultCalendarId
    featuresOverride
    highestRole {
      id
    }
  }
}
`);

export function AuthContextProvider({
  children,
  onLogout,
  refetchUser,
  userLoading: loading,
  userError: error,
  user,
}: {
  children?: ReactNode;
  onLogout(): Promise<void>;
  refetchUser: () => void;
  userLoading: boolean;
  userError?: ApolloError;
  user: UserForAuthContextFragment | undefined | null;
}) {
  const [organizationIdCookie, organizationIdCookieLoading] =
    useWatchCookieValue(ORGANIZATION_ID_COOKIE_NAME);
  const [
    impersonationMembershipIdCookie,
    impersonationMembershipIdCookieLoading,
  ] = useWatchCookieValue(IMPERSONATION_MEMBERSHIP_ID_COOKIE_NAME);

  // reload the page when the org id cookie changes
  const initialOrgIdCookieRef = useRef<string>();
  useEffect(() => {
    if (!organizationIdCookie) return;
    if (initialOrgIdCookieRef.current === organizationIdCookie) return;
    if (initialOrgIdCookieRef.current !== undefined) window.location.reload();
    initialOrgIdCookieRef.current = organizationIdCookie;
  }, [organizationIdCookie]);

  const authUser = useUser();

  // TODO: support other provider(s)
  const ssoProvider = useMemo(() => {
    if (!authUser) return undefined;
    return "google";
  }, [authUser]);

  useEffect(() => {
    postMessageAcrossTabs(
      LOGIN_COMMUNICATION_CHANNEL,
      new Date().toISOString()
    );
  }, [authUser]);

  useAcrossTabsListener({
    channel: LOGIN_COMMUNICATION_CHANNEL,
    listener: () => refetchUser(),
  });

  const switchToOrg = useCallback(
    async (organizationId: string, reload = true) => {
      if (organizationIdCookie === organizationId) return;
      await setCookieValue({
        name: ORGANIZATION_ID_COOKIE_NAME,
        value: organizationId,
        sameSite: "none",
        secure: true,
      });
      // Also clear the impersonation membership any time we change orgs
      await setCookieValue({
        name: IMPERSONATION_MEMBERSHIP_ID_COOKIE_NAME,
        value: "",
        sameSite: "none",
        secure: true,
      });
      await clearPersistedCache();
      if (reload) {
        window.location.reload();
      }
    },
    [organizationIdCookie]
  );

  const impersonateUser = useCallback(
    async (userMembershipId: string, reload = true) => {
      if (impersonationMembershipIdCookie === userMembershipId) return;
      await setCookieValue({
        name: IMPERSONATION_MEMBERSHIP_ID_COOKIE_NAME,
        value: userMembershipId,
        sameSite: "none",
        secure: true,
      });
      await clearPersistedCache();
      if (reload) {
        window.location.reload();
      }
    },
    [impersonationMembershipIdCookie]
  );

  const baseValue = useMemo(
    () => ({
      checkRolePermissions: () => false,
      checkStaffRole: () => false,
      hasFeatureEnabled: () => false,
      loading,
      error,
      organizationIdCookie,
      organizationIdCookieLoading,
      impersonationMembershipIdCookie,
      impersonationMembershipIdCookieLoading,
      impersonateUser,
      switchToOrg,
      onLogout,
      isCoderpadEnabled: false,
      isHackerRankEnabled: false,
      isCandidate: false,
    }),
    [
      error,
      loading,
      organizationIdCookie,
      organizationIdCookieLoading,
      switchToOrg,
      impersonationMembershipIdCookie,
      impersonationMembershipIdCookieLoading,
      impersonateUser,
      onLogout,
    ]
  );

  const value: AuthContextValue = useMemo(() => {
    if (!user) return baseValue;

    const userMembership = user?.currentUserMembership;

    const prospectOnboardingComplete =
      user?.currentOrganization?.prospectOnboardingComplete;

    // TODO: memoize
    function checkStaffRole(auth: StaffRoleEnum) {
      switch (auth) {
        case StaffRoleEnum.STAFF:
          return !!(user?.isStaff || user?.isSuperuser);
        case StaffRoleEnum.SUPERUSER:
          return !!user?.isSuperuser;
        default:
          return false;
      }
    }

    const onboardingComplete = userMembership
      ? userMembership.onboardingComplete
      : checkStaffRole(StaffRoleEnum.STAFF);

    const highestRole = userMembership?.highestRole?.id
      ? (userMembership.highestRole.id as RoleEnum)
      : undefined;

    // TODO: memoize
    function checkRolePermissions(
      permissions: PermissionType | PermissionType[]
    ) {
      if (user?.isStaff || user?.isSuperuser) return true;
      if (!userMembership || !highestRole) return false;

      const perms = [permissions].flat();
      return isRoleAllowed([highestRole], perms);
    }

    function hasFeatureEnabled(
      features: OrganizationFeaturesEnum | OrganizationFeaturesEnum[]
    ) {
      if (!user?.currentOrganization) return false;

      const enabledForOrg = featureIsEnabledForOrg(
        features,
        user.currentOrganization
      );
      const enabledOverrideForUserMembership = user?.currentUserMembership
        ? featureIsEnabledOverrideForUserMembership(
            features,
            user.currentUserMembership
          )
        : false;

      return enabledForOrg || enabledOverrideForUserMembership;
    }

    const isCandidate = highestRole === RoleEnum.Candidate;

    return {
      ...baseValue,
      user,
      ssoProvider,
      highestRole,
      checkRolePermissions,
      checkStaffRole,
      hasFeatureEnabled,
      onboardingComplete,
      prospectOnboardingComplete,
      isCandidate,
    };
  }, [user, baseValue, ssoProvider]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuthContext() {
  const value = useContext(AuthContext);

  if (!value) {
    const defaultValue: AuthContextValue = {
      checkRolePermissions: () => false,
      checkStaffRole: () => false,
      hasFeatureEnabled: () => false,
      loading: true,
      organizationIdCookieLoading: true,
      impersonationMembershipIdCookieLoading: true,
      switchToOrg: () => ({}),
      impersonateUser: () => ({}),
      onLogout: () => Promise.resolve(),
      isCandidate: false,
    };

    console.warn("useAuthContext was called outside of an AuthContextProvider");

    return defaultValue;
  }

  return value;
}
