export const GuidePermission = {
  POST: {
    CREATE: "post:create",
    REPLY: "post:reply",
    DELETE: "post:delete",
    EDIT: "post:edit",
    ADD_EXTERNAL_CC: "post:add_external_cc",
  },
  WRITE: "write",
  VIEW: "view",
  MARK_READ: "mark_read",
};

type Vals<T> = T[keyof T];
type Leaves<T> = Vals<{ [P in keyof T]-?: Vals<T[P]> }>;
export type GuidePermission = Leaves<typeof GuidePermission>;
