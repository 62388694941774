import { Timezone } from "./types";

export const momentTimezonesForNonDst: Timezone[] = [
  {
    id: "Africa/Abidjan",
    name: "Africa/Abidjan (GMT)",
    value: "Africa/Abidjan LMT GMT",
    abbrs: ["LMT", "GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "Africa/Accra",
    name: "Africa/Accra (GMT)",
    value: "Africa/Accra LMT GMT",
    abbrs: ["LMT", "GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "Africa/Addis_Ababa",
    name: "Africa/Addis Ababa (EAT)",
    value: "Africa/Addis Ababa LMT +0230 EAT +0245",
    abbrs: ["LMT", "+0230", "EAT", "+0245"],
    currentAbbr: "EAT",
  },
  {
    id: "Africa/Algiers",
    name: "Africa/Algiers (CET)",
    value: "Africa/Algiers LMT PMT WET WEST CET CEST",
    abbrs: ["LMT", "PMT", "WET", "WEST", "CET", "CEST"],
    currentAbbr: "CET",
  },
  {
    id: "Africa/Asmara",
    name: "Africa/Asmara (EAT)",
    value: "Africa/Asmara LMT +0230 EAT +0245",
    abbrs: ["LMT", "+0230", "EAT", "+0245"],
    currentAbbr: "EAT",
  },
  {
    id: "Africa/Asmera",
    name: "Africa/Asmera (EAT)",
    value: "Africa/Asmera LMT +0230 EAT +0245",
    abbrs: ["LMT", "+0230", "EAT", "+0245"],
    currentAbbr: "EAT",
  },
  {
    id: "Africa/Bamako",
    name: "Africa/Bamako (GMT)",
    value: "Africa/Bamako LMT GMT",
    abbrs: ["LMT", "GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "Africa/Bangui",
    name: "Africa/Bangui (WAT)",
    value: "Africa/Bangui LMT GMT +0030 WAT",
    abbrs: ["LMT", "GMT", "+0030", "WAT"],
    currentAbbr: "WAT",
  },
  {
    id: "Africa/Banjul",
    name: "Africa/Banjul (GMT)",
    value: "Africa/Banjul LMT GMT",
    abbrs: ["LMT", "GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "Africa/Bissau",
    name: "Africa/Bissau (GMT)",
    value: "Africa/Bissau LMT -01 GMT",
    abbrs: ["LMT", "-01", "GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "Africa/Blantyre",
    name: "Africa/Blantyre (CAT)",
    value: "Africa/Blantyre LMT CAT",
    abbrs: ["LMT", "CAT"],
    currentAbbr: "CAT",
  },
  {
    id: "Africa/Brazzaville",
    name: "Africa/Brazzaville (WAT)",
    value: "Africa/Brazzaville LMT GMT +0030 WAT",
    abbrs: ["LMT", "GMT", "+0030", "WAT"],
    currentAbbr: "WAT",
  },
  {
    id: "Africa/Bujumbura",
    name: "Africa/Bujumbura (CAT)",
    value: "Africa/Bujumbura LMT CAT",
    abbrs: ["LMT", "CAT"],
    currentAbbr: "CAT",
  },
  {
    id: "Africa/Cairo",
    name: "Africa/Cairo (EET)",
    value: "Africa/Cairo LMT EET EEST",
    abbrs: ["LMT", "EET", "EEST"],
    currentAbbr: "EET",
  },
  {
    id: "Africa/Casablanca",
    name: "Africa/Casablanca (+01)",
    value: "Africa/Casablanca LMT +00 +01",
    abbrs: ["LMT", "+00", "+01"],
    currentAbbr: "+01",
  },
  {
    id: "Africa/Ceuta",
    name: "Africa/Ceuta (CET)",
    value: "Africa/Ceuta LMT WET WEST CET CEST",
    abbrs: ["LMT", "WET", "WEST", "CET", "CEST"],
    currentAbbr: "CET",
  },
  {
    id: "Africa/Conakry",
    name: "Africa/Conakry (GMT)",
    value: "Africa/Conakry LMT GMT",
    abbrs: ["LMT", "GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "Africa/Dakar",
    name: "Africa/Dakar (GMT)",
    value: "Africa/Dakar LMT GMT",
    abbrs: ["LMT", "GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "Africa/Dar_es_Salaam",
    name: "Africa/Dar es Salaam (EAT)",
    value: "Africa/Dar es Salaam LMT +0230 EAT +0245",
    abbrs: ["LMT", "+0230", "EAT", "+0245"],
    currentAbbr: "EAT",
  },
  {
    id: "Africa/Djibouti",
    name: "Africa/Djibouti (EAT)",
    value: "Africa/Djibouti LMT +0230 EAT +0245",
    abbrs: ["LMT", "+0230", "EAT", "+0245"],
    currentAbbr: "EAT",
  },
  {
    id: "Africa/Douala",
    name: "Africa/Douala (WAT)",
    value: "Africa/Douala LMT GMT +0030 WAT",
    abbrs: ["LMT", "GMT", "+0030", "WAT"],
    currentAbbr: "WAT",
  },
  {
    id: "Africa/El_Aaiun",
    name: "Africa/El Aaiun (+01)",
    value: "Africa/El Aaiun LMT -01 +00 +01",
    abbrs: ["LMT", "-01", "+00", "+01"],
    currentAbbr: "+01",
  },
  {
    id: "Africa/Freetown",
    name: "Africa/Freetown (GMT)",
    value: "Africa/Freetown LMT GMT",
    abbrs: ["LMT", "GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "Africa/Gaborone",
    name: "Africa/Gaborone (CAT)",
    value: "Africa/Gaborone LMT CAT",
    abbrs: ["LMT", "CAT"],
    currentAbbr: "CAT",
  },
  {
    id: "Africa/Harare",
    name: "Africa/Harare (CAT)",
    value: "Africa/Harare LMT CAT",
    abbrs: ["LMT", "CAT"],
    currentAbbr: "CAT",
  },
  {
    id: "Africa/Johannesburg",
    name: "Africa/Johannesburg (SAST)",
    value: "Africa/Johannesburg LMT SAST",
    abbrs: ["LMT", "SAST"],
    currentAbbr: "SAST",
  },
  {
    id: "Africa/Juba",
    name: "Africa/Juba (CAT)",
    value: "Africa/Juba LMT CAT CAST EAT",
    abbrs: ["LMT", "CAT", "CAST", "EAT"],
    currentAbbr: "CAT",
  },
  {
    id: "Africa/Kampala",
    name: "Africa/Kampala (EAT)",
    value: "Africa/Kampala LMT +0230 EAT +0245",
    abbrs: ["LMT", "+0230", "EAT", "+0245"],
    currentAbbr: "EAT",
  },
  {
    id: "Africa/Khartoum",
    name: "Africa/Khartoum (CAT)",
    value: "Africa/Khartoum LMT CAT CAST EAT",
    abbrs: ["LMT", "CAT", "CAST", "EAT"],
    currentAbbr: "CAT",
  },
  {
    id: "Africa/Kigali",
    name: "Africa/Kigali (CAT)",
    value: "Africa/Kigali LMT CAT",
    abbrs: ["LMT", "CAT"],
    currentAbbr: "CAT",
  },
  {
    id: "Africa/Kinshasa",
    name: "Africa/Kinshasa (WAT)",
    value: "Africa/Kinshasa LMT GMT +0030 WAT",
    abbrs: ["LMT", "GMT", "+0030", "WAT"],
    currentAbbr: "WAT",
  },
  {
    id: "Africa/Lagos",
    name: "Africa/Lagos (WAT)",
    value: "Africa/Lagos LMT GMT +0030 WAT",
    abbrs: ["LMT", "GMT", "+0030", "WAT"],
    currentAbbr: "WAT",
  },
  {
    id: "Africa/Libreville",
    name: "Africa/Libreville (WAT)",
    value: "Africa/Libreville LMT GMT +0030 WAT",
    abbrs: ["LMT", "GMT", "+0030", "WAT"],
    currentAbbr: "WAT",
  },
  {
    id: "Africa/Lome",
    name: "Africa/Lome (GMT)",
    value: "Africa/Lome LMT GMT",
    abbrs: ["LMT", "GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "Africa/Luanda",
    name: "Africa/Luanda (WAT)",
    value: "Africa/Luanda LMT GMT +0030 WAT",
    abbrs: ["LMT", "GMT", "+0030", "WAT"],
    currentAbbr: "WAT",
  },
  {
    id: "Africa/Lubumbashi",
    name: "Africa/Lubumbashi (CAT)",
    value: "Africa/Lubumbashi LMT CAT",
    abbrs: ["LMT", "CAT"],
    currentAbbr: "CAT",
  },
  {
    id: "Africa/Lusaka",
    name: "Africa/Lusaka (CAT)",
    value: "Africa/Lusaka LMT CAT",
    abbrs: ["LMT", "CAT"],
    currentAbbr: "CAT",
  },
  {
    id: "Africa/Malabo",
    name: "Africa/Malabo (WAT)",
    value: "Africa/Malabo LMT GMT +0030 WAT",
    abbrs: ["LMT", "GMT", "+0030", "WAT"],
    currentAbbr: "WAT",
  },
  {
    id: "Africa/Maputo",
    name: "Africa/Maputo (CAT)",
    value: "Africa/Maputo LMT CAT",
    abbrs: ["LMT", "CAT"],
    currentAbbr: "CAT",
  },
  {
    id: "Africa/Maseru",
    name: "Africa/Maseru (SAST)",
    value: "Africa/Maseru LMT SAST",
    abbrs: ["LMT", "SAST"],
    currentAbbr: "SAST",
  },
  {
    id: "Africa/Mbabane",
    name: "Africa/Mbabane (SAST)",
    value: "Africa/Mbabane LMT SAST",
    abbrs: ["LMT", "SAST"],
    currentAbbr: "SAST",
  },
  {
    id: "Africa/Mogadishu",
    name: "Africa/Mogadishu (EAT)",
    value: "Africa/Mogadishu LMT +0230 EAT +0245",
    abbrs: ["LMT", "+0230", "EAT", "+0245"],
    currentAbbr: "EAT",
  },
  {
    id: "Africa/Monrovia",
    name: "Africa/Monrovia (GMT)",
    value: "Africa/Monrovia LMT MMT GMT",
    abbrs: ["LMT", "MMT", "GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "Africa/Nairobi",
    name: "Africa/Nairobi (EAT)",
    value: "Africa/Nairobi LMT +0230 EAT +0245",
    abbrs: ["LMT", "+0230", "EAT", "+0245"],
    currentAbbr: "EAT",
  },
  {
    id: "Africa/Ndjamena",
    name: "Africa/Ndjamena (WAT)",
    value: "Africa/Ndjamena LMT WAT WAST",
    abbrs: ["LMT", "WAT", "WAST"],
    currentAbbr: "WAT",
  },
  {
    id: "Africa/Niamey",
    name: "Africa/Niamey (WAT)",
    value: "Africa/Niamey LMT GMT +0030 WAT",
    abbrs: ["LMT", "GMT", "+0030", "WAT"],
    currentAbbr: "WAT",
  },
  {
    id: "Africa/Nouakchott",
    name: "Africa/Nouakchott (GMT)",
    value: "Africa/Nouakchott LMT GMT",
    abbrs: ["LMT", "GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "Africa/Ouagadougou",
    name: "Africa/Ouagadougou (GMT)",
    value: "Africa/Ouagadougou LMT GMT",
    abbrs: ["LMT", "GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "Africa/Porto-Novo",
    name: "Africa/Porto-Novo (WAT)",
    value: "Africa/Porto-Novo LMT GMT +0030 WAT",
    abbrs: ["LMT", "GMT", "+0030", "WAT"],
    currentAbbr: "WAT",
  },
  {
    id: "Africa/Sao_Tome",
    name: "Africa/Sao Tome (GMT)",
    value: "Africa/Sao Tome LMT GMT WAT",
    abbrs: ["LMT", "GMT", "WAT"],
    currentAbbr: "GMT",
  },
  {
    id: "Africa/Timbuktu",
    name: "Africa/Timbuktu (GMT)",
    value: "Africa/Timbuktu LMT GMT",
    abbrs: ["LMT", "GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "Africa/Tripoli",
    name: "Africa/Tripoli (EET)",
    value: "Africa/Tripoli LMT CET CEST EET",
    abbrs: ["LMT", "CET", "CEST", "EET"],
    currentAbbr: "EET",
  },
  {
    id: "Africa/Tunis",
    name: "Africa/Tunis (CET)",
    value: "Africa/Tunis LMT PMT CET CEST",
    abbrs: ["LMT", "PMT", "CET", "CEST"],
    currentAbbr: "CET",
  },
  {
    id: "Africa/Windhoek",
    name: "Africa/Windhoek (CAT)",
    value: "Africa/Windhoek LMT +0130 SAST CAT WAT",
    abbrs: ["LMT", "+0130", "SAST", "CAT", "WAT"],
    currentAbbr: "CAT",
  },
  {
    id: "America/Adak",
    name: "America/Adak (HST)",
    value: "America/Adak LMT NST NWT NPT BST BDT AHST HST HDT",
    abbrs: ["LMT", "NST", "NWT", "NPT", "BST", "BDT", "AHST", "HST", "HDT"],
    currentAbbr: "HST",
  },
  {
    id: "America/Anchorage",
    name: "America/Anchorage (AKST)",
    value: "America/Anchorage LMT AST AWT APT AHST AHDT YST AKST AKDT",
    abbrs: ["LMT", "AST", "AWT", "APT", "AHST", "AHDT", "YST", "AKST", "AKDT"],
    currentAbbr: "AKST",
  },
  {
    id: "America/Anguilla",
    name: "America/Anguilla (AST)",
    value: "America/Anguilla LMT AST AWT APT",
    abbrs: ["LMT", "AST", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "America/Antigua",
    name: "America/Antigua (AST)",
    value: "America/Antigua LMT AST AWT APT",
    abbrs: ["LMT", "AST", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "America/Araguaina",
    name: "America/Araguaina (-03)",
    value: "America/Araguaina LMT -03 -02",
    abbrs: ["LMT", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Argentina/Buenos_Aires",
    name: "America/Argentina/Buenos Aires (-03)",
    value: "America/Argentina/Buenos Aires LMT CMT -04 -03 -02",
    abbrs: ["LMT", "CMT", "-04", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Argentina/Catamarca",
    name: "America/Argentina/Catamarca (-03)",
    value: "America/Argentina/Catamarca LMT CMT -04 -03 -02",
    abbrs: ["LMT", "CMT", "-04", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Argentina/ComodRivadavia",
    name: "America/Argentina/ComodRivadavia (-03)",
    value: "America/Argentina/ComodRivadavia LMT CMT -04 -03 -02",
    abbrs: ["LMT", "CMT", "-04", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Argentina/Cordoba",
    name: "America/Argentina/Cordoba (-03)",
    value: "America/Argentina/Cordoba LMT CMT -04 -03 -02",
    abbrs: ["LMT", "CMT", "-04", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Argentina/Jujuy",
    name: "America/Argentina/Jujuy (-03)",
    value: "America/Argentina/Jujuy LMT CMT -04 -03 -02",
    abbrs: ["LMT", "CMT", "-04", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Argentina/La_Rioja",
    name: "America/Argentina/La Rioja (-03)",
    value: "America/Argentina/La Rioja LMT CMT -04 -03 -02",
    abbrs: ["LMT", "CMT", "-04", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Argentina/Mendoza",
    name: "America/Argentina/Mendoza (-03)",
    value: "America/Argentina/Mendoza LMT CMT -04 -03 -02",
    abbrs: ["LMT", "CMT", "-04", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Argentina/Rio_Gallegos",
    name: "America/Argentina/Rio Gallegos (-03)",
    value: "America/Argentina/Rio Gallegos LMT CMT -04 -03 -02",
    abbrs: ["LMT", "CMT", "-04", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Argentina/Salta",
    name: "America/Argentina/Salta (-03)",
    value: "America/Argentina/Salta LMT CMT -04 -03 -02",
    abbrs: ["LMT", "CMT", "-04", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Argentina/San_Juan",
    name: "America/Argentina/San Juan (-03)",
    value: "America/Argentina/San Juan LMT CMT -04 -03 -02",
    abbrs: ["LMT", "CMT", "-04", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Argentina/San_Luis",
    name: "America/Argentina/San Luis (-03)",
    value: "America/Argentina/San Luis LMT CMT -04 -03 -02",
    abbrs: ["LMT", "CMT", "-04", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Argentina/Tucuman",
    name: "America/Argentina/Tucuman (-03)",
    value: "America/Argentina/Tucuman LMT CMT -04 -03 -02",
    abbrs: ["LMT", "CMT", "-04", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Argentina/Ushuaia",
    name: "America/Argentina/Ushuaia (-03)",
    value: "America/Argentina/Ushuaia LMT CMT -04 -03 -02",
    abbrs: ["LMT", "CMT", "-04", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Aruba",
    name: "America/Aruba (AST)",
    value: "America/Aruba LMT AST AWT APT",
    abbrs: ["LMT", "AST", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "America/Asuncion",
    name: "America/Asuncion (-03)",
    value: "America/Asuncion LMT AMT -04 -03",
    abbrs: ["LMT", "AMT", "-04", "-03"],
    currentAbbr: "-03",
  },
  {
    id: "America/Atikokan",
    name: "America/Atikokan (EST)",
    value: "America/Atikokan LMT CMT EST",
    abbrs: ["LMT", "CMT", "EST"],
    currentAbbr: "EST",
  },
  {
    id: "America/Atka",
    name: "America/Atka (HST)",
    value: "America/Atka LMT NST NWT NPT BST BDT AHST HST HDT",
    abbrs: ["LMT", "NST", "NWT", "NPT", "BST", "BDT", "AHST", "HST", "HDT"],
    currentAbbr: "HST",
  },
  {
    id: "America/Bahia",
    name: "America/Bahia (-03)",
    value: "America/Bahia LMT -03 -02",
    abbrs: ["LMT", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Bahia_Banderas",
    name: "America/Bahia Banderas (CST)",
    value: "America/Bahia Banderas LMT MST CST MDT CDT",
    abbrs: ["LMT", "MST", "CST", "MDT", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "America/Barbados",
    name: "America/Barbados (AST)",
    value: "America/Barbados LMT AST ADT -0330",
    abbrs: ["LMT", "AST", "ADT", "-0330"],
    currentAbbr: "AST",
  },
  {
    id: "America/Belem",
    name: "America/Belem (-03)",
    value: "America/Belem LMT -03 -02",
    abbrs: ["LMT", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Belize",
    name: "America/Belize (CST)",
    value: "America/Belize LMT CST -0530 CWT CPT CDT",
    abbrs: ["LMT", "CST", "-0530", "CWT", "CPT", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "America/Blanc-Sablon",
    name: "America/Blanc-Sablon (AST)",
    value: "America/Blanc-Sablon LMT AST AWT APT",
    abbrs: ["LMT", "AST", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "America/Boa_Vista",
    name: "America/Boa Vista (-04)",
    value: "America/Boa Vista LMT -04 -03",
    abbrs: ["LMT", "-04", "-03"],
    currentAbbr: "-04",
  },
  {
    id: "America/Bogota",
    name: "America/Bogota (-05)",
    value: "America/Bogota LMT BMT -05 -04",
    abbrs: ["LMT", "BMT", "-05", "-04"],
    currentAbbr: "-05",
  },
  {
    id: "America/Boise",
    name: "America/Boise (MST)",
    value: "America/Boise LMT PST PDT MST MWT MPT MDT",
    abbrs: ["LMT", "PST", "PDT", "MST", "MWT", "MPT", "MDT"],
    currentAbbr: "MST",
  },
  {
    id: "America/Buenos_Aires",
    name: "America/Buenos Aires (-03)",
    value: "America/Buenos Aires LMT CMT -04 -03 -02",
    abbrs: ["LMT", "CMT", "-04", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Cambridge_Bay",
    name: "America/Cambridge Bay (MST)",
    value: "America/Cambridge Bay -00 MST MWT MPT MDT CST CDT EST",
    abbrs: ["-00", "MST", "MWT", "MPT", "MDT", "CST", "CDT", "EST"],
    currentAbbr: "MST",
  },
  {
    id: "America/Campo_Grande",
    name: "America/Campo Grande (-04)",
    value: "America/Campo Grande LMT -04 -03",
    abbrs: ["LMT", "-04", "-03"],
    currentAbbr: "-04",
  },
  {
    id: "America/Cancun",
    name: "America/Cancun (EST)",
    value: "America/Cancun LMT CST EST CDT EDT",
    abbrs: ["LMT", "CST", "EST", "CDT", "EDT"],
    currentAbbr: "EST",
  },
  {
    id: "America/Caracas",
    name: "America/Caracas (-04)",
    value: "America/Caracas LMT CMT -0430 -04",
    abbrs: ["LMT", "CMT", "-0430", "-04"],
    currentAbbr: "-04",
  },
  {
    id: "America/Catamarca",
    name: "America/Catamarca (-03)",
    value: "America/Catamarca LMT CMT -04 -03 -02",
    abbrs: ["LMT", "CMT", "-04", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Cayenne",
    name: "America/Cayenne (-03)",
    value: "America/Cayenne LMT -04 -03",
    abbrs: ["LMT", "-04", "-03"],
    currentAbbr: "-03",
  },
  {
    id: "America/Cayman",
    name: "America/Cayman (EST)",
    value: "America/Cayman LMT CMT EST",
    abbrs: ["LMT", "CMT", "EST"],
    currentAbbr: "EST",
  },
  {
    id: "America/Chicago",
    name: "America/Chicago (CST)",
    value: "America/Chicago LMT CST CDT EST CWT CPT",
    abbrs: ["LMT", "CST", "CDT", "EST", "CWT", "CPT"],
    currentAbbr: "CST",
  },
  {
    id: "America/Chihuahua",
    name: "America/Chihuahua (CST)",
    value: "America/Chihuahua LMT MST CST MDT CDT",
    abbrs: ["LMT", "MST", "CST", "MDT", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "America/Ciudad_Juarez",
    name: "America/Ciudad Juarez (MST)",
    value: "America/Ciudad Juarez LMT MST CST MDT CDT",
    abbrs: ["LMT", "MST", "CST", "MDT", "CDT"],
    currentAbbr: "MST",
  },
  {
    id: "America/Coral_Harbour",
    name: "America/Coral Harbour (EST)",
    value: "America/Coral Harbour LMT CMT EST",
    abbrs: ["LMT", "CMT", "EST"],
    currentAbbr: "EST",
  },
  {
    id: "America/Cordoba",
    name: "America/Cordoba (-03)",
    value: "America/Cordoba LMT CMT -04 -03 -02",
    abbrs: ["LMT", "CMT", "-04", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Costa_Rica",
    name: "America/Costa Rica (CST)",
    value: "America/Costa Rica LMT SJMT CST CDT",
    abbrs: ["LMT", "SJMT", "CST", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "America/Creston",
    name: "America/Creston (MST)",
    value: "America/Creston LMT MST MDT MWT",
    abbrs: ["LMT", "MST", "MDT", "MWT"],
    currentAbbr: "MST",
  },
  {
    id: "America/Cuiaba",
    name: "America/Cuiaba (-04)",
    value: "America/Cuiaba LMT -04 -03",
    abbrs: ["LMT", "-04", "-03"],
    currentAbbr: "-04",
  },
  {
    id: "America/Curacao",
    name: "America/Curacao (AST)",
    value: "America/Curacao LMT AST AWT APT",
    abbrs: ["LMT", "AST", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "America/Danmarkshavn",
    name: "America/Danmarkshavn (GMT)",
    value: "America/Danmarkshavn LMT -03 -02 GMT",
    abbrs: ["LMT", "-03", "-02", "GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "America/Dawson",
    name: "America/Dawson (MST)",
    value: "America/Dawson LMT YST YDT YWT YPT YDDT PST PDT MST",
    abbrs: ["LMT", "YST", "YDT", "YWT", "YPT", "YDDT", "PST", "PDT", "MST"],
    currentAbbr: "MST",
  },
  {
    id: "America/Dawson_Creek",
    name: "America/Dawson Creek (MST)",
    value: "America/Dawson Creek LMT PST PDT PWT PPT MST",
    abbrs: ["LMT", "PST", "PDT", "PWT", "PPT", "MST"],
    currentAbbr: "MST",
  },
  {
    id: "America/Denver",
    name: "America/Denver (MST)",
    value: "America/Denver LMT MST MDT MWT MPT",
    abbrs: ["LMT", "MST", "MDT", "MWT", "MPT"],
    currentAbbr: "MST",
  },
  {
    id: "America/Detroit",
    name: "America/Detroit (EST)",
    value: "America/Detroit LMT CST EST EWT EPT EDT",
    abbrs: ["LMT", "CST", "EST", "EWT", "EPT", "EDT"],
    currentAbbr: "EST",
  },
  {
    id: "America/Dominica",
    name: "America/Dominica (AST)",
    value: "America/Dominica LMT AST AWT APT",
    abbrs: ["LMT", "AST", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "America/Edmonton",
    name: "America/Edmonton (MST)",
    value: "America/Edmonton LMT MST MDT MWT MPT",
    abbrs: ["LMT", "MST", "MDT", "MWT", "MPT"],
    currentAbbr: "MST",
  },
  {
    id: "America/Eirunepe",
    name: "America/Eirunepe (-05)",
    value: "America/Eirunepe LMT -05 -04",
    abbrs: ["LMT", "-05", "-04"],
    currentAbbr: "-05",
  },
  {
    id: "America/El_Salvador",
    name: "America/El Salvador (CST)",
    value: "America/El Salvador LMT CST CDT",
    abbrs: ["LMT", "CST", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "America/Ensenada",
    name: "America/Ensenada (PST)",
    value: "America/Ensenada LMT MST PST PDT PWT PPT",
    abbrs: ["LMT", "MST", "PST", "PDT", "PWT", "PPT"],
    currentAbbr: "PST",
  },
  {
    id: "America/Fort_Nelson",
    name: "America/Fort Nelson (MST)",
    value: "America/Fort Nelson LMT PST PDT PWT PPT MST",
    abbrs: ["LMT", "PST", "PDT", "PWT", "PPT", "MST"],
    currentAbbr: "MST",
  },
  {
    id: "America/Fort_Wayne",
    name: "America/Fort Wayne (EST)",
    value: "America/Fort Wayne LMT CST CDT CWT CPT EST EDT",
    abbrs: ["LMT", "CST", "CDT", "CWT", "CPT", "EST", "EDT"],
    currentAbbr: "EST",
  },
  {
    id: "America/Fortaleza",
    name: "America/Fortaleza (-03)",
    value: "America/Fortaleza LMT -03 -02",
    abbrs: ["LMT", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Glace_Bay",
    name: "America/Glace Bay (AST)",
    value: "America/Glace Bay LMT AST ADT AWT APT",
    abbrs: ["LMT", "AST", "ADT", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "America/Godthab",
    name: "America/Godthab (-02)",
    value: "America/Godthab LMT -03 -02 -01",
    abbrs: ["LMT", "-03", "-02", "-01"],
    currentAbbr: "-02",
  },
  {
    id: "America/Goose_Bay",
    name: "America/Goose Bay (AST)",
    value: "America/Goose Bay LMT NST NDT NWT NPT AST ADT ADDT",
    abbrs: ["LMT", "NST", "NDT", "NWT", "NPT", "AST", "ADT", "ADDT"],
    currentAbbr: "AST",
  },
  {
    id: "America/Grand_Turk",
    name: "America/Grand Turk (EST)",
    value: "America/Grand Turk LMT KMT EST EDT AST",
    abbrs: ["LMT", "KMT", "EST", "EDT", "AST"],
    currentAbbr: "EST",
  },
  {
    id: "America/Grenada",
    name: "America/Grenada (AST)",
    value: "America/Grenada LMT AST AWT APT",
    abbrs: ["LMT", "AST", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "America/Guadeloupe",
    name: "America/Guadeloupe (AST)",
    value: "America/Guadeloupe LMT AST AWT APT",
    abbrs: ["LMT", "AST", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "America/Guatemala",
    name: "America/Guatemala (CST)",
    value: "America/Guatemala LMT CST CDT",
    abbrs: ["LMT", "CST", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "America/Guayaquil",
    name: "America/Guayaquil (-05)",
    value: "America/Guayaquil LMT QMT -05 -04",
    abbrs: ["LMT", "QMT", "-05", "-04"],
    currentAbbr: "-05",
  },
  {
    id: "America/Guyana",
    name: "America/Guyana (-04)",
    value: "America/Guyana LMT -04 -0345 -03",
    abbrs: ["LMT", "-04", "-0345", "-03"],
    currentAbbr: "-04",
  },
  {
    id: "America/Halifax",
    name: "America/Halifax (AST)",
    value: "America/Halifax LMT AST ADT AWT APT",
    abbrs: ["LMT", "AST", "ADT", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "America/Havana",
    name: "America/Havana (CST)",
    value: "America/Havana LMT HMT CST CDT",
    abbrs: ["LMT", "HMT", "CST", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "America/Hermosillo",
    name: "America/Hermosillo (MST)",
    value: "America/Hermosillo LMT MST CST MDT",
    abbrs: ["LMT", "MST", "CST", "MDT"],
    currentAbbr: "MST",
  },
  {
    id: "America/Indiana/Indianapolis",
    name: "America/Indiana/Indianapolis (EST)",
    value: "America/Indiana/Indianapolis LMT CST CDT CWT CPT EST EDT",
    abbrs: ["LMT", "CST", "CDT", "CWT", "CPT", "EST", "EDT"],
    currentAbbr: "EST",
  },
  {
    id: "America/Indiana/Knox",
    name: "America/Indiana/Knox (CST)",
    value: "America/Indiana/Knox LMT CST CDT CWT CPT EST",
    abbrs: ["LMT", "CST", "CDT", "CWT", "CPT", "EST"],
    currentAbbr: "CST",
  },
  {
    id: "America/Indiana/Marengo",
    name: "America/Indiana/Marengo (EST)",
    value: "America/Indiana/Marengo LMT CST CDT CWT CPT EST EDT",
    abbrs: ["LMT", "CST", "CDT", "CWT", "CPT", "EST", "EDT"],
    currentAbbr: "EST",
  },
  {
    id: "America/Indiana/Petersburg",
    name: "America/Indiana/Petersburg (EST)",
    value: "America/Indiana/Petersburg LMT CST CDT CWT CPT EST EDT",
    abbrs: ["LMT", "CST", "CDT", "CWT", "CPT", "EST", "EDT"],
    currentAbbr: "EST",
  },
  {
    id: "America/Indiana/Tell_City",
    name: "America/Indiana/Tell City (CST)",
    value: "America/Indiana/Tell City LMT CST CDT CWT CPT EST EDT",
    abbrs: ["LMT", "CST", "CDT", "CWT", "CPT", "EST", "EDT"],
    currentAbbr: "CST",
  },
  {
    id: "America/Indiana/Vevay",
    name: "America/Indiana/Vevay (EST)",
    value: "America/Indiana/Vevay LMT CST CDT CWT CPT EST EDT",
    abbrs: ["LMT", "CST", "CDT", "CWT", "CPT", "EST", "EDT"],
    currentAbbr: "EST",
  },
  {
    id: "America/Indiana/Vincennes",
    name: "America/Indiana/Vincennes (EST)",
    value: "America/Indiana/Vincennes LMT CST CDT CWT CPT EST EDT",
    abbrs: ["LMT", "CST", "CDT", "CWT", "CPT", "EST", "EDT"],
    currentAbbr: "EST",
  },
  {
    id: "America/Indiana/Winamac",
    name: "America/Indiana/Winamac (EST)",
    value: "America/Indiana/Winamac LMT CST CDT CWT CPT EST EDT",
    abbrs: ["LMT", "CST", "CDT", "CWT", "CPT", "EST", "EDT"],
    currentAbbr: "EST",
  },
  {
    id: "America/Indianapolis",
    name: "America/Indianapolis (EST)",
    value: "America/Indianapolis LMT CST CDT CWT CPT EST EDT",
    abbrs: ["LMT", "CST", "CDT", "CWT", "CPT", "EST", "EDT"],
    currentAbbr: "EST",
  },
  {
    id: "America/Inuvik",
    name: "America/Inuvik (MST)",
    value: "America/Inuvik -00 PST PDT MDT MST",
    abbrs: ["-00", "PST", "PDT", "MDT", "MST"],
    currentAbbr: "MST",
  },
  {
    id: "America/Iqaluit",
    name: "America/Iqaluit (EST)",
    value: "America/Iqaluit -00 EWT EPT EST EDT CST CDT",
    abbrs: ["-00", "EWT", "EPT", "EST", "EDT", "CST", "CDT"],
    currentAbbr: "EST",
  },
  {
    id: "America/Jamaica",
    name: "America/Jamaica (EST)",
    value: "America/Jamaica LMT KMT EST EDT",
    abbrs: ["LMT", "KMT", "EST", "EDT"],
    currentAbbr: "EST",
  },
  {
    id: "America/Jujuy",
    name: "America/Jujuy (-03)",
    value: "America/Jujuy LMT CMT -04 -03 -02",
    abbrs: ["LMT", "CMT", "-04", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Juneau",
    name: "America/Juneau (AKST)",
    value: "America/Juneau LMT PST PWT PPT PDT YDT YST AKST AKDT",
    abbrs: ["LMT", "PST", "PWT", "PPT", "PDT", "YDT", "YST", "AKST", "AKDT"],
    currentAbbr: "AKST",
  },
  {
    id: "America/Kentucky/Louisville",
    name: "America/Kentucky/Louisville (EST)",
    value: "America/Kentucky/Louisville LMT CST CDT CWT CPT EST EDT",
    abbrs: ["LMT", "CST", "CDT", "CWT", "CPT", "EST", "EDT"],
    currentAbbr: "EST",
  },
  {
    id: "America/Kentucky/Monticello",
    name: "America/Kentucky/Monticello (EST)",
    value: "America/Kentucky/Monticello LMT CST CDT CWT CPT EST EDT",
    abbrs: ["LMT", "CST", "CDT", "CWT", "CPT", "EST", "EDT"],
    currentAbbr: "EST",
  },
  {
    id: "America/Knox_IN",
    name: "America/Knox IN (CST)",
    value: "America/Knox IN LMT CST CDT CWT CPT EST",
    abbrs: ["LMT", "CST", "CDT", "CWT", "CPT", "EST"],
    currentAbbr: "CST",
  },
  {
    id: "America/Kralendijk",
    name: "America/Kralendijk (AST)",
    value: "America/Kralendijk LMT AST AWT APT",
    abbrs: ["LMT", "AST", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "America/La_Paz",
    name: "America/La Paz (-04)",
    value: "America/La Paz LMT CMT BST -04",
    abbrs: ["LMT", "CMT", "BST", "-04"],
    currentAbbr: "-04",
  },
  {
    id: "America/Lima",
    name: "America/Lima (-05)",
    value: "America/Lima LMT -05 -04",
    abbrs: ["LMT", "-05", "-04"],
    currentAbbr: "-05",
  },
  {
    id: "America/Los_Angeles",
    name: "America/Los Angeles (PST)",
    value: "America/Los Angeles LMT PST PDT PWT PPT",
    abbrs: ["LMT", "PST", "PDT", "PWT", "PPT"],
    currentAbbr: "PST",
  },
  {
    id: "America/Louisville",
    name: "America/Louisville (EST)",
    value: "America/Louisville LMT CST CDT CWT CPT EST EDT",
    abbrs: ["LMT", "CST", "CDT", "CWT", "CPT", "EST", "EDT"],
    currentAbbr: "EST",
  },
  {
    id: "America/Lower_Princes",
    name: "America/Lower Princes (AST)",
    value: "America/Lower Princes LMT AST AWT APT",
    abbrs: ["LMT", "AST", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "America/Maceio",
    name: "America/Maceio (-03)",
    value: "America/Maceio LMT -03 -02",
    abbrs: ["LMT", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Managua",
    name: "America/Managua (CST)",
    value: "America/Managua LMT MMT CST EST CDT",
    abbrs: ["LMT", "MMT", "CST", "EST", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "America/Manaus",
    name: "America/Manaus (-04)",
    value: "America/Manaus LMT -04 -03",
    abbrs: ["LMT", "-04", "-03"],
    currentAbbr: "-04",
  },
  {
    id: "America/Marigot",
    name: "America/Marigot (AST)",
    value: "America/Marigot LMT AST AWT APT",
    abbrs: ["LMT", "AST", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "America/Martinique",
    name: "America/Martinique (AST)",
    value: "America/Martinique LMT FFMT AST ADT",
    abbrs: ["LMT", "FFMT", "AST", "ADT"],
    currentAbbr: "AST",
  },
  {
    id: "America/Matamoros",
    name: "America/Matamoros (CST)",
    value: "America/Matamoros LMT CST CDT",
    abbrs: ["LMT", "CST", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "America/Mazatlan",
    name: "America/Mazatlan (MST)",
    value: "America/Mazatlan LMT MST CST MDT",
    abbrs: ["LMT", "MST", "CST", "MDT"],
    currentAbbr: "MST",
  },
  {
    id: "America/Mendoza",
    name: "America/Mendoza (-03)",
    value: "America/Mendoza LMT CMT -04 -03 -02",
    abbrs: ["LMT", "CMT", "-04", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Menominee",
    name: "America/Menominee (CST)",
    value: "America/Menominee LMT CST CDT CWT CPT EST",
    abbrs: ["LMT", "CST", "CDT", "CWT", "CPT", "EST"],
    currentAbbr: "CST",
  },
  {
    id: "America/Merida",
    name: "America/Merida (CST)",
    value: "America/Merida LMT CST EST CDT",
    abbrs: ["LMT", "CST", "EST", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "America/Metlakatla",
    name: "America/Metlakatla (AKST)",
    value: "America/Metlakatla LMT PST PWT PPT PDT AKST AKDT",
    abbrs: ["LMT", "PST", "PWT", "PPT", "PDT", "AKST", "AKDT"],
    currentAbbr: "AKST",
  },
  {
    id: "America/Mexico_City",
    name: "America/Mexico City (CST)",
    value: "America/Mexico City LMT MST CST MDT CDT CWT",
    abbrs: ["LMT", "MST", "CST", "MDT", "CDT", "CWT"],
    currentAbbr: "CST",
  },
  {
    id: "America/Miquelon",
    name: "America/Miquelon (-03)",
    value: "America/Miquelon LMT AST -03 -02",
    abbrs: ["LMT", "AST", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Moncton",
    name: "America/Moncton (AST)",
    value: "America/Moncton LMT EST AST ADT AWT APT",
    abbrs: ["LMT", "EST", "AST", "ADT", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "America/Monterrey",
    name: "America/Monterrey (CST)",
    value: "America/Monterrey LMT MST CST MDT CDT",
    abbrs: ["LMT", "MST", "CST", "MDT", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "America/Montevideo",
    name: "America/Montevideo (-03)",
    value: "America/Montevideo LMT MMT -04 -03 -0330 -0230 -02 -0130",
    abbrs: ["LMT", "MMT", "-04", "-03", "-0330", "-0230", "-02", "-0130"],
    currentAbbr: "-03",
  },
  {
    id: "America/Montreal",
    name: "America/Montreal (EST)",
    value: "America/Montreal LMT EST EDT EWT EPT",
    abbrs: ["LMT", "EST", "EDT", "EWT", "EPT"],
    currentAbbr: "EST",
  },
  {
    id: "America/Montserrat",
    name: "America/Montserrat (AST)",
    value: "America/Montserrat LMT AST AWT APT",
    abbrs: ["LMT", "AST", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "America/Nassau",
    name: "America/Nassau (EST)",
    value: "America/Nassau LMT EST EDT EWT EPT",
    abbrs: ["LMT", "EST", "EDT", "EWT", "EPT"],
    currentAbbr: "EST",
  },
  {
    id: "America/New_York",
    name: "America/New York (EST)",
    value: "America/New York LMT EST EDT EWT EPT",
    abbrs: ["LMT", "EST", "EDT", "EWT", "EPT"],
    currentAbbr: "EST",
  },
  {
    id: "America/Nipigon",
    name: "America/Nipigon (EST)",
    value: "America/Nipigon LMT EST EDT EWT EPT",
    abbrs: ["LMT", "EST", "EDT", "EWT", "EPT"],
    currentAbbr: "EST",
  },
  {
    id: "America/Nome",
    name: "America/Nome (AKST)",
    value: "America/Nome LMT NST NWT NPT BST BDT YST AKST AKDT",
    abbrs: ["LMT", "NST", "NWT", "NPT", "BST", "BDT", "YST", "AKST", "AKDT"],
    currentAbbr: "AKST",
  },
  {
    id: "America/Noronha",
    name: "America/Noronha (-02)",
    value: "America/Noronha LMT -02 -01",
    abbrs: ["LMT", "-02", "-01"],
    currentAbbr: "-02",
  },
  {
    id: "America/North_Dakota/Beulah",
    name: "America/North Dakota/Beulah (CST)",
    value: "America/North Dakota/Beulah LMT MST MDT MWT MPT CST CDT",
    abbrs: ["LMT", "MST", "MDT", "MWT", "MPT", "CST", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "America/North_Dakota/Center",
    name: "America/North Dakota/Center (CST)",
    value: "America/North Dakota/Center LMT MST MDT MWT MPT CST CDT",
    abbrs: ["LMT", "MST", "MDT", "MWT", "MPT", "CST", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "America/North_Dakota/New_Salem",
    name: "America/North Dakota/New Salem (CST)",
    value: "America/North Dakota/New Salem LMT MST MDT MWT MPT CST CDT",
    abbrs: ["LMT", "MST", "MDT", "MWT", "MPT", "CST", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "America/Nuuk",
    name: "America/Nuuk (-02)",
    value: "America/Nuuk LMT -03 -02 -01",
    abbrs: ["LMT", "-03", "-02", "-01"],
    currentAbbr: "-02",
  },
  {
    id: "America/Ojinaga",
    name: "America/Ojinaga (CST)",
    value: "America/Ojinaga LMT MST CST MDT CDT",
    abbrs: ["LMT", "MST", "CST", "MDT", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "America/Panama",
    name: "America/Panama (EST)",
    value: "America/Panama LMT CMT EST",
    abbrs: ["LMT", "CMT", "EST"],
    currentAbbr: "EST",
  },
  {
    id: "America/Pangnirtung",
    name: "America/Pangnirtung (EST)",
    value: "America/Pangnirtung -00 EWT EPT EST EDT CST CDT",
    abbrs: ["-00", "EWT", "EPT", "EST", "EDT", "CST", "CDT"],
    currentAbbr: "EST",
  },
  {
    id: "America/Paramaribo",
    name: "America/Paramaribo (-03)",
    value: "America/Paramaribo LMT PMT -0330 -03",
    abbrs: ["LMT", "PMT", "-0330", "-03"],
    currentAbbr: "-03",
  },
  {
    id: "America/Phoenix",
    name: "America/Phoenix (MST)",
    value: "America/Phoenix LMT MST MDT MWT",
    abbrs: ["LMT", "MST", "MDT", "MWT"],
    currentAbbr: "MST",
  },
  {
    id: "America/Port-au-Prince",
    name: "America/Port-au-Prince (EST)",
    value: "America/Port-au-Prince LMT PPMT EST EDT",
    abbrs: ["LMT", "PPMT", "EST", "EDT"],
    currentAbbr: "EST",
  },
  {
    id: "America/Port_of_Spain",
    name: "America/Port of Spain (AST)",
    value: "America/Port of Spain LMT AST AWT APT",
    abbrs: ["LMT", "AST", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "America/Porto_Acre",
    name: "America/Porto Acre (-05)",
    value: "America/Porto Acre LMT -05 -04",
    abbrs: ["LMT", "-05", "-04"],
    currentAbbr: "-05",
  },
  {
    id: "America/Porto_Velho",
    name: "America/Porto Velho (-04)",
    value: "America/Porto Velho LMT -04 -03",
    abbrs: ["LMT", "-04", "-03"],
    currentAbbr: "-04",
  },
  {
    id: "America/Puerto_Rico",
    name: "America/Puerto Rico (AST)",
    value: "America/Puerto Rico LMT AST AWT APT",
    abbrs: ["LMT", "AST", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "America/Punta_Arenas",
    name: "America/Punta Arenas (-03)",
    value: "America/Punta Arenas LMT SMT -05 -04 -03",
    abbrs: ["LMT", "SMT", "-05", "-04", "-03"],
    currentAbbr: "-03",
  },
  {
    id: "America/Rainy_River",
    name: "America/Rainy River (CST)",
    value: "America/Rainy River LMT CST CDT CWT CPT",
    abbrs: ["LMT", "CST", "CDT", "CWT", "CPT"],
    currentAbbr: "CST",
  },
  {
    id: "America/Rankin_Inlet",
    name: "America/Rankin Inlet (CST)",
    value: "America/Rankin Inlet -00 CST CDT EST",
    abbrs: ["-00", "CST", "CDT", "EST"],
    currentAbbr: "CST",
  },
  {
    id: "America/Recife",
    name: "America/Recife (-03)",
    value: "America/Recife LMT -03 -02",
    abbrs: ["LMT", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Regina",
    name: "America/Regina (CST)",
    value: "America/Regina LMT MST MDT MWT MPT CST",
    abbrs: ["LMT", "MST", "MDT", "MWT", "MPT", "CST"],
    currentAbbr: "CST",
  },
  {
    id: "America/Resolute",
    name: "America/Resolute (CST)",
    value: "America/Resolute -00 CST CDT EST",
    abbrs: ["-00", "CST", "CDT", "EST"],
    currentAbbr: "CST",
  },
  {
    id: "America/Rio_Branco",
    name: "America/Rio Branco (-05)",
    value: "America/Rio Branco LMT -05 -04",
    abbrs: ["LMT", "-05", "-04"],
    currentAbbr: "-05",
  },
  {
    id: "America/Rosario",
    name: "America/Rosario (-03)",
    value: "America/Rosario LMT CMT -04 -03 -02",
    abbrs: ["LMT", "CMT", "-04", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Santa_Isabel",
    name: "America/Santa Isabel (PST)",
    value: "America/Santa Isabel LMT MST PST PDT PWT PPT",
    abbrs: ["LMT", "MST", "PST", "PDT", "PWT", "PPT"],
    currentAbbr: "PST",
  },
  {
    id: "America/Santarem",
    name: "America/Santarem (-03)",
    value: "America/Santarem LMT -04 -03",
    abbrs: ["LMT", "-04", "-03"],
    currentAbbr: "-03",
  },
  {
    id: "America/Santiago",
    name: "America/Santiago (-03)",
    value: "America/Santiago LMT SMT -05 -04 -03",
    abbrs: ["LMT", "SMT", "-05", "-04", "-03"],
    currentAbbr: "-03",
  },
  {
    id: "America/Santo_Domingo",
    name: "America/Santo Domingo (AST)",
    value: "America/Santo Domingo LMT SDMT EST EDT -0430 AST",
    abbrs: ["LMT", "SDMT", "EST", "EDT", "-0430", "AST"],
    currentAbbr: "AST",
  },
  {
    id: "America/Sao_Paulo",
    name: "America/Sao Paulo (-03)",
    value: "America/Sao Paulo LMT -03 -02",
    abbrs: ["LMT", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "America/Scoresbysund",
    name: "America/Scoresbysund (-02)",
    value: "America/Scoresbysund LMT -02 -01 +00",
    abbrs: ["LMT", "-02", "-01", "+00"],
    currentAbbr: "-02",
  },
  {
    id: "America/Shiprock",
    name: "America/Shiprock (MST)",
    value: "America/Shiprock LMT MST MDT MWT MPT",
    abbrs: ["LMT", "MST", "MDT", "MWT", "MPT"],
    currentAbbr: "MST",
  },
  {
    id: "America/Sitka",
    name: "America/Sitka (AKST)",
    value: "America/Sitka LMT PST PWT PPT PDT YST AKST AKDT",
    abbrs: ["LMT", "PST", "PWT", "PPT", "PDT", "YST", "AKST", "AKDT"],
    currentAbbr: "AKST",
  },
  {
    id: "America/St_Barthelemy",
    name: "America/St Barthelemy (AST)",
    value: "America/St Barthelemy LMT AST AWT APT",
    abbrs: ["LMT", "AST", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "America/St_Johns",
    name: "America/St Johns (NST)",
    value: "America/St Johns LMT NST NDT NWT NPT NDDT",
    abbrs: ["LMT", "NST", "NDT", "NWT", "NPT", "NDDT"],
    currentAbbr: "NST",
  },
  {
    id: "America/St_Kitts",
    name: "America/St Kitts (AST)",
    value: "America/St Kitts LMT AST AWT APT",
    abbrs: ["LMT", "AST", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "America/St_Lucia",
    name: "America/St Lucia (AST)",
    value: "America/St Lucia LMT AST AWT APT",
    abbrs: ["LMT", "AST", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "America/St_Thomas",
    name: "America/St Thomas (AST)",
    value: "America/St Thomas LMT AST AWT APT",
    abbrs: ["LMT", "AST", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "America/St_Vincent",
    name: "America/St Vincent (AST)",
    value: "America/St Vincent LMT AST AWT APT",
    abbrs: ["LMT", "AST", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "America/Swift_Current",
    name: "America/Swift Current (CST)",
    value: "America/Swift Current LMT MST MDT MWT MPT CST",
    abbrs: ["LMT", "MST", "MDT", "MWT", "MPT", "CST"],
    currentAbbr: "CST",
  },
  {
    id: "America/Tegucigalpa",
    name: "America/Tegucigalpa (CST)",
    value: "America/Tegucigalpa LMT CST CDT",
    abbrs: ["LMT", "CST", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "America/Thule",
    name: "America/Thule (AST)",
    value: "America/Thule LMT AST ADT",
    abbrs: ["LMT", "AST", "ADT"],
    currentAbbr: "AST",
  },
  {
    id: "America/Thunder_Bay",
    name: "America/Thunder Bay (EST)",
    value: "America/Thunder Bay LMT EST EDT EWT EPT",
    abbrs: ["LMT", "EST", "EDT", "EWT", "EPT"],
    currentAbbr: "EST",
  },
  {
    id: "America/Tijuana",
    name: "America/Tijuana (PST)",
    value: "America/Tijuana LMT MST PST PDT PWT PPT",
    abbrs: ["LMT", "MST", "PST", "PDT", "PWT", "PPT"],
    currentAbbr: "PST",
  },
  {
    id: "America/Toronto",
    name: "America/Toronto (EST)",
    value: "America/Toronto LMT EST EDT EWT EPT",
    abbrs: ["LMT", "EST", "EDT", "EWT", "EPT"],
    currentAbbr: "EST",
  },
  {
    id: "America/Tortola",
    name: "America/Tortola (AST)",
    value: "America/Tortola LMT AST AWT APT",
    abbrs: ["LMT", "AST", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "America/Vancouver",
    name: "America/Vancouver (PST)",
    value: "America/Vancouver LMT PST PDT PWT PPT",
    abbrs: ["LMT", "PST", "PDT", "PWT", "PPT"],
    currentAbbr: "PST",
  },
  {
    id: "America/Virgin",
    name: "America/Virgin (AST)",
    value: "America/Virgin LMT AST AWT APT",
    abbrs: ["LMT", "AST", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "America/Whitehorse",
    name: "America/Whitehorse (MST)",
    value: "America/Whitehorse LMT YST YDT YWT YPT YDDT PST PDT MST",
    abbrs: ["LMT", "YST", "YDT", "YWT", "YPT", "YDDT", "PST", "PDT", "MST"],
    currentAbbr: "MST",
  },
  {
    id: "America/Winnipeg",
    name: "America/Winnipeg (CST)",
    value: "America/Winnipeg LMT CST CDT CWT CPT",
    abbrs: ["LMT", "CST", "CDT", "CWT", "CPT"],
    currentAbbr: "CST",
  },
  {
    id: "America/Yakutat",
    name: "America/Yakutat (AKST)",
    value: "America/Yakutat LMT YST YWT YPT YDT AKST AKDT",
    abbrs: ["LMT", "YST", "YWT", "YPT", "YDT", "AKST", "AKDT"],
    currentAbbr: "AKST",
  },
  {
    id: "America/Yellowknife",
    name: "America/Yellowknife (MST)",
    value: "America/Yellowknife LMT MST MDT MWT MPT",
    abbrs: ["LMT", "MST", "MDT", "MWT", "MPT"],
    currentAbbr: "MST",
  },
  {
    id: "Antarctica/Casey",
    name: "Antarctica/Casey (+08)",
    value: "Antarctica/Casey -00 +08 +11",
    abbrs: ["-00", "+08", "+11"],
    currentAbbr: "+08",
  },
  {
    id: "Antarctica/Davis",
    name: "Antarctica/Davis (+07)",
    value: "Antarctica/Davis -00 +07 +05",
    abbrs: ["-00", "+07", "+05"],
    currentAbbr: "+07",
  },
  {
    id: "Antarctica/DumontDUrville",
    name: "Antarctica/DumontDUrville (+10)",
    value: "Antarctica/DumontDUrville LMT PMMT +10",
    abbrs: ["LMT", "PMMT", "+10"],
    currentAbbr: "+10",
  },
  {
    id: "Antarctica/Macquarie",
    name: "Antarctica/Macquarie (AEDT)",
    value: "Antarctica/Macquarie -00 AEST AEDT",
    abbrs: ["-00", "AEST", "AEDT"],
    currentAbbr: "AEDT",
  },
  {
    id: "Antarctica/Mawson",
    name: "Antarctica/Mawson (+05)",
    value: "Antarctica/Mawson -00 +06 +05",
    abbrs: ["-00", "+06", "+05"],
    currentAbbr: "+05",
  },
  {
    id: "Antarctica/McMurdo",
    name: "Antarctica/McMurdo (NZDT)",
    value: "Antarctica/McMurdo LMT NZMT NZST NZDT",
    abbrs: ["LMT", "NZMT", "NZST", "NZDT"],
    currentAbbr: "NZDT",
  },
  {
    id: "Antarctica/Palmer",
    name: "Antarctica/Palmer (-03)",
    value: "Antarctica/Palmer -00 -03 -04 -02",
    abbrs: ["-00", "-03", "-04", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "Antarctica/Rothera",
    name: "Antarctica/Rothera (-03)",
    value: "Antarctica/Rothera -00 -03",
    abbrs: ["-00", "-03"],
    currentAbbr: "-03",
  },
  {
    id: "Antarctica/South_Pole",
    name: "Antarctica/South Pole (NZDT)",
    value: "Antarctica/South Pole LMT NZMT NZST NZDT",
    abbrs: ["LMT", "NZMT", "NZST", "NZDT"],
    currentAbbr: "NZDT",
  },
  {
    id: "Antarctica/Syowa",
    name: "Antarctica/Syowa (+03)",
    value: "Antarctica/Syowa LMT +03",
    abbrs: ["LMT", "+03"],
    currentAbbr: "+03",
  },
  {
    id: "Antarctica/Troll",
    name: "Antarctica/Troll (+00)",
    value: "Antarctica/Troll -00 +00 +02",
    abbrs: ["-00", "+00", "+02"],
    currentAbbr: "+00",
  },
  {
    id: "Antarctica/Vostok",
    name: "Antarctica/Vostok (+05)",
    value: "Antarctica/Vostok -00 +07 +05",
    abbrs: ["-00", "+07", "+05"],
    currentAbbr: "+05",
  },
  {
    id: "Arctic/Longyearbyen",
    name: "Arctic/Longyearbyen (CET)",
    value: "Arctic/Longyearbyen LMT CET CEST CEMT",
    abbrs: ["LMT", "CET", "CEST", "CEMT"],
    currentAbbr: "CET",
  },
  {
    id: "Asia/Aden",
    name: "Asia/Aden (+03)",
    value: "Asia/Aden LMT +03",
    abbrs: ["LMT", "+03"],
    currentAbbr: "+03",
  },
  {
    id: "Asia/Almaty",
    name: "Asia/Almaty (+05)",
    value: "Asia/Almaty LMT +05 +06 +07",
    abbrs: ["LMT", "+05", "+06", "+07"],
    currentAbbr: "+05",
  },
  {
    id: "Asia/Amman",
    name: "Asia/Amman (+03)",
    value: "Asia/Amman LMT EET EEST +03",
    abbrs: ["LMT", "EET", "EEST", "+03"],
    currentAbbr: "+03",
  },
  {
    id: "Asia/Anadyr",
    name: "Asia/Anadyr (+12)",
    value: "Asia/Anadyr LMT +12 +13 +14 +11",
    abbrs: ["LMT", "+12", "+13", "+14", "+11"],
    currentAbbr: "+12",
  },
  {
    id: "Asia/Aqtau",
    name: "Asia/Aqtau (+05)",
    value: "Asia/Aqtau LMT +04 +05 +06",
    abbrs: ["LMT", "+04", "+05", "+06"],
    currentAbbr: "+05",
  },
  {
    id: "Asia/Aqtobe",
    name: "Asia/Aqtobe (+05)",
    value: "Asia/Aqtobe LMT +04 +05 +06",
    abbrs: ["LMT", "+04", "+05", "+06"],
    currentAbbr: "+05",
  },
  {
    id: "Asia/Ashgabat",
    name: "Asia/Ashgabat (+05)",
    value: "Asia/Ashgabat LMT +04 +05 +06",
    abbrs: ["LMT", "+04", "+05", "+06"],
    currentAbbr: "+05",
  },
  {
    id: "Asia/Ashkhabad",
    name: "Asia/Ashkhabad (+05)",
    value: "Asia/Ashkhabad LMT +04 +05 +06",
    abbrs: ["LMT", "+04", "+05", "+06"],
    currentAbbr: "+05",
  },
  {
    id: "Asia/Atyrau",
    name: "Asia/Atyrau (+05)",
    value: "Asia/Atyrau LMT +03 +05 +06 +04",
    abbrs: ["LMT", "+03", "+05", "+06", "+04"],
    currentAbbr: "+05",
  },
  {
    id: "Asia/Baghdad",
    name: "Asia/Baghdad (+03)",
    value: "Asia/Baghdad LMT BMT +03 +04",
    abbrs: ["LMT", "BMT", "+03", "+04"],
    currentAbbr: "+03",
  },
  {
    id: "Asia/Bahrain",
    name: "Asia/Bahrain (+03)",
    value: "Asia/Bahrain LMT +04 +03",
    abbrs: ["LMT", "+04", "+03"],
    currentAbbr: "+03",
  },
  {
    id: "Asia/Baku",
    name: "Asia/Baku (+04)",
    value: "Asia/Baku LMT +03 +04 +05",
    abbrs: ["LMT", "+03", "+04", "+05"],
    currentAbbr: "+04",
  },
  {
    id: "Asia/Bangkok",
    name: "Asia/Bangkok (+07)",
    value: "Asia/Bangkok LMT BMT +07",
    abbrs: ["LMT", "BMT", "+07"],
    currentAbbr: "+07",
  },
  {
    id: "Asia/Barnaul",
    name: "Asia/Barnaul (+07)",
    value: "Asia/Barnaul LMT +06 +07 +08",
    abbrs: ["LMT", "+06", "+07", "+08"],
    currentAbbr: "+07",
  },
  {
    id: "Asia/Beirut",
    name: "Asia/Beirut (EET)",
    value: "Asia/Beirut LMT EET EEST",
    abbrs: ["LMT", "EET", "EEST"],
    currentAbbr: "EET",
  },
  {
    id: "Asia/Bishkek",
    name: "Asia/Bishkek (+06)",
    value: "Asia/Bishkek LMT +05 +06 +07",
    abbrs: ["LMT", "+05", "+06", "+07"],
    currentAbbr: "+06",
  },
  {
    id: "Asia/Brunei",
    name: "Asia/Brunei (+08)",
    value: "Asia/Brunei LMT +0730 +08 +0820 +09",
    abbrs: ["LMT", "+0730", "+08", "+0820", "+09"],
    currentAbbr: "+08",
  },
  {
    id: "Asia/Calcutta",
    name: "Asia/Calcutta (IST)",
    value: "Asia/Calcutta LMT HMT MMT IST +0630",
    abbrs: ["LMT", "HMT", "MMT", "IST", "+0630"],
    currentAbbr: "IST",
  },
  {
    id: "Asia/Chita",
    name: "Asia/Chita (+09)",
    value: "Asia/Chita LMT +08 +09 +10",
    abbrs: ["LMT", "+08", "+09", "+10"],
    currentAbbr: "+09",
  },
  {
    id: "Asia/Choibalsan",
    name: "Asia/Choibalsan (+08)",
    value: "Asia/Choibalsan LMT +07 +08 +09",
    abbrs: ["LMT", "+07", "+08", "+09"],
    currentAbbr: "+08",
  },
  {
    id: "Asia/Chongqing",
    name: "Asia/Chongqing (CST)",
    value: "Asia/Chongqing LMT CST CDT",
    abbrs: ["LMT", "CST", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "Asia/Chungking",
    name: "Asia/Chungking (CST)",
    value: "Asia/Chungking LMT CST CDT",
    abbrs: ["LMT", "CST", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "Asia/Colombo",
    name: "Asia/Colombo (+0530)",
    value: "Asia/Colombo LMT MMT +0530 +06 +0630",
    abbrs: ["LMT", "MMT", "+0530", "+06", "+0630"],
    currentAbbr: "+0530",
  },
  {
    id: "Asia/Dacca",
    name: "Asia/Dacca (+06)",
    value: "Asia/Dacca LMT HMT +0630 +0530 +06 +07",
    abbrs: ["LMT", "HMT", "+0630", "+0530", "+06", "+07"],
    currentAbbr: "+06",
  },
  {
    id: "Asia/Damascus",
    name: "Asia/Damascus (+03)",
    value: "Asia/Damascus LMT EET EEST +03",
    abbrs: ["LMT", "EET", "EEST", "+03"],
    currentAbbr: "+03",
  },
  {
    id: "Asia/Dhaka",
    name: "Asia/Dhaka (+06)",
    value: "Asia/Dhaka LMT HMT +0630 +0530 +06 +07",
    abbrs: ["LMT", "HMT", "+0630", "+0530", "+06", "+07"],
    currentAbbr: "+06",
  },
  {
    id: "Asia/Dili",
    name: "Asia/Dili (+09)",
    value: "Asia/Dili LMT +08 +09",
    abbrs: ["LMT", "+08", "+09"],
    currentAbbr: "+09",
  },
  {
    id: "Asia/Dubai",
    name: "Asia/Dubai (+04)",
    value: "Asia/Dubai LMT +04",
    abbrs: ["LMT", "+04"],
    currentAbbr: "+04",
  },
  {
    id: "Asia/Dushanbe",
    name: "Asia/Dushanbe (+05)",
    value: "Asia/Dushanbe LMT +05 +06 +07",
    abbrs: ["LMT", "+05", "+06", "+07"],
    currentAbbr: "+05",
  },
  {
    id: "Asia/Famagusta",
    name: "Asia/Famagusta (EET)",
    value: "Asia/Famagusta LMT EET EEST +03",
    abbrs: ["LMT", "EET", "EEST", "+03"],
    currentAbbr: "EET",
  },
  {
    id: "Asia/Gaza",
    name: "Asia/Gaza (EET)",
    value: "Asia/Gaza LMT EET EEST IST IDT",
    abbrs: ["LMT", "EET", "EEST", "IST", "IDT"],
    currentAbbr: "EET",
  },
  {
    id: "Asia/Harbin",
    name: "Asia/Harbin (CST)",
    value: "Asia/Harbin LMT CST CDT",
    abbrs: ["LMT", "CST", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "Asia/Hebron",
    name: "Asia/Hebron (EET)",
    value: "Asia/Hebron LMT EET EEST IST IDT",
    abbrs: ["LMT", "EET", "EEST", "IST", "IDT"],
    currentAbbr: "EET",
  },
  {
    id: "Asia/Ho_Chi_Minh",
    name: "Asia/Ho Chi Minh (+07)",
    value: "Asia/Ho Chi Minh LMT PLMT +07 +08 +09",
    abbrs: ["LMT", "PLMT", "+07", "+08", "+09"],
    currentAbbr: "+07",
  },
  {
    id: "Asia/Hong_Kong",
    name: "Asia/Hong Kong (HKT)",
    value: "Asia/Hong Kong LMT HKT HKST HKWT JST",
    abbrs: ["LMT", "HKT", "HKST", "HKWT", "JST"],
    currentAbbr: "HKT",
  },
  {
    id: "Asia/Hovd",
    name: "Asia/Hovd (+07)",
    value: "Asia/Hovd LMT +06 +07 +08",
    abbrs: ["LMT", "+06", "+07", "+08"],
    currentAbbr: "+07",
  },
  {
    id: "Asia/Irkutsk",
    name: "Asia/Irkutsk (+08)",
    value: "Asia/Irkutsk LMT IMT +07 +08 +09",
    abbrs: ["LMT", "IMT", "+07", "+08", "+09"],
    currentAbbr: "+08",
  },
  {
    id: "Asia/Istanbul",
    name: "Asia/Istanbul (+03)",
    value: "Asia/Istanbul LMT IMT EET EEST +03 +04",
    abbrs: ["LMT", "IMT", "EET", "EEST", "+03", "+04"],
    currentAbbr: "+03",
  },
  {
    id: "Asia/Jakarta",
    name: "Asia/Jakarta (WIB)",
    value: "Asia/Jakarta LMT BMT +0720 +0730 +09 +08 WIB",
    abbrs: ["LMT", "BMT", "+0720", "+0730", "+09", "+08", "WIB"],
    currentAbbr: "WIB",
  },
  {
    id: "Asia/Jayapura",
    name: "Asia/Jayapura (WIT)",
    value: "Asia/Jayapura LMT +09 +0930 WIT",
    abbrs: ["LMT", "+09", "+0930", "WIT"],
    currentAbbr: "WIT",
  },
  {
    id: "Asia/Jerusalem",
    name: "Asia/Jerusalem (IST)",
    value: "Asia/Jerusalem LMT JMT IST IDT IDDT",
    abbrs: ["LMT", "JMT", "IST", "IDT", "IDDT"],
    currentAbbr: "IST",
  },
  {
    id: "Asia/Kabul",
    name: "Asia/Kabul (+0430)",
    value: "Asia/Kabul LMT +04 +0430",
    abbrs: ["LMT", "+04", "+0430"],
    currentAbbr: "+0430",
  },
  {
    id: "Asia/Kamchatka",
    name: "Asia/Kamchatka (+12)",
    value: "Asia/Kamchatka LMT +11 +12 +13",
    abbrs: ["LMT", "+11", "+12", "+13"],
    currentAbbr: "+12",
  },
  {
    id: "Asia/Karachi",
    name: "Asia/Karachi (PKT)",
    value: "Asia/Karachi LMT +0530 +0630 +05 PKT PKST",
    abbrs: ["LMT", "+0530", "+0630", "+05", "PKT", "PKST"],
    currentAbbr: "PKT",
  },
  {
    id: "Asia/Kashgar",
    name: "Asia/Kashgar (+06)",
    value: "Asia/Kashgar LMT +06",
    abbrs: ["LMT", "+06"],
    currentAbbr: "+06",
  },
  {
    id: "Asia/Kathmandu",
    name: "Asia/Kathmandu (+0545)",
    value: "Asia/Kathmandu LMT +0530 +0545",
    abbrs: ["LMT", "+0530", "+0545"],
    currentAbbr: "+0545",
  },
  {
    id: "Asia/Katmandu",
    name: "Asia/Katmandu (+0545)",
    value: "Asia/Katmandu LMT +0530 +0545",
    abbrs: ["LMT", "+0530", "+0545"],
    currentAbbr: "+0545",
  },
  {
    id: "Asia/Khandyga",
    name: "Asia/Khandyga (+09)",
    value: "Asia/Khandyga LMT +08 +09 +10 +11",
    abbrs: ["LMT", "+08", "+09", "+10", "+11"],
    currentAbbr: "+09",
  },
  {
    id: "Asia/Kolkata",
    name: "Asia/Kolkata (IST)",
    value: "Asia/Kolkata LMT HMT MMT IST +0630",
    abbrs: ["LMT", "HMT", "MMT", "IST", "+0630"],
    currentAbbr: "IST",
  },
  {
    id: "Asia/Krasnoyarsk",
    name: "Asia/Krasnoyarsk (+07)",
    value: "Asia/Krasnoyarsk LMT +06 +07 +08",
    abbrs: ["LMT", "+06", "+07", "+08"],
    currentAbbr: "+07",
  },
  {
    id: "Asia/Kuala_Lumpur",
    name: "Asia/Kuala Lumpur (+08)",
    value: "Asia/Kuala Lumpur LMT SMT +07 +0720 +0730 +09 +08",
    abbrs: ["LMT", "SMT", "+07", "+0720", "+0730", "+09", "+08"],
    currentAbbr: "+08",
  },
  {
    id: "Asia/Kuching",
    name: "Asia/Kuching (+08)",
    value: "Asia/Kuching LMT +0730 +08 +0820 +09",
    abbrs: ["LMT", "+0730", "+08", "+0820", "+09"],
    currentAbbr: "+08",
  },
  {
    id: "Asia/Kuwait",
    name: "Asia/Kuwait (+03)",
    value: "Asia/Kuwait LMT +03",
    abbrs: ["LMT", "+03"],
    currentAbbr: "+03",
  },
  {
    id: "Asia/Macao",
    name: "Asia/Macao (CST)",
    value: "Asia/Macao LMT CST +09 +10 CDT",
    abbrs: ["LMT", "CST", "+09", "+10", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "Asia/Macau",
    name: "Asia/Macau (CST)",
    value: "Asia/Macau LMT CST +09 +10 CDT",
    abbrs: ["LMT", "CST", "+09", "+10", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "Asia/Magadan",
    name: "Asia/Magadan (+11)",
    value: "Asia/Magadan LMT +10 +11 +12",
    abbrs: ["LMT", "+10", "+11", "+12"],
    currentAbbr: "+11",
  },
  {
    id: "Asia/Makassar",
    name: "Asia/Makassar (WITA)",
    value: "Asia/Makassar LMT MMT +08 +09 WITA",
    abbrs: ["LMT", "MMT", "+08", "+09", "WITA"],
    currentAbbr: "WITA",
  },
  {
    id: "Asia/Manila",
    name: "Asia/Manila (PST)",
    value: "Asia/Manila LMT PST PDT JST",
    abbrs: ["LMT", "PST", "PDT", "JST"],
    currentAbbr: "PST",
  },
  {
    id: "Asia/Muscat",
    name: "Asia/Muscat (+04)",
    value: "Asia/Muscat LMT +04",
    abbrs: ["LMT", "+04"],
    currentAbbr: "+04",
  },
  {
    id: "Asia/Nicosia",
    name: "Asia/Nicosia (EET)",
    value: "Asia/Nicosia LMT EET EEST",
    abbrs: ["LMT", "EET", "EEST"],
    currentAbbr: "EET",
  },
  {
    id: "Asia/Novokuznetsk",
    name: "Asia/Novokuznetsk (+07)",
    value: "Asia/Novokuznetsk LMT +06 +07 +08",
    abbrs: ["LMT", "+06", "+07", "+08"],
    currentAbbr: "+07",
  },
  {
    id: "Asia/Novosibirsk",
    name: "Asia/Novosibirsk (+07)",
    value: "Asia/Novosibirsk LMT +06 +07 +08",
    abbrs: ["LMT", "+06", "+07", "+08"],
    currentAbbr: "+07",
  },
  {
    id: "Asia/Omsk",
    name: "Asia/Omsk (+06)",
    value: "Asia/Omsk LMT +05 +06 +07",
    abbrs: ["LMT", "+05", "+06", "+07"],
    currentAbbr: "+06",
  },
  {
    id: "Asia/Oral",
    name: "Asia/Oral (+05)",
    value: "Asia/Oral LMT +03 +05 +06 +04",
    abbrs: ["LMT", "+03", "+05", "+06", "+04"],
    currentAbbr: "+05",
  },
  {
    id: "Asia/Phnom_Penh",
    name: "Asia/Phnom Penh (+07)",
    value: "Asia/Phnom Penh LMT BMT +07",
    abbrs: ["LMT", "BMT", "+07"],
    currentAbbr: "+07",
  },
  {
    id: "Asia/Pontianak",
    name: "Asia/Pontianak (WIB)",
    value: "Asia/Pontianak LMT PMT +0730 +09 +08 WITA WIB",
    abbrs: ["LMT", "PMT", "+0730", "+09", "+08", "WITA", "WIB"],
    currentAbbr: "WIB",
  },
  {
    id: "Asia/Pyongyang",
    name: "Asia/Pyongyang (KST)",
    value: "Asia/Pyongyang LMT KST JST",
    abbrs: ["LMT", "KST", "JST"],
    currentAbbr: "KST",
  },
  {
    id: "Asia/Qatar",
    name: "Asia/Qatar (+03)",
    value: "Asia/Qatar LMT +04 +03",
    abbrs: ["LMT", "+04", "+03"],
    currentAbbr: "+03",
  },
  {
    id: "Asia/Qostanay",
    name: "Asia/Qostanay (+05)",
    value: "Asia/Qostanay LMT +04 +05 +06",
    abbrs: ["LMT", "+04", "+05", "+06"],
    currentAbbr: "+05",
  },
  {
    id: "Asia/Qyzylorda",
    name: "Asia/Qyzylorda (+05)",
    value: "Asia/Qyzylorda LMT +04 +05 +06",
    abbrs: ["LMT", "+04", "+05", "+06"],
    currentAbbr: "+05",
  },
  {
    id: "Asia/Rangoon",
    name: "Asia/Rangoon (+0630)",
    value: "Asia/Rangoon LMT RMT +0630 +09",
    abbrs: ["LMT", "RMT", "+0630", "+09"],
    currentAbbr: "+0630",
  },
  {
    id: "Asia/Riyadh",
    name: "Asia/Riyadh (+03)",
    value: "Asia/Riyadh LMT +03",
    abbrs: ["LMT", "+03"],
    currentAbbr: "+03",
  },
  {
    id: "Asia/Saigon",
    name: "Asia/Saigon (+07)",
    value: "Asia/Saigon LMT PLMT +07 +08 +09",
    abbrs: ["LMT", "PLMT", "+07", "+08", "+09"],
    currentAbbr: "+07",
  },
  {
    id: "Asia/Sakhalin",
    name: "Asia/Sakhalin (+11)",
    value: "Asia/Sakhalin LMT +09 +11 +12 +10",
    abbrs: ["LMT", "+09", "+11", "+12", "+10"],
    currentAbbr: "+11",
  },
  {
    id: "Asia/Samarkand",
    name: "Asia/Samarkand (+05)",
    value: "Asia/Samarkand LMT +04 +05 +06",
    abbrs: ["LMT", "+04", "+05", "+06"],
    currentAbbr: "+05",
  },
  {
    id: "Asia/Seoul",
    name: "Asia/Seoul (KST)",
    value: "Asia/Seoul LMT KST JST KDT",
    abbrs: ["LMT", "KST", "JST", "KDT"],
    currentAbbr: "KST",
  },
  {
    id: "Asia/Shanghai",
    name: "Asia/Shanghai (CST)",
    value: "Asia/Shanghai LMT CST CDT",
    abbrs: ["LMT", "CST", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "Asia/Singapore",
    name: "Asia/Singapore (+08)",
    value: "Asia/Singapore LMT SMT +07 +0720 +0730 +09 +08",
    abbrs: ["LMT", "SMT", "+07", "+0720", "+0730", "+09", "+08"],
    currentAbbr: "+08",
  },
  {
    id: "Asia/Srednekolymsk",
    name: "Asia/Srednekolymsk (+11)",
    value: "Asia/Srednekolymsk LMT +10 +11 +12",
    abbrs: ["LMT", "+10", "+11", "+12"],
    currentAbbr: "+11",
  },
  {
    id: "Asia/Taipei",
    name: "Asia/Taipei (CST)",
    value: "Asia/Taipei LMT CST JST CDT",
    abbrs: ["LMT", "CST", "JST", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "Asia/Tashkent",
    name: "Asia/Tashkent (+05)",
    value: "Asia/Tashkent LMT +05 +06 +07",
    abbrs: ["LMT", "+05", "+06", "+07"],
    currentAbbr: "+05",
  },
  {
    id: "Asia/Tbilisi",
    name: "Asia/Tbilisi (+04)",
    value: "Asia/Tbilisi LMT TBMT +03 +04 +05",
    abbrs: ["LMT", "TBMT", "+03", "+04", "+05"],
    currentAbbr: "+04",
  },
  {
    id: "Asia/Tehran",
    name: "Asia/Tehran (+0330)",
    value: "Asia/Tehran LMT TMT +0330 +0430 +04 +05",
    abbrs: ["LMT", "TMT", "+0330", "+0430", "+04", "+05"],
    currentAbbr: "+0330",
  },
  {
    id: "Asia/Tel_Aviv",
    name: "Asia/Tel Aviv (IST)",
    value: "Asia/Tel Aviv LMT JMT IST IDT IDDT",
    abbrs: ["LMT", "JMT", "IST", "IDT", "IDDT"],
    currentAbbr: "IST",
  },
  {
    id: "Asia/Thimbu",
    name: "Asia/Thimbu (+06)",
    value: "Asia/Thimbu LMT +0530 +06",
    abbrs: ["LMT", "+0530", "+06"],
    currentAbbr: "+06",
  },
  {
    id: "Asia/Thimphu",
    name: "Asia/Thimphu (+06)",
    value: "Asia/Thimphu LMT +0530 +06",
    abbrs: ["LMT", "+0530", "+06"],
    currentAbbr: "+06",
  },
  {
    id: "Asia/Tokyo",
    name: "Asia/Tokyo (JST)",
    value: "Asia/Tokyo LMT JST JDT",
    abbrs: ["LMT", "JST", "JDT"],
    currentAbbr: "JST",
  },
  {
    id: "Asia/Tomsk",
    name: "Asia/Tomsk (+07)",
    value: "Asia/Tomsk LMT +06 +07 +08",
    abbrs: ["LMT", "+06", "+07", "+08"],
    currentAbbr: "+07",
  },
  {
    id: "Asia/Ujung_Pandang",
    name: "Asia/Ujung Pandang (WITA)",
    value: "Asia/Ujung Pandang LMT MMT +08 +09 WITA",
    abbrs: ["LMT", "MMT", "+08", "+09", "WITA"],
    currentAbbr: "WITA",
  },
  {
    id: "Asia/Ulaanbaatar",
    name: "Asia/Ulaanbaatar (+08)",
    value: "Asia/Ulaanbaatar LMT +07 +08 +09",
    abbrs: ["LMT", "+07", "+08", "+09"],
    currentAbbr: "+08",
  },
  {
    id: "Asia/Ulan_Bator",
    name: "Asia/Ulan Bator (+08)",
    value: "Asia/Ulan Bator LMT +07 +08 +09",
    abbrs: ["LMT", "+07", "+08", "+09"],
    currentAbbr: "+08",
  },
  {
    id: "Asia/Urumqi",
    name: "Asia/Urumqi (+06)",
    value: "Asia/Urumqi LMT +06",
    abbrs: ["LMT", "+06"],
    currentAbbr: "+06",
  },
  {
    id: "Asia/Ust-Nera",
    name: "Asia/Ust-Nera (+10)",
    value: "Asia/Ust-Nera LMT +08 +09 +12 +11 +10",
    abbrs: ["LMT", "+08", "+09", "+12", "+11", "+10"],
    currentAbbr: "+10",
  },
  {
    id: "Asia/Vientiane",
    name: "Asia/Vientiane (+07)",
    value: "Asia/Vientiane LMT BMT +07",
    abbrs: ["LMT", "BMT", "+07"],
    currentAbbr: "+07",
  },
  {
    id: "Asia/Vladivostok",
    name: "Asia/Vladivostok (+10)",
    value: "Asia/Vladivostok LMT +09 +10 +11",
    abbrs: ["LMT", "+09", "+10", "+11"],
    currentAbbr: "+10",
  },
  {
    id: "Asia/Yakutsk",
    name: "Asia/Yakutsk (+09)",
    value: "Asia/Yakutsk LMT +08 +09 +10",
    abbrs: ["LMT", "+08", "+09", "+10"],
    currentAbbr: "+09",
  },
  {
    id: "Asia/Yangon",
    name: "Asia/Yangon (+0630)",
    value: "Asia/Yangon LMT RMT +0630 +09",
    abbrs: ["LMT", "RMT", "+0630", "+09"],
    currentAbbr: "+0630",
  },
  {
    id: "Asia/Yekaterinburg",
    name: "Asia/Yekaterinburg (+05)",
    value: "Asia/Yekaterinburg LMT PMT +04 +05 +06",
    abbrs: ["LMT", "PMT", "+04", "+05", "+06"],
    currentAbbr: "+05",
  },
  {
    id: "Asia/Yerevan",
    name: "Asia/Yerevan (+04)",
    value: "Asia/Yerevan LMT +03 +04 +05",
    abbrs: ["LMT", "+03", "+04", "+05"],
    currentAbbr: "+04",
  },
  {
    id: "Atlantic/Azores",
    name: "Atlantic/Azores (-01)",
    value: "Atlantic/Azores LMT HMT -02 -01 +00 WET WEST",
    abbrs: ["LMT", "HMT", "-02", "-01", "+00", "WET", "WEST"],
    currentAbbr: "-01",
  },
  {
    id: "Atlantic/Bermuda",
    name: "Atlantic/Bermuda (AST)",
    value: "Atlantic/Bermuda LMT BMT BST AST ADT",
    abbrs: ["LMT", "BMT", "BST", "AST", "ADT"],
    currentAbbr: "AST",
  },
  {
    id: "Atlantic/Canary",
    name: "Atlantic/Canary (WET)",
    value: "Atlantic/Canary LMT -01 WET WEST",
    abbrs: ["LMT", "-01", "WET", "WEST"],
    currentAbbr: "WET",
  },
  {
    id: "Atlantic/Cape_Verde",
    name: "Atlantic/Cape Verde (-01)",
    value: "Atlantic/Cape Verde LMT -02 -01",
    abbrs: ["LMT", "-02", "-01"],
    currentAbbr: "-01",
  },
  {
    id: "Atlantic/Faeroe",
    name: "Atlantic/Faeroe (WET)",
    value: "Atlantic/Faeroe LMT WET WEST",
    abbrs: ["LMT", "WET", "WEST"],
    currentAbbr: "WET",
  },
  {
    id: "Atlantic/Faroe",
    name: "Atlantic/Faroe (WET)",
    value: "Atlantic/Faroe LMT WET WEST",
    abbrs: ["LMT", "WET", "WEST"],
    currentAbbr: "WET",
  },
  {
    id: "Atlantic/Jan_Mayen",
    name: "Atlantic/Jan Mayen (CET)",
    value: "Atlantic/Jan Mayen LMT CET CEST CEMT",
    abbrs: ["LMT", "CET", "CEST", "CEMT"],
    currentAbbr: "CET",
  },
  {
    id: "Atlantic/Madeira",
    name: "Atlantic/Madeira (WET)",
    value: "Atlantic/Madeira LMT FMT -01 +00 +01 WET WEST",
    abbrs: ["LMT", "FMT", "-01", "+00", "+01", "WET", "WEST"],
    currentAbbr: "WET",
  },
  {
    id: "Atlantic/Reykjavik",
    name: "Atlantic/Reykjavik (GMT)",
    value: "Atlantic/Reykjavik LMT GMT",
    abbrs: ["LMT", "GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "Atlantic/South_Georgia",
    name: "Atlantic/South Georgia (-02)",
    value: "Atlantic/South Georgia LMT -02",
    abbrs: ["LMT", "-02"],
    currentAbbr: "-02",
  },
  {
    id: "Atlantic/St_Helena",
    name: "Atlantic/St Helena (GMT)",
    value: "Atlantic/St Helena LMT GMT",
    abbrs: ["LMT", "GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "Atlantic/Stanley",
    name: "Atlantic/Stanley (-03)",
    value: "Atlantic/Stanley LMT SMT -04 -03 -02",
    abbrs: ["LMT", "SMT", "-04", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "Australia/ACT",
    name: "Australia/ACT (AEDT)",
    value: "Australia/ACT LMT AEST AEDT",
    abbrs: ["LMT", "AEST", "AEDT"],
    currentAbbr: "AEDT",
  },
  {
    id: "Australia/Adelaide",
    name: "Australia/Adelaide (ACDT)",
    value: "Australia/Adelaide LMT ACST ACDT",
    abbrs: ["LMT", "ACST", "ACDT"],
    currentAbbr: "ACDT",
  },
  {
    id: "Australia/Brisbane",
    name: "Australia/Brisbane (AEST)",
    value: "Australia/Brisbane LMT AEST AEDT",
    abbrs: ["LMT", "AEST", "AEDT"],
    currentAbbr: "AEST",
  },
  {
    id: "Australia/Broken_Hill",
    name: "Australia/Broken Hill (ACDT)",
    value: "Australia/Broken Hill LMT AEST ACST ACDT",
    abbrs: ["LMT", "AEST", "ACST", "ACDT"],
    currentAbbr: "ACDT",
  },
  {
    id: "Australia/Canberra",
    name: "Australia/Canberra (AEDT)",
    value: "Australia/Canberra LMT AEST AEDT",
    abbrs: ["LMT", "AEST", "AEDT"],
    currentAbbr: "AEDT",
  },
  {
    id: "Australia/Currie",
    name: "Australia/Currie (AEDT)",
    value: "Australia/Currie LMT AEST AEDT",
    abbrs: ["LMT", "AEST", "AEDT"],
    currentAbbr: "AEDT",
  },
  {
    id: "Australia/Darwin",
    name: "Australia/Darwin (ACST)",
    value: "Australia/Darwin LMT ACST ACDT",
    abbrs: ["LMT", "ACST", "ACDT"],
    currentAbbr: "ACST",
  },
  {
    id: "Australia/Eucla",
    name: "Australia/Eucla (+0845)",
    value: "Australia/Eucla LMT +0845 +0945",
    abbrs: ["LMT", "+0845", "+0945"],
    currentAbbr: "+0845",
  },
  {
    id: "Australia/Hobart",
    name: "Australia/Hobart (AEDT)",
    value: "Australia/Hobart LMT AEST AEDT",
    abbrs: ["LMT", "AEST", "AEDT"],
    currentAbbr: "AEDT",
  },
  {
    id: "Australia/LHI",
    name: "Australia/LHI (+11)",
    value: "Australia/LHI LMT AEST +1030 +1130 +11",
    abbrs: ["LMT", "AEST", "+1030", "+1130", "+11"],
    currentAbbr: "+11",
  },
  {
    id: "Australia/Lindeman",
    name: "Australia/Lindeman (AEST)",
    value: "Australia/Lindeman LMT AEST AEDT",
    abbrs: ["LMT", "AEST", "AEDT"],
    currentAbbr: "AEST",
  },
  {
    id: "Australia/Lord_Howe",
    name: "Australia/Lord Howe (+11)",
    value: "Australia/Lord Howe LMT AEST +1030 +1130 +11",
    abbrs: ["LMT", "AEST", "+1030", "+1130", "+11"],
    currentAbbr: "+11",
  },
  {
    id: "Australia/Melbourne",
    name: "Australia/Melbourne (AEDT)",
    value: "Australia/Melbourne LMT AEST AEDT",
    abbrs: ["LMT", "AEST", "AEDT"],
    currentAbbr: "AEDT",
  },
  {
    id: "Australia/NSW",
    name: "Australia/NSW (AEDT)",
    value: "Australia/NSW LMT AEST AEDT",
    abbrs: ["LMT", "AEST", "AEDT"],
    currentAbbr: "AEDT",
  },
  {
    id: "Australia/North",
    name: "Australia/North (ACST)",
    value: "Australia/North LMT ACST ACDT",
    abbrs: ["LMT", "ACST", "ACDT"],
    currentAbbr: "ACST",
  },
  {
    id: "Australia/Perth",
    name: "Australia/Perth (AWST)",
    value: "Australia/Perth LMT AWST AWDT",
    abbrs: ["LMT", "AWST", "AWDT"],
    currentAbbr: "AWST",
  },
  {
    id: "Australia/Queensland",
    name: "Australia/Queensland (AEST)",
    value: "Australia/Queensland LMT AEST AEDT",
    abbrs: ["LMT", "AEST", "AEDT"],
    currentAbbr: "AEST",
  },
  {
    id: "Australia/South",
    name: "Australia/South (ACDT)",
    value: "Australia/South LMT ACST ACDT",
    abbrs: ["LMT", "ACST", "ACDT"],
    currentAbbr: "ACDT",
  },
  {
    id: "Australia/Sydney",
    name: "Australia/Sydney (AEDT)",
    value: "Australia/Sydney LMT AEST AEDT",
    abbrs: ["LMT", "AEST", "AEDT"],
    currentAbbr: "AEDT",
  },
  {
    id: "Australia/Tasmania",
    name: "Australia/Tasmania (AEDT)",
    value: "Australia/Tasmania LMT AEST AEDT",
    abbrs: ["LMT", "AEST", "AEDT"],
    currentAbbr: "AEDT",
  },
  {
    id: "Australia/Victoria",
    name: "Australia/Victoria (AEDT)",
    value: "Australia/Victoria LMT AEST AEDT",
    abbrs: ["LMT", "AEST", "AEDT"],
    currentAbbr: "AEDT",
  },
  {
    id: "Australia/West",
    name: "Australia/West (AWST)",
    value: "Australia/West LMT AWST AWDT",
    abbrs: ["LMT", "AWST", "AWDT"],
    currentAbbr: "AWST",
  },
  {
    id: "Australia/Yancowinna",
    name: "Australia/Yancowinna (ACDT)",
    value: "Australia/Yancowinna LMT AEST ACST ACDT",
    abbrs: ["LMT", "AEST", "ACST", "ACDT"],
    currentAbbr: "ACDT",
  },
  {
    id: "Brazil/Acre",
    name: "Brazil/Acre (-05)",
    value: "Brazil/Acre LMT -05 -04",
    abbrs: ["LMT", "-05", "-04"],
    currentAbbr: "-05",
  },
  {
    id: "Brazil/DeNoronha",
    name: "Brazil/DeNoronha (-02)",
    value: "Brazil/DeNoronha LMT -02 -01",
    abbrs: ["LMT", "-02", "-01"],
    currentAbbr: "-02",
  },
  {
    id: "Brazil/East",
    name: "Brazil/East (-03)",
    value: "Brazil/East LMT -03 -02",
    abbrs: ["LMT", "-03", "-02"],
    currentAbbr: "-03",
  },
  {
    id: "Brazil/West",
    name: "Brazil/West (-04)",
    value: "Brazil/West LMT -04 -03",
    abbrs: ["LMT", "-04", "-03"],
    currentAbbr: "-04",
  },
  {
    id: "CET",
    name: "CET (CET)",
    value: "CET LMT BMT WET CET CEST WEST",
    abbrs: ["LMT", "BMT", "WET", "CET", "CEST", "WEST"],
    currentAbbr: "CET",
  },
  {
    id: "CST6CDT",
    name: "CST6CDT (CST)",
    value: "CST6CDT LMT CST CDT EST CWT CPT",
    abbrs: ["LMT", "CST", "CDT", "EST", "CWT", "CPT"],
    currentAbbr: "CST",
  },
  {
    id: "Canada/Atlantic",
    name: "Canada/Atlantic (AST)",
    value: "Canada/Atlantic LMT AST ADT AWT APT",
    abbrs: ["LMT", "AST", "ADT", "AWT", "APT"],
    currentAbbr: "AST",
  },
  {
    id: "Canada/Central",
    name: "Canada/Central (CST)",
    value: "Canada/Central LMT CST CDT CWT CPT",
    abbrs: ["LMT", "CST", "CDT", "CWT", "CPT"],
    currentAbbr: "CST",
  },
  {
    id: "Canada/Eastern",
    name: "Canada/Eastern (EST)",
    value: "Canada/Eastern LMT EST EDT EWT EPT",
    abbrs: ["LMT", "EST", "EDT", "EWT", "EPT"],
    currentAbbr: "EST",
  },
  {
    id: "Canada/Mountain",
    name: "Canada/Mountain (MST)",
    value: "Canada/Mountain LMT MST MDT MWT MPT",
    abbrs: ["LMT", "MST", "MDT", "MWT", "MPT"],
    currentAbbr: "MST",
  },
  {
    id: "Canada/Newfoundland",
    name: "Canada/Newfoundland (NST)",
    value: "Canada/Newfoundland LMT NST NDT NWT NPT NDDT",
    abbrs: ["LMT", "NST", "NDT", "NWT", "NPT", "NDDT"],
    currentAbbr: "NST",
  },
  {
    id: "Canada/Pacific",
    name: "Canada/Pacific (PST)",
    value: "Canada/Pacific LMT PST PDT PWT PPT",
    abbrs: ["LMT", "PST", "PDT", "PWT", "PPT"],
    currentAbbr: "PST",
  },
  {
    id: "Canada/Saskatchewan",
    name: "Canada/Saskatchewan (CST)",
    value: "Canada/Saskatchewan LMT MST MDT MWT MPT CST",
    abbrs: ["LMT", "MST", "MDT", "MWT", "MPT", "CST"],
    currentAbbr: "CST",
  },
  {
    id: "Canada/Yukon",
    name: "Canada/Yukon (MST)",
    value: "Canada/Yukon LMT YST YDT YWT YPT YDDT PST PDT MST",
    abbrs: ["LMT", "YST", "YDT", "YWT", "YPT", "YDDT", "PST", "PDT", "MST"],
    currentAbbr: "MST",
  },
  {
    id: "Chile/Continental",
    name: "Chile/Continental (-03)",
    value: "Chile/Continental LMT SMT -05 -04 -03",
    abbrs: ["LMT", "SMT", "-05", "-04", "-03"],
    currentAbbr: "-03",
  },
  {
    id: "Chile/EasterIsland",
    name: "Chile/EasterIsland (-05)",
    value: "Chile/EasterIsland LMT EMT -07 -06 -05",
    abbrs: ["LMT", "EMT", "-07", "-06", "-05"],
    currentAbbr: "-05",
  },
  {
    id: "Cuba",
    name: "Cuba (CST)",
    value: "Cuba LMT HMT CST CDT",
    abbrs: ["LMT", "HMT", "CST", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "EET",
    name: "EET (EET)",
    value: "EET LMT AMT EET EEST CEST CET",
    abbrs: ["LMT", "AMT", "EET", "EEST", "CEST", "CET"],
    currentAbbr: "EET",
  },
  {
    id: "EST",
    name: "EST (EST)",
    value: "EST LMT CMT EST",
    abbrs: ["LMT", "CMT", "EST"],
    currentAbbr: "EST",
  },
  {
    id: "EST5EDT",
    name: "EST5EDT (EST)",
    value: "EST5EDT LMT EST EDT EWT EPT",
    abbrs: ["LMT", "EST", "EDT", "EWT", "EPT"],
    currentAbbr: "EST",
  },
  {
    id: "Egypt",
    name: "Egypt (EET)",
    value: "Egypt LMT EET EEST",
    abbrs: ["LMT", "EET", "EEST"],
    currentAbbr: "EET",
  },
  {
    id: "Eire",
    name: "Eire (GMT)",
    value: "Eire LMT DMT IST GMT BST",
    abbrs: ["LMT", "DMT", "IST", "GMT", "BST"],
    currentAbbr: "GMT",
  },
  {
    id: "Etc/GMT",
    name: "Etc/GMT (GMT)",
    value: "Etc/GMT GMT",
    abbrs: ["GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "Etc/GMT+0",
    name: "Etc/GMT+0 (GMT)",
    value: "Etc/GMT+0 GMT",
    abbrs: ["GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "Etc/GMT+1",
    name: "Etc/GMT+1 (-01)",
    value: "Etc/GMT+1 -01",
    abbrs: ["-01"],
    currentAbbr: "-01",
  },
  {
    id: "Etc/GMT+10",
    name: "Etc/GMT+10 (-10)",
    value: "Etc/GMT+10 -10",
    abbrs: ["-10"],
    currentAbbr: "-10",
  },
  {
    id: "Etc/GMT+11",
    name: "Etc/GMT+11 (-11)",
    value: "Etc/GMT+11 -11",
    abbrs: ["-11"],
    currentAbbr: "-11",
  },
  {
    id: "Etc/GMT+12",
    name: "Etc/GMT+12 (-12)",
    value: "Etc/GMT+12 -12",
    abbrs: ["-12"],
    currentAbbr: "-12",
  },
  {
    id: "Etc/GMT+2",
    name: "Etc/GMT+2 (-02)",
    value: "Etc/GMT+2 -02",
    abbrs: ["-02"],
    currentAbbr: "-02",
  },
  {
    id: "Etc/GMT+3",
    name: "Etc/GMT+3 (-03)",
    value: "Etc/GMT+3 -03",
    abbrs: ["-03"],
    currentAbbr: "-03",
  },
  {
    id: "Etc/GMT+4",
    name: "Etc/GMT+4 (-04)",
    value: "Etc/GMT+4 -04",
    abbrs: ["-04"],
    currentAbbr: "-04",
  },
  {
    id: "Etc/GMT+5",
    name: "Etc/GMT+5 (-05)",
    value: "Etc/GMT+5 -05",
    abbrs: ["-05"],
    currentAbbr: "-05",
  },
  {
    id: "Etc/GMT+6",
    name: "Etc/GMT+6 (-06)",
    value: "Etc/GMT+6 -06",
    abbrs: ["-06"],
    currentAbbr: "-06",
  },
  {
    id: "Etc/GMT+7",
    name: "Etc/GMT+7 (-07)",
    value: "Etc/GMT+7 -07",
    abbrs: ["-07"],
    currentAbbr: "-07",
  },
  {
    id: "Etc/GMT+8",
    name: "Etc/GMT+8 (-08)",
    value: "Etc/GMT+8 -08",
    abbrs: ["-08"],
    currentAbbr: "-08",
  },
  {
    id: "Etc/GMT+9",
    name: "Etc/GMT+9 (-09)",
    value: "Etc/GMT+9 -09",
    abbrs: ["-09"],
    currentAbbr: "-09",
  },
  {
    id: "Etc/GMT-0",
    name: "Etc/GMT-0 (GMT)",
    value: "Etc/GMT-0 GMT",
    abbrs: ["GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "Etc/GMT-1",
    name: "Etc/GMT-1 (+01)",
    value: "Etc/GMT-1 +01",
    abbrs: ["+01"],
    currentAbbr: "+01",
  },
  {
    id: "Etc/GMT-10",
    name: "Etc/GMT-10 (+10)",
    value: "Etc/GMT-10 +10",
    abbrs: ["+10"],
    currentAbbr: "+10",
  },
  {
    id: "Etc/GMT-11",
    name: "Etc/GMT-11 (+11)",
    value: "Etc/GMT-11 +11",
    abbrs: ["+11"],
    currentAbbr: "+11",
  },
  {
    id: "Etc/GMT-12",
    name: "Etc/GMT-12 (+12)",
    value: "Etc/GMT-12 +12",
    abbrs: ["+12"],
    currentAbbr: "+12",
  },
  {
    id: "Etc/GMT-13",
    name: "Etc/GMT-13 (+13)",
    value: "Etc/GMT-13 +13",
    abbrs: ["+13"],
    currentAbbr: "+13",
  },
  {
    id: "Etc/GMT-14",
    name: "Etc/GMT-14 (+14)",
    value: "Etc/GMT-14 +14",
    abbrs: ["+14"],
    currentAbbr: "+14",
  },
  {
    id: "Etc/GMT-2",
    name: "Etc/GMT-2 (+02)",
    value: "Etc/GMT-2 +02",
    abbrs: ["+02"],
    currentAbbr: "+02",
  },
  {
    id: "Etc/GMT-3",
    name: "Etc/GMT-3 (+03)",
    value: "Etc/GMT-3 +03",
    abbrs: ["+03"],
    currentAbbr: "+03",
  },
  {
    id: "Etc/GMT-4",
    name: "Etc/GMT-4 (+04)",
    value: "Etc/GMT-4 +04",
    abbrs: ["+04"],
    currentAbbr: "+04",
  },
  {
    id: "Etc/GMT-5",
    name: "Etc/GMT-5 (+05)",
    value: "Etc/GMT-5 +05",
    abbrs: ["+05"],
    currentAbbr: "+05",
  },
  {
    id: "Etc/GMT-6",
    name: "Etc/GMT-6 (+06)",
    value: "Etc/GMT-6 +06",
    abbrs: ["+06"],
    currentAbbr: "+06",
  },
  {
    id: "Etc/GMT-7",
    name: "Etc/GMT-7 (+07)",
    value: "Etc/GMT-7 +07",
    abbrs: ["+07"],
    currentAbbr: "+07",
  },
  {
    id: "Etc/GMT-8",
    name: "Etc/GMT-8 (+08)",
    value: "Etc/GMT-8 +08",
    abbrs: ["+08"],
    currentAbbr: "+08",
  },
  {
    id: "Etc/GMT-9",
    name: "Etc/GMT-9 (+09)",
    value: "Etc/GMT-9 +09",
    abbrs: ["+09"],
    currentAbbr: "+09",
  },
  {
    id: "Etc/GMT0",
    name: "Etc/GMT0 (GMT)",
    value: "Etc/GMT0 GMT",
    abbrs: ["GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "Etc/Greenwich",
    name: "Etc/Greenwich (GMT)",
    value: "Etc/Greenwich GMT",
    abbrs: ["GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "Etc/UCT",
    name: "Etc/UCT (UTC)",
    value: "Etc/UCT UTC",
    abbrs: ["UTC"],
    currentAbbr: "UTC",
  },
  {
    id: "Etc/UTC",
    name: "Etc/UTC (UTC)",
    value: "Etc/UTC UTC",
    abbrs: ["UTC"],
    currentAbbr: "UTC",
  },
  {
    id: "Etc/Universal",
    name: "Etc/Universal (UTC)",
    value: "Etc/Universal UTC",
    abbrs: ["UTC"],
    currentAbbr: "UTC",
  },
  {
    id: "Etc/Zulu",
    name: "Etc/Zulu (UTC)",
    value: "Etc/Zulu UTC",
    abbrs: ["UTC"],
    currentAbbr: "UTC",
  },
  {
    id: "Europe/Amsterdam",
    name: "Europe/Amsterdam (CET)",
    value: "Europe/Amsterdam LMT BMT WET CET CEST WEST",
    abbrs: ["LMT", "BMT", "WET", "CET", "CEST", "WEST"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Andorra",
    name: "Europe/Andorra (CET)",
    value: "Europe/Andorra LMT WET CET CEST",
    abbrs: ["LMT", "WET", "CET", "CEST"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Astrakhan",
    name: "Europe/Astrakhan (+04)",
    value: "Europe/Astrakhan LMT +03 +04 +05",
    abbrs: ["LMT", "+03", "+04", "+05"],
    currentAbbr: "+04",
  },
  {
    id: "Europe/Athens",
    name: "Europe/Athens (EET)",
    value: "Europe/Athens LMT AMT EET EEST CEST CET",
    abbrs: ["LMT", "AMT", "EET", "EEST", "CEST", "CET"],
    currentAbbr: "EET",
  },
  {
    id: "Europe/Belfast",
    name: "Europe/Belfast (GMT)",
    value: "Europe/Belfast LMT GMT BST BDST",
    abbrs: ["LMT", "GMT", "BST", "BDST"],
    currentAbbr: "GMT",
  },
  {
    id: "Europe/Belgrade",
    name: "Europe/Belgrade (CET)",
    value: "Europe/Belgrade LMT CET CEST",
    abbrs: ["LMT", "CET", "CEST"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Berlin",
    name: "Europe/Berlin (CET)",
    value: "Europe/Berlin LMT CET CEST CEMT",
    abbrs: ["LMT", "CET", "CEST", "CEMT"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Bratislava",
    name: "Europe/Bratislava (CET)",
    value: "Europe/Bratislava LMT PMT CET CEST GMT",
    abbrs: ["LMT", "PMT", "CET", "CEST", "GMT"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Brussels",
    name: "Europe/Brussels (CET)",
    value: "Europe/Brussels LMT BMT WET CET CEST WEST",
    abbrs: ["LMT", "BMT", "WET", "CET", "CEST", "WEST"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Bucharest",
    name: "Europe/Bucharest (EET)",
    value: "Europe/Bucharest LMT BMT EET EEST",
    abbrs: ["LMT", "BMT", "EET", "EEST"],
    currentAbbr: "EET",
  },
  {
    id: "Europe/Budapest",
    name: "Europe/Budapest (CET)",
    value: "Europe/Budapest LMT CET CEST",
    abbrs: ["LMT", "CET", "CEST"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Busingen",
    name: "Europe/Busingen (CET)",
    value: "Europe/Busingen LMT BMT CET CEST",
    abbrs: ["LMT", "BMT", "CET", "CEST"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Chisinau",
    name: "Europe/Chisinau (EET)",
    value: "Europe/Chisinau LMT CMT BMT EET EEST CEST CET MSK MSD",
    abbrs: ["LMT", "CMT", "BMT", "EET", "EEST", "CEST", "CET", "MSK", "MSD"],
    currentAbbr: "EET",
  },
  {
    id: "Europe/Copenhagen",
    name: "Europe/Copenhagen (CET)",
    value: "Europe/Copenhagen LMT CET CEST CEMT",
    abbrs: ["LMT", "CET", "CEST", "CEMT"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Dublin",
    name: "Europe/Dublin (GMT)",
    value: "Europe/Dublin LMT DMT IST GMT BST",
    abbrs: ["LMT", "DMT", "IST", "GMT", "BST"],
    currentAbbr: "GMT",
  },
  {
    id: "Europe/Gibraltar",
    name: "Europe/Gibraltar (CET)",
    value: "Europe/Gibraltar LMT GMT BST BDST CET CEST",
    abbrs: ["LMT", "GMT", "BST", "BDST", "CET", "CEST"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Guernsey",
    name: "Europe/Guernsey (GMT)",
    value: "Europe/Guernsey LMT GMT BST BDST",
    abbrs: ["LMT", "GMT", "BST", "BDST"],
    currentAbbr: "GMT",
  },
  {
    id: "Europe/Helsinki",
    name: "Europe/Helsinki (EET)",
    value: "Europe/Helsinki LMT HMT EET EEST",
    abbrs: ["LMT", "HMT", "EET", "EEST"],
    currentAbbr: "EET",
  },
  {
    id: "Europe/Isle_of_Man",
    name: "Europe/Isle of Man (GMT)",
    value: "Europe/Isle of Man LMT GMT BST BDST",
    abbrs: ["LMT", "GMT", "BST", "BDST"],
    currentAbbr: "GMT",
  },
  {
    id: "Europe/Istanbul",
    name: "Europe/Istanbul (+03)",
    value: "Europe/Istanbul LMT IMT EET EEST +03 +04",
    abbrs: ["LMT", "IMT", "EET", "EEST", "+03", "+04"],
    currentAbbr: "+03",
  },
  {
    id: "Europe/Jersey",
    name: "Europe/Jersey (GMT)",
    value: "Europe/Jersey LMT GMT BST BDST",
    abbrs: ["LMT", "GMT", "BST", "BDST"],
    currentAbbr: "GMT",
  },
  {
    id: "Europe/Kaliningrad",
    name: "Europe/Kaliningrad (EET)",
    value: "Europe/Kaliningrad LMT CET CEST EET EEST MSK MSD +03",
    abbrs: ["LMT", "CET", "CEST", "EET", "EEST", "MSK", "MSD", "+03"],
    currentAbbr: "EET",
  },
  {
    id: "Europe/Kiev",
    name: "Europe/Kiev (EET)",
    value: "Europe/Kiev LMT KMT EET MSK CEST CET MSD EEST",
    abbrs: ["LMT", "KMT", "EET", "MSK", "CEST", "CET", "MSD", "EEST"],
    currentAbbr: "EET",
  },
  {
    id: "Europe/Kirov",
    name: "Europe/Kirov (MSK)",
    value: "Europe/Kirov LMT +03 +04 +05 MSD MSK",
    abbrs: ["LMT", "+03", "+04", "+05", "MSD", "MSK"],
    currentAbbr: "MSK",
  },
  {
    id: "Europe/Kyiv",
    name: "Europe/Kyiv (EET)",
    value: "Europe/Kyiv LMT KMT EET MSK CEST CET MSD EEST",
    abbrs: ["LMT", "KMT", "EET", "MSK", "CEST", "CET", "MSD", "EEST"],
    currentAbbr: "EET",
  },
  {
    id: "Europe/Lisbon",
    name: "Europe/Lisbon (WET)",
    value: "Europe/Lisbon LMT WET WEST WEMT CET CEST",
    abbrs: ["LMT", "WET", "WEST", "WEMT", "CET", "CEST"],
    currentAbbr: "WET",
  },
  {
    id: "Europe/Ljubljana",
    name: "Europe/Ljubljana (CET)",
    value: "Europe/Ljubljana LMT CET CEST",
    abbrs: ["LMT", "CET", "CEST"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/London",
    name: "Europe/London (GMT)",
    value: "Europe/London LMT GMT BST BDST",
    abbrs: ["LMT", "GMT", "BST", "BDST"],
    currentAbbr: "GMT",
  },
  {
    id: "Europe/Luxembourg",
    name: "Europe/Luxembourg (CET)",
    value: "Europe/Luxembourg LMT BMT WET CET CEST WEST",
    abbrs: ["LMT", "BMT", "WET", "CET", "CEST", "WEST"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Madrid",
    name: "Europe/Madrid (CET)",
    value: "Europe/Madrid LMT WET WEST WEMT CET CEST",
    abbrs: ["LMT", "WET", "WEST", "WEMT", "CET", "CEST"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Malta",
    name: "Europe/Malta (CET)",
    value: "Europe/Malta LMT CET CEST",
    abbrs: ["LMT", "CET", "CEST"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Mariehamn",
    name: "Europe/Mariehamn (EET)",
    value: "Europe/Mariehamn LMT HMT EET EEST",
    abbrs: ["LMT", "HMT", "EET", "EEST"],
    currentAbbr: "EET",
  },
  {
    id: "Europe/Minsk",
    name: "Europe/Minsk (+03)",
    value: "Europe/Minsk LMT MMT EET MSK CEST CET MSD EEST +03",
    abbrs: ["LMT", "MMT", "EET", "MSK", "CEST", "CET", "MSD", "EEST", "+03"],
    currentAbbr: "+03",
  },
  {
    id: "Europe/Monaco",
    name: "Europe/Monaco (CET)",
    value: "Europe/Monaco LMT PMT WET WEST CEST CET WEMT",
    abbrs: ["LMT", "PMT", "WET", "WEST", "CEST", "CET", "WEMT"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Moscow",
    name: "Europe/Moscow (MSK)",
    value: "Europe/Moscow LMT MMT MST MDST MSD MSK +05 EET EEST",
    abbrs: ["LMT", "MMT", "MST", "MDST", "MSD", "MSK", "+05", "EET", "EEST"],
    currentAbbr: "MSK",
  },
  {
    id: "Europe/Nicosia",
    name: "Europe/Nicosia (EET)",
    value: "Europe/Nicosia LMT EET EEST",
    abbrs: ["LMT", "EET", "EEST"],
    currentAbbr: "EET",
  },
  {
    id: "Europe/Oslo",
    name: "Europe/Oslo (CET)",
    value: "Europe/Oslo LMT CET CEST CEMT",
    abbrs: ["LMT", "CET", "CEST", "CEMT"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Paris",
    name: "Europe/Paris (CET)",
    value: "Europe/Paris LMT PMT WET WEST CEST CET WEMT",
    abbrs: ["LMT", "PMT", "WET", "WEST", "CEST", "CET", "WEMT"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Podgorica",
    name: "Europe/Podgorica (CET)",
    value: "Europe/Podgorica LMT CET CEST",
    abbrs: ["LMT", "CET", "CEST"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Prague",
    name: "Europe/Prague (CET)",
    value: "Europe/Prague LMT PMT CET CEST GMT",
    abbrs: ["LMT", "PMT", "CET", "CEST", "GMT"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Riga",
    name: "Europe/Riga (EET)",
    value: "Europe/Riga LMT RMT LST EET MSK CEST CET MSD EEST",
    abbrs: ["LMT", "RMT", "LST", "EET", "MSK", "CEST", "CET", "MSD", "EEST"],
    currentAbbr: "EET",
  },
  {
    id: "Europe/Rome",
    name: "Europe/Rome (CET)",
    value: "Europe/Rome LMT RMT CET CEST",
    abbrs: ["LMT", "RMT", "CET", "CEST"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Samara",
    name: "Europe/Samara (+04)",
    value: "Europe/Samara LMT +03 +04 +05",
    abbrs: ["LMT", "+03", "+04", "+05"],
    currentAbbr: "+04",
  },
  {
    id: "Europe/San_Marino",
    name: "Europe/San Marino (CET)",
    value: "Europe/San Marino LMT RMT CET CEST",
    abbrs: ["LMT", "RMT", "CET", "CEST"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Sarajevo",
    name: "Europe/Sarajevo (CET)",
    value: "Europe/Sarajevo LMT CET CEST",
    abbrs: ["LMT", "CET", "CEST"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Saratov",
    name: "Europe/Saratov (+04)",
    value: "Europe/Saratov LMT +03 +04 +05",
    abbrs: ["LMT", "+03", "+04", "+05"],
    currentAbbr: "+04",
  },
  {
    id: "Europe/Simferopol",
    name: "Europe/Simferopol (MSK)",
    value: "Europe/Simferopol LMT SMT EET MSK CEST CET MSD EEST",
    abbrs: ["LMT", "SMT", "EET", "MSK", "CEST", "CET", "MSD", "EEST"],
    currentAbbr: "MSK",
  },
  {
    id: "Europe/Skopje",
    name: "Europe/Skopje (CET)",
    value: "Europe/Skopje LMT CET CEST",
    abbrs: ["LMT", "CET", "CEST"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Sofia",
    name: "Europe/Sofia (EET)",
    value: "Europe/Sofia LMT IMT EET CET CEST EEST",
    abbrs: ["LMT", "IMT", "EET", "CET", "CEST", "EEST"],
    currentAbbr: "EET",
  },
  {
    id: "Europe/Stockholm",
    name: "Europe/Stockholm (CET)",
    value: "Europe/Stockholm LMT CET CEST CEMT",
    abbrs: ["LMT", "CET", "CEST", "CEMT"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Tallinn",
    name: "Europe/Tallinn (EET)",
    value: "Europe/Tallinn LMT TMT CET CEST EET MSK MSD EEST",
    abbrs: ["LMT", "TMT", "CET", "CEST", "EET", "MSK", "MSD", "EEST"],
    currentAbbr: "EET",
  },
  {
    id: "Europe/Tirane",
    name: "Europe/Tirane (CET)",
    value: "Europe/Tirane LMT CET CEST",
    abbrs: ["LMT", "CET", "CEST"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Tiraspol",
    name: "Europe/Tiraspol (EET)",
    value: "Europe/Tiraspol LMT CMT BMT EET EEST CEST CET MSK MSD",
    abbrs: ["LMT", "CMT", "BMT", "EET", "EEST", "CEST", "CET", "MSK", "MSD"],
    currentAbbr: "EET",
  },
  {
    id: "Europe/Ulyanovsk",
    name: "Europe/Ulyanovsk (+04)",
    value: "Europe/Ulyanovsk LMT +03 +04 +05 +02",
    abbrs: ["LMT", "+03", "+04", "+05", "+02"],
    currentAbbr: "+04",
  },
  {
    id: "Europe/Uzhgorod",
    name: "Europe/Uzhgorod (EET)",
    value: "Europe/Uzhgorod LMT KMT EET MSK CEST CET MSD EEST",
    abbrs: ["LMT", "KMT", "EET", "MSK", "CEST", "CET", "MSD", "EEST"],
    currentAbbr: "EET",
  },
  {
    id: "Europe/Vaduz",
    name: "Europe/Vaduz (CET)",
    value: "Europe/Vaduz LMT BMT CET CEST",
    abbrs: ["LMT", "BMT", "CET", "CEST"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Vatican",
    name: "Europe/Vatican (CET)",
    value: "Europe/Vatican LMT RMT CET CEST",
    abbrs: ["LMT", "RMT", "CET", "CEST"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Vienna",
    name: "Europe/Vienna (CET)",
    value: "Europe/Vienna LMT CET CEST",
    abbrs: ["LMT", "CET", "CEST"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Vilnius",
    name: "Europe/Vilnius (EET)",
    value: "Europe/Vilnius LMT WMT KMT CET EET MSK CEST MSD EEST",
    abbrs: ["LMT", "WMT", "KMT", "CET", "EET", "MSK", "CEST", "MSD", "EEST"],
    currentAbbr: "EET",
  },
  {
    id: "Europe/Volgograd",
    name: "Europe/Volgograd (MSK)",
    value: "Europe/Volgograd LMT +03 +04 +05 MSD MSK",
    abbrs: ["LMT", "+03", "+04", "+05", "MSD", "MSK"],
    currentAbbr: "MSK",
  },
  {
    id: "Europe/Warsaw",
    name: "Europe/Warsaw (CET)",
    value: "Europe/Warsaw LMT WMT CET CEST EET EEST",
    abbrs: ["LMT", "WMT", "CET", "CEST", "EET", "EEST"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Zagreb",
    name: "Europe/Zagreb (CET)",
    value: "Europe/Zagreb LMT CET CEST",
    abbrs: ["LMT", "CET", "CEST"],
    currentAbbr: "CET",
  },
  {
    id: "Europe/Zaporozhye",
    name: "Europe/Zaporozhye (EET)",
    value: "Europe/Zaporozhye LMT KMT EET MSK CEST CET MSD EEST",
    abbrs: ["LMT", "KMT", "EET", "MSK", "CEST", "CET", "MSD", "EEST"],
    currentAbbr: "EET",
  },
  {
    id: "Europe/Zurich",
    name: "Europe/Zurich (CET)",
    value: "Europe/Zurich LMT BMT CET CEST",
    abbrs: ["LMT", "BMT", "CET", "CEST"],
    currentAbbr: "CET",
  },
  {
    id: "GB",
    name: "GB (GMT)",
    value: "GB LMT GMT BST BDST",
    abbrs: ["LMT", "GMT", "BST", "BDST"],
    currentAbbr: "GMT",
  },
  {
    id: "GB-Eire",
    name: "GB-Eire (GMT)",
    value: "GB-Eire LMT GMT BST BDST",
    abbrs: ["LMT", "GMT", "BST", "BDST"],
    currentAbbr: "GMT",
  },
  {
    id: "GMT",
    name: "GMT (GMT)",
    value: "GMT GMT",
    abbrs: ["GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "GMT+0",
    name: "GMT+0 (GMT)",
    value: "GMT+0 GMT",
    abbrs: ["GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "GMT-0",
    name: "GMT-0 (GMT)",
    value: "GMT-0 GMT",
    abbrs: ["GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "GMT0",
    name: "GMT0 (GMT)",
    value: "GMT0 GMT",
    abbrs: ["GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "Greenwich",
    name: "Greenwich (GMT)",
    value: "Greenwich GMT",
    abbrs: ["GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "HST",
    name: "HST (HST)",
    value: "HST LMT HST HDT HWT HPT",
    abbrs: ["LMT", "HST", "HDT", "HWT", "HPT"],
    currentAbbr: "HST",
  },
  {
    id: "Hongkong",
    name: "Hongkong (HKT)",
    value: "Hongkong LMT HKT HKST HKWT JST",
    abbrs: ["LMT", "HKT", "HKST", "HKWT", "JST"],
    currentAbbr: "HKT",
  },
  {
    id: "Iceland",
    name: "Iceland (GMT)",
    value: "Iceland LMT GMT",
    abbrs: ["LMT", "GMT"],
    currentAbbr: "GMT",
  },
  {
    id: "Indian/Antananarivo",
    name: "Indian/Antananarivo (EAT)",
    value: "Indian/Antananarivo LMT +0230 EAT +0245",
    abbrs: ["LMT", "+0230", "EAT", "+0245"],
    currentAbbr: "EAT",
  },
  {
    id: "Indian/Chagos",
    name: "Indian/Chagos (+06)",
    value: "Indian/Chagos LMT +05 +06",
    abbrs: ["LMT", "+05", "+06"],
    currentAbbr: "+06",
  },
  {
    id: "Indian/Christmas",
    name: "Indian/Christmas (+07)",
    value: "Indian/Christmas LMT BMT +07",
    abbrs: ["LMT", "BMT", "+07"],
    currentAbbr: "+07",
  },
  {
    id: "Indian/Cocos",
    name: "Indian/Cocos (+0630)",
    value: "Indian/Cocos LMT RMT +0630 +09",
    abbrs: ["LMT", "RMT", "+0630", "+09"],
    currentAbbr: "+0630",
  },
  {
    id: "Indian/Comoro",
    name: "Indian/Comoro (EAT)",
    value: "Indian/Comoro LMT +0230 EAT +0245",
    abbrs: ["LMT", "+0230", "EAT", "+0245"],
    currentAbbr: "EAT",
  },
  {
    id: "Indian/Kerguelen",
    name: "Indian/Kerguelen (+05)",
    value: "Indian/Kerguelen LMT MMT +05",
    abbrs: ["LMT", "MMT", "+05"],
    currentAbbr: "+05",
  },
  {
    id: "Indian/Mahe",
    name: "Indian/Mahe (+04)",
    value: "Indian/Mahe LMT +04",
    abbrs: ["LMT", "+04"],
    currentAbbr: "+04",
  },
  {
    id: "Indian/Maldives",
    name: "Indian/Maldives (+05)",
    value: "Indian/Maldives LMT MMT +05",
    abbrs: ["LMT", "MMT", "+05"],
    currentAbbr: "+05",
  },
  {
    id: "Indian/Mauritius",
    name: "Indian/Mauritius (+04)",
    value: "Indian/Mauritius LMT +04 +05",
    abbrs: ["LMT", "+04", "+05"],
    currentAbbr: "+04",
  },
  {
    id: "Indian/Mayotte",
    name: "Indian/Mayotte (EAT)",
    value: "Indian/Mayotte LMT +0230 EAT +0245",
    abbrs: ["LMT", "+0230", "EAT", "+0245"],
    currentAbbr: "EAT",
  },
  {
    id: "Indian/Reunion",
    name: "Indian/Reunion (+04)",
    value: "Indian/Reunion LMT +04",
    abbrs: ["LMT", "+04"],
    currentAbbr: "+04",
  },
  {
    id: "Iran",
    name: "Iran (+0330)",
    value: "Iran LMT TMT +0330 +0430 +04 +05",
    abbrs: ["LMT", "TMT", "+0330", "+0430", "+04", "+05"],
    currentAbbr: "+0330",
  },
  {
    id: "Israel",
    name: "Israel (IST)",
    value: "Israel LMT JMT IST IDT IDDT",
    abbrs: ["LMT", "JMT", "IST", "IDT", "IDDT"],
    currentAbbr: "IST",
  },
  {
    id: "Jamaica",
    name: "Jamaica (EST)",
    value: "Jamaica LMT KMT EST EDT",
    abbrs: ["LMT", "KMT", "EST", "EDT"],
    currentAbbr: "EST",
  },
  {
    id: "Japan",
    name: "Japan (JST)",
    value: "Japan LMT JST JDT",
    abbrs: ["LMT", "JST", "JDT"],
    currentAbbr: "JST",
  },
  {
    id: "Kwajalein",
    name: "Kwajalein (+12)",
    value: "Kwajalein LMT +11 +10 +09 -12 +12",
    abbrs: ["LMT", "+11", "+10", "+09", "-12", "+12"],
    currentAbbr: "+12",
  },
  {
    id: "Libya",
    name: "Libya (EET)",
    value: "Libya LMT CET CEST EET",
    abbrs: ["LMT", "CET", "CEST", "EET"],
    currentAbbr: "EET",
  },
  {
    id: "MET",
    name: "MET (CET)",
    value: "MET LMT BMT WET CET CEST WEST",
    abbrs: ["LMT", "BMT", "WET", "CET", "CEST", "WEST"],
    currentAbbr: "CET",
  },
  {
    id: "MST",
    name: "MST (MST)",
    value: "MST LMT MST MDT MWT",
    abbrs: ["LMT", "MST", "MDT", "MWT"],
    currentAbbr: "MST",
  },
  {
    id: "MST7MDT",
    name: "MST7MDT (MST)",
    value: "MST7MDT LMT MST MDT MWT MPT",
    abbrs: ["LMT", "MST", "MDT", "MWT", "MPT"],
    currentAbbr: "MST",
  },
  {
    id: "Mexico/BajaNorte",
    name: "Mexico/BajaNorte (PST)",
    value: "Mexico/BajaNorte LMT MST PST PDT PWT PPT",
    abbrs: ["LMT", "MST", "PST", "PDT", "PWT", "PPT"],
    currentAbbr: "PST",
  },
  {
    id: "Mexico/BajaSur",
    name: "Mexico/BajaSur (MST)",
    value: "Mexico/BajaSur LMT MST CST MDT",
    abbrs: ["LMT", "MST", "CST", "MDT"],
    currentAbbr: "MST",
  },
  {
    id: "Mexico/General",
    name: "Mexico/General (CST)",
    value: "Mexico/General LMT MST CST MDT CDT CWT",
    abbrs: ["LMT", "MST", "CST", "MDT", "CDT", "CWT"],
    currentAbbr: "CST",
  },
  {
    id: "NZ",
    name: "NZ (NZDT)",
    value: "NZ LMT NZMT NZST NZDT",
    abbrs: ["LMT", "NZMT", "NZST", "NZDT"],
    currentAbbr: "NZDT",
  },
  {
    id: "NZ-CHAT",
    name: "NZ-CHAT (+1345)",
    value: "NZ-CHAT LMT +1215 +1245 +1345",
    abbrs: ["LMT", "+1215", "+1245", "+1345"],
    currentAbbr: "+1345",
  },
  {
    id: "Navajo",
    name: "Navajo (MST)",
    value: "Navajo LMT MST MDT MWT MPT",
    abbrs: ["LMT", "MST", "MDT", "MWT", "MPT"],
    currentAbbr: "MST",
  },
  {
    id: "PRC",
    name: "PRC (CST)",
    value: "PRC LMT CST CDT",
    abbrs: ["LMT", "CST", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "PST8PDT",
    name: "PST8PDT (PST)",
    value: "PST8PDT LMT PST PDT PWT PPT",
    abbrs: ["LMT", "PST", "PDT", "PWT", "PPT"],
    currentAbbr: "PST",
  },
  {
    id: "Pacific/Apia",
    name: "Pacific/Apia (+13)",
    value: "Pacific/Apia LMT -1130 -11 -10 +14 +13",
    abbrs: ["LMT", "-1130", "-11", "-10", "+14", "+13"],
    currentAbbr: "+13",
  },
  {
    id: "Pacific/Auckland",
    name: "Pacific/Auckland (NZDT)",
    value: "Pacific/Auckland LMT NZMT NZST NZDT",
    abbrs: ["LMT", "NZMT", "NZST", "NZDT"],
    currentAbbr: "NZDT",
  },
  {
    id: "Pacific/Bougainville",
    name: "Pacific/Bougainville (+11)",
    value: "Pacific/Bougainville LMT PMMT +10 +09 +11",
    abbrs: ["LMT", "PMMT", "+10", "+09", "+11"],
    currentAbbr: "+11",
  },
  {
    id: "Pacific/Chatham",
    name: "Pacific/Chatham (+1345)",
    value: "Pacific/Chatham LMT +1215 +1245 +1345",
    abbrs: ["LMT", "+1215", "+1245", "+1345"],
    currentAbbr: "+1345",
  },
  {
    id: "Pacific/Chuuk",
    name: "Pacific/Chuuk (+10)",
    value: "Pacific/Chuuk LMT PMMT +10",
    abbrs: ["LMT", "PMMT", "+10"],
    currentAbbr: "+10",
  },
  {
    id: "Pacific/Easter",
    name: "Pacific/Easter (-05)",
    value: "Pacific/Easter LMT EMT -07 -06 -05",
    abbrs: ["LMT", "EMT", "-07", "-06", "-05"],
    currentAbbr: "-05",
  },
  {
    id: "Pacific/Efate",
    name: "Pacific/Efate (+11)",
    value: "Pacific/Efate LMT +11 +12",
    abbrs: ["LMT", "+11", "+12"],
    currentAbbr: "+11",
  },
  {
    id: "Pacific/Enderbury",
    name: "Pacific/Enderbury (+13)",
    value: "Pacific/Enderbury -00 -12 -11 +13",
    abbrs: ["-00", "-12", "-11", "+13"],
    currentAbbr: "+13",
  },
  {
    id: "Pacific/Fakaofo",
    name: "Pacific/Fakaofo (+13)",
    value: "Pacific/Fakaofo LMT -11 +13",
    abbrs: ["LMT", "-11", "+13"],
    currentAbbr: "+13",
  },
  {
    id: "Pacific/Fiji",
    name: "Pacific/Fiji (+12)",
    value: "Pacific/Fiji LMT +12 +13",
    abbrs: ["LMT", "+12", "+13"],
    currentAbbr: "+12",
  },
  {
    id: "Pacific/Funafuti",
    name: "Pacific/Funafuti (+12)",
    value: "Pacific/Funafuti LMT +12",
    abbrs: ["LMT", "+12"],
    currentAbbr: "+12",
  },
  {
    id: "Pacific/Galapagos",
    name: "Pacific/Galapagos (-06)",
    value: "Pacific/Galapagos LMT -05 -06",
    abbrs: ["LMT", "-05", "-06"],
    currentAbbr: "-06",
  },
  {
    id: "Pacific/Gambier",
    name: "Pacific/Gambier (-09)",
    value: "Pacific/Gambier LMT -09",
    abbrs: ["LMT", "-09"],
    currentAbbr: "-09",
  },
  {
    id: "Pacific/Guadalcanal",
    name: "Pacific/Guadalcanal (+11)",
    value: "Pacific/Guadalcanal LMT +11",
    abbrs: ["LMT", "+11"],
    currentAbbr: "+11",
  },
  {
    id: "Pacific/Guam",
    name: "Pacific/Guam (ChST)",
    value: "Pacific/Guam LMT GST +09 GDT ChST",
    abbrs: ["LMT", "GST", "+09", "GDT", "ChST"],
    currentAbbr: "ChST",
  },
  {
    id: "Pacific/Honolulu",
    name: "Pacific/Honolulu (HST)",
    value: "Pacific/Honolulu LMT HST HDT HWT HPT",
    abbrs: ["LMT", "HST", "HDT", "HWT", "HPT"],
    currentAbbr: "HST",
  },
  {
    id: "Pacific/Johnston",
    name: "Pacific/Johnston (HST)",
    value: "Pacific/Johnston LMT HST HDT HWT HPT",
    abbrs: ["LMT", "HST", "HDT", "HWT", "HPT"],
    currentAbbr: "HST",
  },
  {
    id: "Pacific/Kanton",
    name: "Pacific/Kanton (+13)",
    value: "Pacific/Kanton -00 -12 -11 +13",
    abbrs: ["-00", "-12", "-11", "+13"],
    currentAbbr: "+13",
  },
  {
    id: "Pacific/Kiritimati",
    name: "Pacific/Kiritimati (+14)",
    value: "Pacific/Kiritimati LMT -1040 -10 +14",
    abbrs: ["LMT", "-1040", "-10", "+14"],
    currentAbbr: "+14",
  },
  {
    id: "Pacific/Kosrae",
    name: "Pacific/Kosrae (+11)",
    value: "Pacific/Kosrae LMT +11 +09 +10 +12",
    abbrs: ["LMT", "+11", "+09", "+10", "+12"],
    currentAbbr: "+11",
  },
  {
    id: "Pacific/Kwajalein",
    name: "Pacific/Kwajalein (+12)",
    value: "Pacific/Kwajalein LMT +11 +10 +09 -12 +12",
    abbrs: ["LMT", "+11", "+10", "+09", "-12", "+12"],
    currentAbbr: "+12",
  },
  {
    id: "Pacific/Majuro",
    name: "Pacific/Majuro (+12)",
    value: "Pacific/Majuro LMT +12",
    abbrs: ["LMT", "+12"],
    currentAbbr: "+12",
  },
  {
    id: "Pacific/Marquesas",
    name: "Pacific/Marquesas (-0930)",
    value: "Pacific/Marquesas LMT -0930",
    abbrs: ["LMT", "-0930"],
    currentAbbr: "-0930",
  },
  {
    id: "Pacific/Midway",
    name: "Pacific/Midway (SST)",
    value: "Pacific/Midway LMT SST",
    abbrs: ["LMT", "SST"],
    currentAbbr: "SST",
  },
  {
    id: "Pacific/Nauru",
    name: "Pacific/Nauru (+12)",
    value: "Pacific/Nauru LMT +1130 +09 +12",
    abbrs: ["LMT", "+1130", "+09", "+12"],
    currentAbbr: "+12",
  },
  {
    id: "Pacific/Niue",
    name: "Pacific/Niue (-11)",
    value: "Pacific/Niue LMT -1120 -11",
    abbrs: ["LMT", "-1120", "-11"],
    currentAbbr: "-11",
  },
  {
    id: "Pacific/Norfolk",
    name: "Pacific/Norfolk (+12)",
    value: "Pacific/Norfolk LMT +1112 +1130 +1230 +11 +12",
    abbrs: ["LMT", "+1112", "+1130", "+1230", "+11", "+12"],
    currentAbbr: "+12",
  },
  {
    id: "Pacific/Noumea",
    name: "Pacific/Noumea (+11)",
    value: "Pacific/Noumea LMT +11 +12",
    abbrs: ["LMT", "+11", "+12"],
    currentAbbr: "+11",
  },
  {
    id: "Pacific/Pago_Pago",
    name: "Pacific/Pago Pago (SST)",
    value: "Pacific/Pago Pago LMT SST",
    abbrs: ["LMT", "SST"],
    currentAbbr: "SST",
  },
  {
    id: "Pacific/Palau",
    name: "Pacific/Palau (+09)",
    value: "Pacific/Palau LMT +09",
    abbrs: ["LMT", "+09"],
    currentAbbr: "+09",
  },
  {
    id: "Pacific/Pitcairn",
    name: "Pacific/Pitcairn (-08)",
    value: "Pacific/Pitcairn LMT -0830 -08",
    abbrs: ["LMT", "-0830", "-08"],
    currentAbbr: "-08",
  },
  {
    id: "Pacific/Pohnpei",
    name: "Pacific/Pohnpei (+11)",
    value: "Pacific/Pohnpei LMT +11",
    abbrs: ["LMT", "+11"],
    currentAbbr: "+11",
  },
  {
    id: "Pacific/Ponape",
    name: "Pacific/Ponape (+11)",
    value: "Pacific/Ponape LMT +11",
    abbrs: ["LMT", "+11"],
    currentAbbr: "+11",
  },
  {
    id: "Pacific/Port_Moresby",
    name: "Pacific/Port Moresby (+10)",
    value: "Pacific/Port Moresby LMT PMMT +10",
    abbrs: ["LMT", "PMMT", "+10"],
    currentAbbr: "+10",
  },
  {
    id: "Pacific/Rarotonga",
    name: "Pacific/Rarotonga (-10)",
    value: "Pacific/Rarotonga LMT -1030 -0930 -10",
    abbrs: ["LMT", "-1030", "-0930", "-10"],
    currentAbbr: "-10",
  },
  {
    id: "Pacific/Saipan",
    name: "Pacific/Saipan (ChST)",
    value: "Pacific/Saipan LMT GST +09 GDT ChST",
    abbrs: ["LMT", "GST", "+09", "GDT", "ChST"],
    currentAbbr: "ChST",
  },
  {
    id: "Pacific/Samoa",
    name: "Pacific/Samoa (SST)",
    value: "Pacific/Samoa LMT SST",
    abbrs: ["LMT", "SST"],
    currentAbbr: "SST",
  },
  {
    id: "Pacific/Tahiti",
    name: "Pacific/Tahiti (-10)",
    value: "Pacific/Tahiti LMT -10",
    abbrs: ["LMT", "-10"],
    currentAbbr: "-10",
  },
  {
    id: "Pacific/Tarawa",
    name: "Pacific/Tarawa (+12)",
    value: "Pacific/Tarawa LMT +12",
    abbrs: ["LMT", "+12"],
    currentAbbr: "+12",
  },
  {
    id: "Pacific/Tongatapu",
    name: "Pacific/Tongatapu (+13)",
    value: "Pacific/Tongatapu LMT +1220 +13 +14",
    abbrs: ["LMT", "+1220", "+13", "+14"],
    currentAbbr: "+13",
  },
  {
    id: "Pacific/Truk",
    name: "Pacific/Truk (+10)",
    value: "Pacific/Truk LMT PMMT +10",
    abbrs: ["LMT", "PMMT", "+10"],
    currentAbbr: "+10",
  },
  {
    id: "Pacific/Wake",
    name: "Pacific/Wake (+12)",
    value: "Pacific/Wake LMT +12",
    abbrs: ["LMT", "+12"],
    currentAbbr: "+12",
  },
  {
    id: "Pacific/Wallis",
    name: "Pacific/Wallis (+12)",
    value: "Pacific/Wallis LMT +12",
    abbrs: ["LMT", "+12"],
    currentAbbr: "+12",
  },
  {
    id: "Pacific/Yap",
    name: "Pacific/Yap (+10)",
    value: "Pacific/Yap LMT PMMT +10",
    abbrs: ["LMT", "PMMT", "+10"],
    currentAbbr: "+10",
  },
  {
    id: "Poland",
    name: "Poland (CET)",
    value: "Poland LMT WMT CET CEST EET EEST",
    abbrs: ["LMT", "WMT", "CET", "CEST", "EET", "EEST"],
    currentAbbr: "CET",
  },
  {
    id: "Portugal",
    name: "Portugal (WET)",
    value: "Portugal LMT WET WEST WEMT CET CEST",
    abbrs: ["LMT", "WET", "WEST", "WEMT", "CET", "CEST"],
    currentAbbr: "WET",
  },
  {
    id: "ROC",
    name: "ROC (CST)",
    value: "ROC LMT CST JST CDT",
    abbrs: ["LMT", "CST", "JST", "CDT"],
    currentAbbr: "CST",
  },
  {
    id: "ROK",
    name: "ROK (KST)",
    value: "ROK LMT KST JST KDT",
    abbrs: ["LMT", "KST", "JST", "KDT"],
    currentAbbr: "KST",
  },
  {
    id: "Singapore",
    name: "Singapore (+08)",
    value: "Singapore LMT SMT +07 +0720 +0730 +09 +08",
    abbrs: ["LMT", "SMT", "+07", "+0720", "+0730", "+09", "+08"],
    currentAbbr: "+08",
  },
  {
    id: "Turkey",
    name: "Turkey (+03)",
    value: "Turkey LMT IMT EET EEST +03 +04",
    abbrs: ["LMT", "IMT", "EET", "EEST", "+03", "+04"],
    currentAbbr: "+03",
  },
  {
    id: "UCT",
    name: "UCT (UTC)",
    value: "UCT UTC",
    abbrs: ["UTC"],
    currentAbbr: "UTC",
  },
  {
    id: "US/Alaska",
    name: "US/Alaska (AKST)",
    value: "US/Alaska LMT AST AWT APT AHST AHDT YST AKST AKDT",
    abbrs: ["LMT", "AST", "AWT", "APT", "AHST", "AHDT", "YST", "AKST", "AKDT"],
    currentAbbr: "AKST",
  },
  {
    id: "US/Aleutian",
    name: "US/Aleutian (HST)",
    value: "US/Aleutian LMT NST NWT NPT BST BDT AHST HST HDT",
    abbrs: ["LMT", "NST", "NWT", "NPT", "BST", "BDT", "AHST", "HST", "HDT"],
    currentAbbr: "HST",
  },
  {
    id: "US/Arizona",
    name: "US/Arizona (MST)",
    value: "US/Arizona LMT MST MDT MWT",
    abbrs: ["LMT", "MST", "MDT", "MWT"],
    currentAbbr: "MST",
  },
  {
    id: "US/Central",
    name: "US/Central (CST)",
    value: "US/Central LMT CST CDT EST CWT CPT",
    abbrs: ["LMT", "CST", "CDT", "EST", "CWT", "CPT"],
    currentAbbr: "CST",
  },
  {
    id: "US/East-Indiana",
    name: "US/East-Indiana (EST)",
    value: "US/East-Indiana LMT CST CDT CWT CPT EST EDT",
    abbrs: ["LMT", "CST", "CDT", "CWT", "CPT", "EST", "EDT"],
    currentAbbr: "EST",
  },
  {
    id: "US/Eastern",
    name: "US/Eastern (EST)",
    value: "US/Eastern LMT EST EDT EWT EPT",
    abbrs: ["LMT", "EST", "EDT", "EWT", "EPT"],
    currentAbbr: "EST",
  },
  {
    id: "US/Hawaii",
    name: "US/Hawaii (HST)",
    value: "US/Hawaii LMT HST HDT HWT HPT",
    abbrs: ["LMT", "HST", "HDT", "HWT", "HPT"],
    currentAbbr: "HST",
  },
  {
    id: "US/Indiana-Starke",
    name: "US/Indiana-Starke (CST)",
    value: "US/Indiana-Starke LMT CST CDT CWT CPT EST",
    abbrs: ["LMT", "CST", "CDT", "CWT", "CPT", "EST"],
    currentAbbr: "CST",
  },
  {
    id: "US/Michigan",
    name: "US/Michigan (EST)",
    value: "US/Michigan LMT CST EST EWT EPT EDT",
    abbrs: ["LMT", "CST", "EST", "EWT", "EPT", "EDT"],
    currentAbbr: "EST",
  },
  {
    id: "US/Mountain",
    name: "US/Mountain (MST)",
    value: "US/Mountain LMT MST MDT MWT MPT",
    abbrs: ["LMT", "MST", "MDT", "MWT", "MPT"],
    currentAbbr: "MST",
  },
  {
    id: "US/Pacific",
    name: "US/Pacific (PST)",
    value: "US/Pacific LMT PST PDT PWT PPT",
    abbrs: ["LMT", "PST", "PDT", "PWT", "PPT"],
    currentAbbr: "PST",
  },
  {
    id: "US/Samoa",
    name: "US/Samoa (SST)",
    value: "US/Samoa LMT SST",
    abbrs: ["LMT", "SST"],
    currentAbbr: "SST",
  },
  {
    id: "UTC",
    name: "UTC (UTC)",
    value: "UTC UTC",
    abbrs: ["UTC"],
    currentAbbr: "UTC",
  },
  {
    id: "Universal",
    name: "Universal (UTC)",
    value: "Universal UTC",
    abbrs: ["UTC"],
    currentAbbr: "UTC",
  },
  {
    id: "W-SU",
    name: "W-SU (MSK)",
    value: "W-SU LMT MMT MST MDST MSD MSK +05 EET EEST",
    abbrs: ["LMT", "MMT", "MST", "MDST", "MSD", "MSK", "+05", "EET", "EEST"],
    currentAbbr: "MSK",
  },
  {
    id: "WET",
    name: "WET (WET)",
    value: "WET LMT WET WEST WEMT CET CEST",
    abbrs: ["LMT", "WET", "WEST", "WEMT", "CET", "CEST"],
    currentAbbr: "WET",
  },
  {
    id: "Zulu",
    name: "Zulu (UTC)",
    value: "Zulu UTC",
    abbrs: ["UTC"],
    currentAbbr: "UTC",
  },
];

export const momentTimezonesToAbbreviationsForNonDst: {
  [key: string]: string;
} = {
  "Africa/Abidjan": "GMT",
  "Africa/Accra": "GMT",
  "Africa/Addis_Ababa": "EAT",
  "Africa/Algiers": "CET",
  "Africa/Asmara": "EAT",
  "Africa/Asmera": "EAT",
  "Africa/Bamako": "GMT",
  "Africa/Bangui": "WAT",
  "Africa/Banjul": "GMT",
  "Africa/Bissau": "GMT",
  "Africa/Blantyre": "CAT",
  "Africa/Brazzaville": "WAT",
  "Africa/Bujumbura": "CAT",
  "Africa/Cairo": "EET",
  "Africa/Casablanca": "+01",
  "Africa/Ceuta": "CET",
  "Africa/Conakry": "GMT",
  "Africa/Dakar": "GMT",
  "Africa/Dar_es_Salaam": "EAT",
  "Africa/Djibouti": "EAT",
  "Africa/Douala": "WAT",
  "Africa/El_Aaiun": "+01",
  "Africa/Freetown": "GMT",
  "Africa/Gaborone": "CAT",
  "Africa/Harare": "CAT",
  "Africa/Johannesburg": "SAST",
  "Africa/Juba": "CAT",
  "Africa/Kampala": "EAT",
  "Africa/Khartoum": "CAT",
  "Africa/Kigali": "CAT",
  "Africa/Kinshasa": "WAT",
  "Africa/Lagos": "WAT",
  "Africa/Libreville": "WAT",
  "Africa/Lome": "GMT",
  "Africa/Luanda": "WAT",
  "Africa/Lubumbashi": "CAT",
  "Africa/Lusaka": "CAT",
  "Africa/Malabo": "WAT",
  "Africa/Maputo": "CAT",
  "Africa/Maseru": "SAST",
  "Africa/Mbabane": "SAST",
  "Africa/Mogadishu": "EAT",
  "Africa/Monrovia": "GMT",
  "Africa/Nairobi": "EAT",
  "Africa/Ndjamena": "WAT",
  "Africa/Niamey": "WAT",
  "Africa/Nouakchott": "GMT",
  "Africa/Ouagadougou": "GMT",
  "Africa/Porto-Novo": "WAT",
  "Africa/Sao_Tome": "GMT",
  "Africa/Timbuktu": "GMT",
  "Africa/Tripoli": "EET",
  "Africa/Tunis": "CET",
  "Africa/Windhoek": "CAT",
  "America/Adak": "HST",
  "America/Anchorage": "AKST",
  "America/Anguilla": "AST",
  "America/Antigua": "AST",
  "America/Araguaina": "-03",
  "America/Argentina/Buenos_Aires": "-03",
  "America/Argentina/Catamarca": "-03",
  "America/Argentina/ComodRivadavia": "-03",
  "America/Argentina/Cordoba": "-03",
  "America/Argentina/Jujuy": "-03",
  "America/Argentina/La_Rioja": "-03",
  "America/Argentina/Mendoza": "-03",
  "America/Argentina/Rio_Gallegos": "-03",
  "America/Argentina/Salta": "-03",
  "America/Argentina/San_Juan": "-03",
  "America/Argentina/San_Luis": "-03",
  "America/Argentina/Tucuman": "-03",
  "America/Argentina/Ushuaia": "-03",
  "America/Aruba": "AST",
  "America/Asuncion": "-03",
  "America/Atikokan": "EST",
  "America/Atka": "HST",
  "America/Bahia": "-03",
  "America/Bahia_Banderas": "CST",
  "America/Barbados": "AST",
  "America/Belem": "-03",
  "America/Belize": "CST",
  "America/Blanc-Sablon": "AST",
  "America/Boa_Vista": "-04",
  "America/Bogota": "-05",
  "America/Boise": "MST",
  "America/Buenos_Aires": "-03",
  "America/Cambridge_Bay": "MST",
  "America/Campo_Grande": "-04",
  "America/Cancun": "EST",
  "America/Caracas": "-04",
  "America/Catamarca": "-03",
  "America/Cayenne": "-03",
  "America/Cayman": "EST",
  "America/Chicago": "CST",
  "America/Chihuahua": "CST",
  "America/Ciudad_Juarez": "MST",
  "America/Coral_Harbour": "EST",
  "America/Cordoba": "-03",
  "America/Costa_Rica": "CST",
  "America/Creston": "MST",
  "America/Cuiaba": "-04",
  "America/Curacao": "AST",
  "America/Danmarkshavn": "GMT",
  "America/Dawson": "MST",
  "America/Dawson_Creek": "MST",
  "America/Denver": "MST",
  "America/Detroit": "EST",
  "America/Dominica": "AST",
  "America/Edmonton": "MST",
  "America/Eirunepe": "-05",
  "America/El_Salvador": "CST",
  "America/Ensenada": "PST",
  "America/Fort_Nelson": "MST",
  "America/Fort_Wayne": "EST",
  "America/Fortaleza": "-03",
  "America/Glace_Bay": "AST",
  "America/Godthab": "-02",
  "America/Goose_Bay": "AST",
  "America/Grand_Turk": "EST",
  "America/Grenada": "AST",
  "America/Guadeloupe": "AST",
  "America/Guatemala": "CST",
  "America/Guayaquil": "-05",
  "America/Guyana": "-04",
  "America/Halifax": "AST",
  "America/Havana": "CST",
  "America/Hermosillo": "MST",
  "America/Indiana/Indianapolis": "EST",
  "America/Indiana/Knox": "CST",
  "America/Indiana/Marengo": "EST",
  "America/Indiana/Petersburg": "EST",
  "America/Indiana/Tell_City": "CST",
  "America/Indiana/Vevay": "EST",
  "America/Indiana/Vincennes": "EST",
  "America/Indiana/Winamac": "EST",
  "America/Indianapolis": "EST",
  "America/Inuvik": "MST",
  "America/Iqaluit": "EST",
  "America/Jamaica": "EST",
  "America/Jujuy": "-03",
  "America/Juneau": "AKST",
  "America/Kentucky/Louisville": "EST",
  "America/Kentucky/Monticello": "EST",
  "America/Knox_IN": "CST",
  "America/Kralendijk": "AST",
  "America/La_Paz": "-04",
  "America/Lima": "-05",
  "America/Los_Angeles": "PST",
  "America/Louisville": "EST",
  "America/Lower_Princes": "AST",
  "America/Maceio": "-03",
  "America/Managua": "CST",
  "America/Manaus": "-04",
  "America/Marigot": "AST",
  "America/Martinique": "AST",
  "America/Matamoros": "CST",
  "America/Mazatlan": "MST",
  "America/Mendoza": "-03",
  "America/Menominee": "CST",
  "America/Merida": "CST",
  "America/Metlakatla": "AKST",
  "America/Mexico_City": "CST",
  "America/Miquelon": "-03",
  "America/Moncton": "AST",
  "America/Monterrey": "CST",
  "America/Montevideo": "-03",
  "America/Montreal": "EST",
  "America/Montserrat": "AST",
  "America/Nassau": "EST",
  "America/New_York": "EST",
  "America/Nipigon": "EST",
  "America/Nome": "AKST",
  "America/Noronha": "-02",
  "America/North_Dakota/Beulah": "CST",
  "America/North_Dakota/Center": "CST",
  "America/North_Dakota/New_Salem": "CST",
  "America/Nuuk": "-02",
  "America/Ojinaga": "CST",
  "America/Panama": "EST",
  "America/Pangnirtung": "EST",
  "America/Paramaribo": "-03",
  "America/Phoenix": "MST",
  "America/Port-au-Prince": "EST",
  "America/Port_of_Spain": "AST",
  "America/Porto_Acre": "-05",
  "America/Porto_Velho": "-04",
  "America/Puerto_Rico": "AST",
  "America/Punta_Arenas": "-03",
  "America/Rainy_River": "CST",
  "America/Rankin_Inlet": "CST",
  "America/Recife": "-03",
  "America/Regina": "CST",
  "America/Resolute": "CST",
  "America/Rio_Branco": "-05",
  "America/Rosario": "-03",
  "America/Santa_Isabel": "PST",
  "America/Santarem": "-03",
  "America/Santiago": "-03",
  "America/Santo_Domingo": "AST",
  "America/Sao_Paulo": "-03",
  "America/Scoresbysund": "-02",
  "America/Shiprock": "MST",
  "America/Sitka": "AKST",
  "America/St_Barthelemy": "AST",
  "America/St_Johns": "NST",
  "America/St_Kitts": "AST",
  "America/St_Lucia": "AST",
  "America/St_Thomas": "AST",
  "America/St_Vincent": "AST",
  "America/Swift_Current": "CST",
  "America/Tegucigalpa": "CST",
  "America/Thule": "AST",
  "America/Thunder_Bay": "EST",
  "America/Tijuana": "PST",
  "America/Toronto": "EST",
  "America/Tortola": "AST",
  "America/Vancouver": "PST",
  "America/Virgin": "AST",
  "America/Whitehorse": "MST",
  "America/Winnipeg": "CST",
  "America/Yakutat": "AKST",
  "America/Yellowknife": "MST",
  "Antarctica/Casey": "+08",
  "Antarctica/Davis": "+07",
  "Antarctica/DumontDUrville": "+10",
  "Antarctica/Macquarie": "AEDT",
  "Antarctica/Mawson": "+05",
  "Antarctica/McMurdo": "NZDT",
  "Antarctica/Palmer": "-03",
  "Antarctica/Rothera": "-03",
  "Antarctica/South_Pole": "NZDT",
  "Antarctica/Syowa": "+03",
  "Antarctica/Troll": "+00",
  "Antarctica/Vostok": "+05",
  "Arctic/Longyearbyen": "CET",
  "Asia/Aden": "+03",
  "Asia/Almaty": "+05",
  "Asia/Amman": "+03",
  "Asia/Anadyr": "+12",
  "Asia/Aqtau": "+05",
  "Asia/Aqtobe": "+05",
  "Asia/Ashgabat": "+05",
  "Asia/Ashkhabad": "+05",
  "Asia/Atyrau": "+05",
  "Asia/Baghdad": "+03",
  "Asia/Bahrain": "+03",
  "Asia/Baku": "+04",
  "Asia/Bangkok": "+07",
  "Asia/Barnaul": "+07",
  "Asia/Beirut": "EET",
  "Asia/Bishkek": "+06",
  "Asia/Brunei": "+08",
  "Asia/Calcutta": "IST",
  "Asia/Chita": "+09",
  "Asia/Choibalsan": "+08",
  "Asia/Chongqing": "CST",
  "Asia/Chungking": "CST",
  "Asia/Colombo": "+0530",
  "Asia/Dacca": "+06",
  "Asia/Damascus": "+03",
  "Asia/Dhaka": "+06",
  "Asia/Dili": "+09",
  "Asia/Dubai": "+04",
  "Asia/Dushanbe": "+05",
  "Asia/Famagusta": "EET",
  "Asia/Gaza": "EET",
  "Asia/Harbin": "CST",
  "Asia/Hebron": "EET",
  "Asia/Ho_Chi_Minh": "+07",
  "Asia/Hong_Kong": "HKT",
  "Asia/Hovd": "+07",
  "Asia/Irkutsk": "+08",
  "Asia/Istanbul": "+03",
  "Asia/Jakarta": "WIB",
  "Asia/Jayapura": "WIT",
  "Asia/Jerusalem": "IST",
  "Asia/Kabul": "+0430",
  "Asia/Kamchatka": "+12",
  "Asia/Karachi": "PKT",
  "Asia/Kashgar": "+06",
  "Asia/Kathmandu": "+0545",
  "Asia/Katmandu": "+0545",
  "Asia/Khandyga": "+09",
  "Asia/Kolkata": "IST",
  "Asia/Krasnoyarsk": "+07",
  "Asia/Kuala_Lumpur": "+08",
  "Asia/Kuching": "+08",
  "Asia/Kuwait": "+03",
  "Asia/Macao": "CST",
  "Asia/Macau": "CST",
  "Asia/Magadan": "+11",
  "Asia/Makassar": "WITA",
  "Asia/Manila": "PST",
  "Asia/Muscat": "+04",
  "Asia/Nicosia": "EET",
  "Asia/Novokuznetsk": "+07",
  "Asia/Novosibirsk": "+07",
  "Asia/Omsk": "+06",
  "Asia/Oral": "+05",
  "Asia/Phnom_Penh": "+07",
  "Asia/Pontianak": "WIB",
  "Asia/Pyongyang": "KST",
  "Asia/Qatar": "+03",
  "Asia/Qostanay": "+05",
  "Asia/Qyzylorda": "+05",
  "Asia/Rangoon": "+0630",
  "Asia/Riyadh": "+03",
  "Asia/Saigon": "+07",
  "Asia/Sakhalin": "+11",
  "Asia/Samarkand": "+05",
  "Asia/Seoul": "KST",
  "Asia/Shanghai": "CST",
  "Asia/Singapore": "+08",
  "Asia/Srednekolymsk": "+11",
  "Asia/Taipei": "CST",
  "Asia/Tashkent": "+05",
  "Asia/Tbilisi": "+04",
  "Asia/Tehran": "+0330",
  "Asia/Tel_Aviv": "IST",
  "Asia/Thimbu": "+06",
  "Asia/Thimphu": "+06",
  "Asia/Tokyo": "JST",
  "Asia/Tomsk": "+07",
  "Asia/Ujung_Pandang": "WITA",
  "Asia/Ulaanbaatar": "+08",
  "Asia/Ulan_Bator": "+08",
  "Asia/Urumqi": "+06",
  "Asia/Ust-Nera": "+10",
  "Asia/Vientiane": "+07",
  "Asia/Vladivostok": "+10",
  "Asia/Yakutsk": "+09",
  "Asia/Yangon": "+0630",
  "Asia/Yekaterinburg": "+05",
  "Asia/Yerevan": "+04",
  "Atlantic/Azores": "-01",
  "Atlantic/Bermuda": "AST",
  "Atlantic/Canary": "WET",
  "Atlantic/Cape_Verde": "-01",
  "Atlantic/Faeroe": "WET",
  "Atlantic/Faroe": "WET",
  "Atlantic/Jan_Mayen": "CET",
  "Atlantic/Madeira": "WET",
  "Atlantic/Reykjavik": "GMT",
  "Atlantic/South_Georgia": "-02",
  "Atlantic/St_Helena": "GMT",
  "Atlantic/Stanley": "-03",
  "Australia/ACT": "AEDT",
  "Australia/Adelaide": "ACDT",
  "Australia/Brisbane": "AEST",
  "Australia/Broken_Hill": "ACDT",
  "Australia/Canberra": "AEDT",
  "Australia/Currie": "AEDT",
  "Australia/Darwin": "ACST",
  "Australia/Eucla": "+0845",
  "Australia/Hobart": "AEDT",
  "Australia/LHI": "+11",
  "Australia/Lindeman": "AEST",
  "Australia/Lord_Howe": "+11",
  "Australia/Melbourne": "AEDT",
  "Australia/NSW": "AEDT",
  "Australia/North": "ACST",
  "Australia/Perth": "AWST",
  "Australia/Queensland": "AEST",
  "Australia/South": "ACDT",
  "Australia/Sydney": "AEDT",
  "Australia/Tasmania": "AEDT",
  "Australia/Victoria": "AEDT",
  "Australia/West": "AWST",
  "Australia/Yancowinna": "ACDT",
  "Brazil/Acre": "-05",
  "Brazil/DeNoronha": "-02",
  "Brazil/East": "-03",
  "Brazil/West": "-04",
  CET: "CET",
  CST6CDT: "CST",
  "Canada/Atlantic": "AST",
  "Canada/Central": "CST",
  "Canada/Eastern": "EST",
  "Canada/Mountain": "MST",
  "Canada/Newfoundland": "NST",
  "Canada/Pacific": "PST",
  "Canada/Saskatchewan": "CST",
  "Canada/Yukon": "MST",
  "Chile/Continental": "-03",
  "Chile/EasterIsland": "-05",
  Cuba: "CST",
  EET: "EET",
  EST: "EST",
  EST5EDT: "EST",
  Egypt: "EET",
  Eire: "GMT",
  "Etc/GMT": "GMT",
  "Etc/GMT+0": "GMT",
  "Etc/GMT+1": "-01",
  "Etc/GMT+10": "-10",
  "Etc/GMT+11": "-11",
  "Etc/GMT+12": "-12",
  "Etc/GMT+2": "-02",
  "Etc/GMT+3": "-03",
  "Etc/GMT+4": "-04",
  "Etc/GMT+5": "-05",
  "Etc/GMT+6": "-06",
  "Etc/GMT+7": "-07",
  "Etc/GMT+8": "-08",
  "Etc/GMT+9": "-09",
  "Etc/GMT-0": "GMT",
  "Etc/GMT-1": "+01",
  "Etc/GMT-10": "+10",
  "Etc/GMT-11": "+11",
  "Etc/GMT-12": "+12",
  "Etc/GMT-13": "+13",
  "Etc/GMT-14": "+14",
  "Etc/GMT-2": "+02",
  "Etc/GMT-3": "+03",
  "Etc/GMT-4": "+04",
  "Etc/GMT-5": "+05",
  "Etc/GMT-6": "+06",
  "Etc/GMT-7": "+07",
  "Etc/GMT-8": "+08",
  "Etc/GMT-9": "+09",
  "Etc/GMT0": "GMT",
  "Etc/Greenwich": "GMT",
  "Etc/UCT": "UTC",
  "Etc/UTC": "UTC",
  "Etc/Universal": "UTC",
  "Etc/Zulu": "UTC",
  "Europe/Amsterdam": "CET",
  "Europe/Andorra": "CET",
  "Europe/Astrakhan": "+04",
  "Europe/Athens": "EET",
  "Europe/Belfast": "GMT",
  "Europe/Belgrade": "CET",
  "Europe/Berlin": "CET",
  "Europe/Bratislava": "CET",
  "Europe/Brussels": "CET",
  "Europe/Bucharest": "EET",
  "Europe/Budapest": "CET",
  "Europe/Busingen": "CET",
  "Europe/Chisinau": "EET",
  "Europe/Copenhagen": "CET",
  "Europe/Dublin": "GMT",
  "Europe/Gibraltar": "CET",
  "Europe/Guernsey": "GMT",
  "Europe/Helsinki": "EET",
  "Europe/Isle_of_Man": "GMT",
  "Europe/Istanbul": "+03",
  "Europe/Jersey": "GMT",
  "Europe/Kaliningrad": "EET",
  "Europe/Kiev": "EET",
  "Europe/Kirov": "MSK",
  "Europe/Kyiv": "EET",
  "Europe/Lisbon": "WET",
  "Europe/Ljubljana": "CET",
  "Europe/London": "GMT",
  "Europe/Luxembourg": "CET",
  "Europe/Madrid": "CET",
  "Europe/Malta": "CET",
  "Europe/Mariehamn": "EET",
  "Europe/Minsk": "+03",
  "Europe/Monaco": "CET",
  "Europe/Moscow": "MSK",
  "Europe/Nicosia": "EET",
  "Europe/Oslo": "CET",
  "Europe/Paris": "CET",
  "Europe/Podgorica": "CET",
  "Europe/Prague": "CET",
  "Europe/Riga": "EET",
  "Europe/Rome": "CET",
  "Europe/Samara": "+04",
  "Europe/San_Marino": "CET",
  "Europe/Sarajevo": "CET",
  "Europe/Saratov": "+04",
  "Europe/Simferopol": "MSK",
  "Europe/Skopje": "CET",
  "Europe/Sofia": "EET",
  "Europe/Stockholm": "CET",
  "Europe/Tallinn": "EET",
  "Europe/Tirane": "CET",
  "Europe/Tiraspol": "EET",
  "Europe/Ulyanovsk": "+04",
  "Europe/Uzhgorod": "EET",
  "Europe/Vaduz": "CET",
  "Europe/Vatican": "CET",
  "Europe/Vienna": "CET",
  "Europe/Vilnius": "EET",
  "Europe/Volgograd": "MSK",
  "Europe/Warsaw": "CET",
  "Europe/Zagreb": "CET",
  "Europe/Zaporozhye": "EET",
  "Europe/Zurich": "CET",
  GB: "GMT",
  "GB-Eire": "GMT",
  GMT: "GMT",
  "GMT+0": "GMT",
  "GMT-0": "GMT",
  GMT0: "GMT",
  Greenwich: "GMT",
  HST: "HST",
  Hongkong: "HKT",
  Iceland: "GMT",
  "Indian/Antananarivo": "EAT",
  "Indian/Chagos": "+06",
  "Indian/Christmas": "+07",
  "Indian/Cocos": "+0630",
  "Indian/Comoro": "EAT",
  "Indian/Kerguelen": "+05",
  "Indian/Mahe": "+04",
  "Indian/Maldives": "+05",
  "Indian/Mauritius": "+04",
  "Indian/Mayotte": "EAT",
  "Indian/Reunion": "+04",
  Iran: "+0330",
  Israel: "IST",
  Jamaica: "EST",
  Japan: "JST",
  Kwajalein: "+12",
  Libya: "EET",
  MET: "CET",
  MST: "MST",
  MST7MDT: "MST",
  "Mexico/BajaNorte": "PST",
  "Mexico/BajaSur": "MST",
  "Mexico/General": "CST",
  NZ: "NZDT",
  "NZ-CHAT": "+1345",
  Navajo: "MST",
  PRC: "CST",
  PST8PDT: "PST",
  "Pacific/Apia": "+13",
  "Pacific/Auckland": "NZDT",
  "Pacific/Bougainville": "+11",
  "Pacific/Chatham": "+1345",
  "Pacific/Chuuk": "+10",
  "Pacific/Easter": "-05",
  "Pacific/Efate": "+11",
  "Pacific/Enderbury": "+13",
  "Pacific/Fakaofo": "+13",
  "Pacific/Fiji": "+12",
  "Pacific/Funafuti": "+12",
  "Pacific/Galapagos": "-06",
  "Pacific/Gambier": "-09",
  "Pacific/Guadalcanal": "+11",
  "Pacific/Guam": "ChST",
  "Pacific/Honolulu": "HST",
  "Pacific/Johnston": "HST",
  "Pacific/Kanton": "+13",
  "Pacific/Kiritimati": "+14",
  "Pacific/Kosrae": "+11",
  "Pacific/Kwajalein": "+12",
  "Pacific/Majuro": "+12",
  "Pacific/Marquesas": "-0930",
  "Pacific/Midway": "SST",
  "Pacific/Nauru": "+12",
  "Pacific/Niue": "-11",
  "Pacific/Norfolk": "+12",
  "Pacific/Noumea": "+11",
  "Pacific/Pago_Pago": "SST",
  "Pacific/Palau": "+09",
  "Pacific/Pitcairn": "-08",
  "Pacific/Pohnpei": "+11",
  "Pacific/Ponape": "+11",
  "Pacific/Port_Moresby": "+10",
  "Pacific/Rarotonga": "-10",
  "Pacific/Saipan": "ChST",
  "Pacific/Samoa": "SST",
  "Pacific/Tahiti": "-10",
  "Pacific/Tarawa": "+12",
  "Pacific/Tongatapu": "+13",
  "Pacific/Truk": "+10",
  "Pacific/Wake": "+12",
  "Pacific/Wallis": "+12",
  "Pacific/Yap": "+10",
  Poland: "CET",
  Portugal: "WET",
  ROC: "CST",
  ROK: "KST",
  Singapore: "+08",
  Turkey: "+03",
  UCT: "UTC",
  "US/Alaska": "AKST",
  "US/Aleutian": "HST",
  "US/Arizona": "MST",
  "US/Central": "CST",
  "US/East-Indiana": "EST",
  "US/Eastern": "EST",
  "US/Hawaii": "HST",
  "US/Indiana-Starke": "CST",
  "US/Michigan": "EST",
  "US/Mountain": "MST",
  "US/Pacific": "PST",
  "US/Samoa": "SST",
  UTC: "UTC",
  Universal: "UTC",
  "W-SU": "MSK",
  WET: "WET",
  Zulu: "UTC",
};
