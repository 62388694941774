import { AuthContextProvider } from "auth/context";
import {
  useExtensionHasBeenOpened,
  useExtensionIsClosed,
} from "client/app/extension/__components/ExtensionControllerProvider";
import { TimezoneProvider } from "client/timezones/TimezoneProvider";
import AnalyticsWrapper from "components/AnalyticsWrapper";
import { FullstoryWrapper } from "components/FullstoryWrapper";
import SatismeterWrapper from "components/SatismeterWrapper";
import { gql } from "generated/graphql-codegen";
import { Session } from "next-auth/core/types";
import { SessionProvider } from "next-auth/react";
import { useMemo } from "react";
import useQuery from "utils/useQuery";

const INITIAL_USER_QUERY = gql(`
  query InitialUserQuery {
    currentUser {
      id
      currentUserMembership {
        ...UserMembershipForSatismeter
      }
      ...UserForAnalytics
      ...UserForAuthContext
      ...UserForFullstory
    }
  }
`);

/** Combine all providers that require a user into one query */
export function CustomUserProviders({
  children,
  onLogout,
  session,
}: {
  children?: React.ReactNode;
  onLogout: () => Promise<void>;
  session?: Session | null;
}) {
  const isExtensionClosed = useExtensionIsClosed();
  const hasExtensionBeenOpen = useExtensionHasBeenOpened();
  const { data, loading, error, refetch } = useQuery(INITIAL_USER_QUERY, {
    throwOnError: false,
  });

  const user = data?.currentUser;

  // Ignore errors unless this query has never succeeded
  const userError = useMemo(() => {
    return !data ? error : undefined;
  }, [data, error]);

  if (!hasExtensionBeenOpen) {
    return null;
  }

  return (
    <SessionProvider
      refetchOnWindowFocus={!isExtensionClosed}
      session={session}
    >
      <AuthContextProvider
        onLogout={onLogout}
        user={user}
        refetchUser={refetch}
        userLoading={loading}
        userError={userError}
      >
        <TimezoneProvider timezone={user?.timezone}>
          <AnalyticsWrapper user={user}>
            <SatismeterWrapper userMembership={user?.currentUserMembership}>
              <FullstoryWrapper user={user}>{children}</FullstoryWrapper>
            </SatismeterWrapper>
          </AnalyticsWrapper>
        </TimezoneProvider>
      </AuthContextProvider>
    </SessionProvider>
  );
}
