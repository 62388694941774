var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"30a5f88559c621a793a39beacbe4edf9833999d6"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as FullStory from "@fullstory/browser";
import SentryFullStory from "@sentry/fullstory";
import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const sentryEnvironment = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;

Sentry.init({
  dsn: SENTRY_DSN,
  maxValueLength: 500,

  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0,
  enabled: sentryEnvironment !== "development",
  // FOR DEV:
  // tracesSampleRate: 1.0,
  // enabled: true,

  replaysOnErrorSampleRate: 1.0,
  environment: sentryEnvironment,
  integrations: [
    new Sentry.Replay(),
    new SentryFullStory("idk-labs", { client: FullStory }),
  ],
  ignoreErrors: [
    /^(Error: )?Failed to fetch$/,
    /^(Error: )?Load failed$/,
    /^(Error: )?NetworkError when attempting to fetch resource/,
    /^(Error: )?Network Error$/,
    /^(Error: )?Network Error$/,
    /^(Error: )?ResizeObserver loop completed with undelivered notifications.$/,
    /^(LaunchDarklyFlagFetchError: )?network error \(Error\)$/,
    /^(Error: )?Route Canceled \(safe to ignore\)$/,
  ],
});
