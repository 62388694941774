import { useAuthContext } from "auth/context";
import { useLDClient } from "client/lib/launchdarkly";
import { RoleEnum } from "enums/role-enum";
import { gql } from "generated/graphql-codegen/gql";
import React, { useEffect, useMemo, useState } from "react";
import { captureSentryError } from "shared/errors/sentryError";
import { useQueryStringValue } from "utils/next";
import useQuery from "utils/useQuery";

import { FlagsLoadingContext } from "./FlagsLoadingContext";

gql(`
  fragment OrgForFlags on Organization {
    id
    name
  }
`);

const GUIDE_DETAILS_FOR_IDENTITY = gql(`
  query GuideDetailsForIdentity(
    $organizationSlug: String!
    $shortId: String!
  ) {
    guideByShortId(
      organizationSlug: $organizationSlug, shortId: $shortId
    ) {
      id
      candidate {
        id
        firstName: displayFirstName
        lastName
        email
        organization {
          ...OrgForFlags
        }
      }
    }
  }
`);

interface IdentifyUserWrapperProps {
  children?: React.ReactNode;
}

export function IdentifyUserWrapper({ children }: IdentifyUserWrapperProps) {
  const auth = useAuthContext();
  const client = useLDClient();
  const [flagsLoading, setFlagsLoading] = useState(true);

  const organizationSlug = useQueryStringValue("organizationSlug");
  const shortId = useQueryStringValue("shortId");

  // All guide pages have these 2 parameters; this check is more resilient than maintaining a list of paths
  const isGuidePage = organizationSlug && shortId;

  const {
    data: guideData,
    loading: guideDataLoading,
    error: guideDataError,
  } = useQuery(GUIDE_DETAILS_FOR_IDENTITY, {
    // only call if we're on a guide page + there's no user info
    skip: !isGuidePage || auth.loading || !!auth.user,
    variables: {
      organizationSlug,
      shortId,
    },
  });

  useEffect(() => {
    const userData = auth.user;
    if (userData) {
      client?.identify(
        {
          ...userData,
          highestRole: userData.currentUserMembership?.highestRole?.id
            ? (userData.currentUserMembership.highestRole.id as RoleEnum)
            : null,
          currentOrganization: userData.currentOrganization || undefined,
        },
        undefined,
        (err) => {
          if (err) {
            captureSentryError(err, {
              title: "analytics identifyUser user",
            });
          }
          setFlagsLoading(false);
        }
      );
    } else if (
      // If either request error'd or both have finished (and the above conditions aren't met)
      auth.error ||
      guideDataError ||
      (!auth.loading && !guideDataLoading)
    ) {
      setFlagsLoading(false);
    }
  }, [
    auth.error,
    auth.loading,
    auth.user,
    client,
    guideData,
    guideDataError,
    guideDataLoading,
  ]);

  const flagsLoadingValue = useMemo(
    () => ({ loading: flagsLoading }),
    [flagsLoading]
  );

  return (
    <FlagsLoadingContext.Provider value={flagsLoadingValue}>
      {children}
    </FlagsLoadingContext.Provider>
  );
}
