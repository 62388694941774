import { LDFlagSet } from "launchdarkly-js-sdk-common";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { userAsLDUser } from "shared/lib/launchdarkly/convert-user-to-ld-user";
import { User } from "shared/lib/launchdarkly/User";

type IdentifyFunction = (err: Error | null, flags: LDFlagSet | null) => void;

type UseClientReturnType = {
  identify(user: User, hash?: string, callback?: IdentifyFunction): void;
};

export default function useClient(): UseClientReturnType {
  const ldClient = useLDClient();

  const identify = (
    user: User | { anonymous: boolean },
    hash?: string,
    callback?: IdentifyFunction
  ): void => {
    if (ldClient) {
      const ldUser = "anonymous" in user ? user : userAsLDUser({ user });
      ldClient.identify(ldUser, hash, callback);
    }
  };

  return {
    identify,
  };
}
