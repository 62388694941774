/* eslint-disable @typescript-eslint/no-explicit-any */
import { mergeProps as reactAriaMergeProps } from "@react-aria/utils";
import { omit } from "lodash";
import React, {
  cloneElement,
  isValidElement,
  MutableRefObject,
  ReactElement,
  ReactNode,
  Ref,
  RefAttributes,
  RefCallback,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import mergeRefs from "react-merge-refs";
import { useIsomorphicLayoutEffect } from "react-use";

import { createId } from "./misc";

// TODO: refactor away from react-aria and make public
// improves the react-aria utility by also merging refs,
// copying most of the types and docs from react-aria for now

type Props = { [key: string]: any };
type TupleTypes<T> = {
  [P in keyof T]: T[P];
} extends {
  [key: number]: infer V;
}
  ? V
  : never;
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;

/**
 * Merges multiple props objects together. Event handlers are chained,
 * classNames are combined, and ids are deduplicated - different ids
 * will trigger a side-effect and re-render components hooked up with `useId`.
 * **Refs are merged too.**
 * For all other props, the last prop object overrides all previous ones.
 * @param args - Multiple sets of props to merge together.
 */
export function mergeProps<T extends Props[]>(
  ...args: T
): UnionToIntersection<TupleTypes<T>> {
  const mergedProps = reactAriaMergeProps(...args);
  const refs = args.map((arg) => arg.ref).filter(Boolean);
  if (refs.length === 0) return mergedProps;
  (mergedProps as RefAttributes<unknown>).ref = mergeRefs(refs);
  return mergedProps;
}

/** Returns a clone of a single child element, with the input props merge into its props.
 * Input props will take precedence over the child element's own props, overriding them
 * in some cases. */
export function mergePropsIntoSingleChild<T>(
  /** The props that will be merged into the single child element. */
  inputProps: unknown,
  /** Children consisting of a single child element, that will have its props merged
   * with the input props. */
  children: ReactNode
): ReactElement<T> {
  const child = React.Children.only(children);
  if (!isValidElement(child)) throw new Error("Invalid children");
  const mergedProps = mergeProps(
    { ...child.props, ref: (child as RefAttributes<unknown>).ref },
    // omit included here because of https://github.com/IDKLabs/resource/pull/16753
    omit(inputProps as Record<string, unknown>, ["0"])
  );
  return cloneElement(child, mergedProps) as ReactElement<T>;
}

/**
 * Validates the type correctness of a set of prop defaults, while retaining narrow types. Pass
 * the default props as an object literal and use `as const`, and also the target props type as
 * a type argument. The object will be returned as is with the same (narrow) types: like an identity
 * function, but validated against the passed type.
 *
 * Note that this is a factory function, so you'll need to "call it twice". This is to get around the
 * TypeScript limitation that there is no partial inference of type arguments in generics.
 *
 * @example
 * const DEFAULT_PROPS = createDefaultProps<ComponentProps>()({
 *   variant: "primary"
 * } as const)
 */
export function createDefaultProps<P>() {
  return <D extends Partial<P>>(defaultProps: D): D => defaultProps;
}

/** Ensures that a value doesn't change throughout the life of a component. */
export function useStaticValueError<T>(
  /** The value to be checked. */
  value: T,
  /** The error message to display. If not provided, a default one will be used. */
  msg?: string
) {
  const [initialValue] = useState(value);
  if (initialValue !== value)
    throw new Error(
      msg ?? "Value can't change throughout the life of the component"
    );
}

type UseScheduleFocusOptions = {
  /** Callback that will be executed after focusing the element. */
  afterFocus?: () => void;
};

// stolen from ariakit - https://github.com/ariakit/ariakit/blob/e33b93a611189e03252ffbf11d25763001d24fb5/packages/ariakit/src/composite/composite.ts#L97-L108
/** Returns a callback that, when called, focuses the element in the next React render. */
export function useScheduleFocus<T extends HTMLElement | null>(
  elementRef: RefObject<T>,
  { afterFocus }: UseScheduleFocusOptions = {}
) {
  const [scheduled, setScheduled] = useState(false);
  const schedule = useCallback(() => setScheduled(true), []);
  useEffect(() => {
    const { current: element } = elementRef;
    if (scheduled) {
      element?.focus();
      if (element) afterFocus?.();
      setScheduled(false);
    }
  }, [afterFocus, elementRef, scheduled]);
  return schedule;
}

// stolen from ariakit - https://github.com/ariakit/ariakit/blob/21e58e9c00a41ccec7ba515de4b13cebbeeec0e0/packages/ariakit-utils/src/misc.ts#L67-L79
/** Sets both a function and object React ref. */
export function setRef<T>(
  ref: RefCallback<T> | MutableRefObject<T> | null | undefined,
  value: T
) {
  if (typeof ref === "function") {
    ref(value);
  } else if (ref) {
    // eslint-disable-next-line no-param-reassign
    ref.current = value;
  }
}

// stolen from ariakit - https://github.com/ariakit/ariakit/blob/21e58e9c00a41ccec7ba515de4b13cebbeeec0e0/packages/ariakit-utils/src/hooks.ts#L121-L139
/**
 * Merges React Refs into a single memoized function ref so you can pass it to
 * an element.
 * @example
 * const Component = React.forwardRef((props, ref) => {
 *   const internalRef = React.useRef();
 *   return <div {...props} ref={useForkRef(internalRef, ref)} />;
 * });
 */
export function useForkRef(...refs: Array<Ref<any> | undefined>) {
  return useMemo(() => {
    if (!refs.some(Boolean)) return undefined;
    return (value: any) => {
      refs.forEach((ref) => {
        setRef(ref, value);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refs);
}

/** Props with required children. */
export type RequiredChildren = { children: ReactNode };

/** Holds and returns the initially passed value indefinitely. */
export function useStaticValue<T>(value: T | (() => T)) {
  const [staticValue] = useState(value);
  return staticValue;
}

type AnyFunction = (...args: any) => any;

// stolen from ariakit: https://github.com/ariakit/ariakit/blob/3ab193103ab9709f177bd747f898493c9f8344e6/packages/ariakit-utils/src/hooks.ts#L97-L119
/**
 * Creates a stable callback function that has access to the latest state and
 * can be used within event handlers and effect callbacks. Throws when used in
 * the render phase.
 * @example
 * function Component(props) {
 *   const onClick = useEvent(props.onClick);
 *   React.useEffect(() => {}, [onClick]);
 * }
 */
export function useEvent<T extends AnyFunction>(callback?: T) {
  const ref = useRef<AnyFunction | undefined>(() => {
    throw new Error("Cannot call an event handler while rendering.");
  });
  // TODO: replace with insertion effect after upgrading to React 18
  useIsomorphicLayoutEffect(() => {
    ref.current = callback;
  });
  return useCallback<AnyFunction>((...args) => ref.current?.(...args), []) as T;
}

// TODO: replace with React 18 useId
/** Create a stable random id. The id will be 5 characters long. */
export function useId() {
  return useStaticValue(() => createId());
}
