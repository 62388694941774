/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { TypedDocumentNode } from "@apollo/client";
import { Link } from "@resource/atlas/link/Link";
import { useRouter } from "next/router";
import { createContext, ReactNode, useContext, useMemo, useState } from "react";

export type ConnectionErrorContextValue = {
  erroredQuery?: TypedDocumentNode<any, any>;
  setErroredQuery: (
    erroredQuery: TypedDocumentNode<any, any> | undefined
  ) => unknown;
};

const ConnectionErrorContext = createContext<ConnectionErrorContextValue>({
  erroredQuery: undefined,
  setErroredQuery() {},
});

export function useConnectionErrorContext() {
  return useContext(ConnectionErrorContext);
}

export function ConnectionErrorBanner({ children }: { children?: ReactNode }) {
  const router = useRouter();
  const [erroredQuery, setErroredQuery] = useState<
    TypedDocumentNode<any, any> | undefined
  >();
  const value = useMemo(
    () => ({
      erroredQuery,
      setErroredQuery,
    }),
    [erroredQuery]
  );

  return (
    <ConnectionErrorContext.Provider value={value}>
      {erroredQuery && (
        <div className="flex w-full px-4 py-2 gap-4 bg-red-50 text-body-md-heavy">
          Connection lost.{" "}
          <Link as="button" onClick={() => router.reload()}>
            Refresh
          </Link>{" "}
          to see the most up-to-date information.
        </div>
      )}
      {children}
    </ConnectionErrorContext.Provider>
  );
}
