import { atom, useAtomValue } from "jotai";
import { DateTime, Interval } from "luxon";
// eslint-disable-next-line import/no-restricted-paths
import { DayOfWeek } from "server/scheduler/algorithm-v2/types";
import { getCurrentIanaTimezone } from "shared/utils/timezones";

import { CalendarView } from "./types";

function isValidTimezone(tz: string) {
  return DateTime.local().setZone(tz).isValid;
}

export const calendarTimezoneInternalAtom = atom(getCurrentIanaTimezone());

// ensure calendar timezone is never invalid
export const calendarTimezoneAtom = atom(
  (get) => {
    const timezone = get(calendarTimezoneInternalAtom);

    if (!isValidTimezone(timezone)) {
      return getCurrentIanaTimezone();
    }

    return timezone;
  },
  (get, set, tz: string) => {
    if (!isValidTimezone(tz)) {
      console.error("Invalid timezone", tz);
      return;
    }

    set(calendarTimezoneInternalAtom, tz);
  }
);

export const calendarIncludeWeekendsAtom = atom(false);

export const calendarTwentyFourHourFormatAtom = atom(false);

export const calendarViewAtom = atom<CalendarView>("week");

const selectedDayInternalAtom = atom(DateTime.local());

// make sure selected day is always visible and in correct timezone
export const calendarSelectedDayAtom = atom(
  (get) => {
    const view = get(calendarViewAtom);
    const selectedDayInternal = get(selectedDayInternalAtom);
    const includeWeekends = get(calendarIncludeWeekendsAtom);

    if (!includeWeekends && view === "week") {
      if (selectedDayInternal.weekday === DayOfWeek.SATURDAY) {
        return selectedDayInternal.plus({ days: 2 });
      }

      if (selectedDayInternal.weekday === DayOfWeek.SUNDAY) {
        return selectedDayInternal.plus({ days: 1 });
      }
    }

    return selectedDayInternal;
  },
  (get, set, selectedDay: DateTime | ((prev: DateTime) => DateTime)) => {
    const calendarTimezone = get(calendarTimezoneAtom);
    const selectedDayInternal = get(selectedDayInternalAtom);

    if (typeof selectedDay === "function") {
      const newDay = selectedDay(selectedDayInternal).setZone(calendarTimezone);

      set(selectedDayInternalAtom, newDay);
    } else {
      set(selectedDayInternalAtom, selectedDay.setZone(calendarTimezone));
    }
  }
);

export const calendarCurrentIntervalAtom = atom((get) => {
  const selectedDay = get(calendarSelectedDayAtom);
  const view = get(calendarViewAtom);
  const includeWeekends = get(calendarIncludeWeekendsAtom);

  const baseInterval = Interval.fromDateTimes(
    selectedDay.startOf(view),
    selectedDay.endOf(view)
  );

  if (view === "week" && !includeWeekends) {
    return baseInterval.set({
      end: baseInterval.end.minus({ days: 2 }),
    });
  }

  return baseInterval;
});

export function useCalendarTimezone() {
  return useAtomValue(calendarTimezoneAtom);
}

export function useCalendarSettings() {
  const timezone = useAtomValue(calendarTimezoneAtom);
  const includeWeekends = useAtomValue(calendarIncludeWeekendsAtom);
  const twentyFourHourFormat = useAtomValue(calendarTwentyFourHourFormatAtom);
  const selectedDay = useAtomValue(calendarSelectedDayAtom);
  const view = useAtomValue(calendarViewAtom);

  return {
    timezone,
    includeWeekends,
    twentyFourHourFormat,
    selectedDay,
    view,
  };
}

export function useCurrentInterval() {
  return useAtomValue(calendarCurrentIntervalAtom);
}
